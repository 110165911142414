// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MpsDataFile from '../data/MpsDataFile';
import * as MpsConstants from '../MpsConstants';
import { deepClone } from '../MpsUtils';

export class MpsDataPage extends Component {
    intl = this.props.intl;

    handleValueChange = (newValue, fileName) => {
        let newDataFiles = [...this.props.dataFiles.filter((file) => file.fileName !== fileName)];
        let fullPath = this.props.dataPathPrefix + fileName;

        if (!newValue) {
            if (this.props.allFiles.includes(fullPath)) {
                let deletedDataFile = deepClone(this.props.dataFiles.find((file) => file.fileName === fileName));
                deletedDataFile.previousContents = deletedDataFile.contents;
                deletedDataFile.contents = null;

                newDataFiles.push(deletedDataFile);
            }
        } else {
            newDataFiles.push(newValue);
        }

        this.props.onChange(newDataFiles, 'dataFiles');
    };

    render() {
        const channelFileName = MpsConstants.CHANNEL_IMEIS_FILE_NAME;
        const sponsorFileName = MpsConstants.SPONSOR_IMEIS_FILE_NAME;
        const ipFileName = MpsConstants.INSTALLEDPROFILE_IMEIS_FILE_NAME;
        const rowPadding = 'p-3';

        return (
            <div>
                <div className={`bg-light ${rowPadding}`}>
                    <MpsDataFile
                        controlId='channelDataFile'
                        fileName={channelFileName}
                        value={this.props.dataFiles?.find((file) => file.fileName === channelFileName)}
                        onChange={this.handleValueChange}
                        dataPathPrefix={this.props.dataPathPrefix}
                    />
                </div>
                <div className={`bg-white ${rowPadding}`}>
                    <MpsDataFile
                        controlId='sponsorDataFile'
                        fileName={sponsorFileName}
                        value={this.props.dataFiles?.find((file) => file.fileName === sponsorFileName)}
                        onChange={this.handleValueChange}
                        dataPathPrefix={this.props.dataPathPrefix}
                    />
                </div>
                <div className={`bg-light ${rowPadding}`}>
                    <MpsDataFile
                        controlId='installedProfileDataFile'
                        fileName={ipFileName}
                        value={this.props.dataFiles?.find((file) => file.fileName === ipFileName)}
                        onChange={this.handleValueChange}
                        dataPathPrefix={this.props.dataPathPrefix}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(MpsDataPage);
