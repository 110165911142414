// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

export class NoSettingsSelectedContainer extends Component {
    render() {
        return (
            <div>
                <p>{this.props.intl.formatMessage({ id: 'no-setting-selected' })}</p>
            </div>
        );
    }
}

export default injectIntl(NoSettingsSelectedContainer);
