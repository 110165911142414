// Copyright (C) Microsoft Corporation. All rights reserved.
export function generateGuid(upperCase) {
    // eslint-disable-next-line no-mixed-operators
    let guid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16) // eslint-disable-line no-mixed-operators
    );

    return !upperCase ? guid : guid.toUpperCase();
}

export function isAlphaNumeric(value) {
    const alphaNumericRegex = /^([a-zA-Z0-9]+)$/;
    return alphaNumericRegex.test(value);
}

export function isNumZeroOrEmpty(value) {
    const numOrEmptyRegex = /(^[0-9]*$|^$)/;
    return numOrEmptyRegex.test(value);
}

export function isNumNonZeroOrEmpty(value) {
    const numOrEmptyRegex = /(^[1-9][0-9]*$|^$)/;
    return numOrEmptyRegex.test(value);
}

export function deepClone(obj) {
    if (obj === undefined) {
        return undefined;
    }

    return JSON.parse(JSON.stringify(obj));
}
