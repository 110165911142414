// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import RestrictedTextInput from '../form/RestrictedTextInput';
import { deepClone } from '../../mps/MpsUtils';
import './TabbedContainer.css';

export class TabbedContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        };

        this.activeIndexRef = React.createRef();
    }

    setActiveIndex = (index) => {
        this.setState({ activeIndex: index });
    };

    removeItem = (index) => {
        let removedItem = deepClone(this.props.items[index]);

        let activeIndex = this.state.activeIndex;
        if (activeIndex >= index) {
            let newIndex = Math.max(0, activeIndex - 1);
            this.setState({ activeIndex: newIndex });
        }

        this.props.onChange([], [removedItem]);
    };

    addEmptyItem = (newTabValue) => {
        let newActiveIndex = this.props.items.length;
        let newEmptyItem = deepClone(this.props.emptyItem);

        newEmptyItem[this.props.tabPropName] = newTabValue;

        // WARN: this is kind of hacky, do not attempt at home!
        if (!!this.props.placeholderPropNames) {
            this.props.placeholderPropNames.forEach((placeholderPropName) => {
                newEmptyItem[placeholderPropName] = newEmptyItem[placeholderPropName].replace(`{${this.props.tabPropName}}`, newTabValue);
            });
        }

        this.props.onChange([newEmptyItem], []);
        this.setState({ activeIndex: newActiveIndex });
    };

    componentDidUpdate(prevProps) {
        if (this.props.items.length !== prevProps.items.length && this.props.items.length > 0) {
            this.activeIndexRef.current.focus();
        }
    }

    render() {
        let { items, renderItem, tabPropName, controlId } = this.props;
        let activeIndex = this.state.activeIndex;

        return (
            <div>
                {items?.map((item, index) => {
                    let variant = activeIndex === index ? 'primary' : 'outline-secondary';
                    const activeIndexRef = activeIndex === index ? this.activeIndexRef : null;

                    return (
                        <ButtonGroup className='tabbed-container-tab' key={controlId + 'Tab' + index}>
                            <Button
                                className='tabbed-container-tab-main'
                                variant={variant}
                                aria-label={this.props.intl.formatMessage(
                                    { id: 'select-item-button-aria-label' },
                                    { text: item[tabPropName] ?? '...' }
                                )}
                                ref={activeIndexRef}
                                onClick={() => {
                                    this.setActiveIndex(index);
                                }}
                            >
                                {item[tabPropName] ?? '...'}
                            </Button>
                            <Button
                                variant='secondary'
                                aria-label={this.props.intl.formatMessage(
                                    { id: 'delete-item-button-aria-label' },
                                    { text: item[tabPropName] ?? '...' }
                                )}
                                onClick={() => {
                                    this.removeItem(index);
                                }}
                                disabled={items?.length === 1}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} size='sm' />
                            </Button>
                        </ButtonGroup>
                    );
                })}
                <RestrictedTextInput
                    className='tabbed-container-input'
                    options={this.props.tabOptions}
                    excludedOptions={this.props.excludedTabOptions}
                    onChange={this.addEmptyItem}
                />
                <div>{renderItem(items[activeIndex], activeIndex)}</div>
            </div>
        );
    }
}

export default injectIntl(TabbedContainer);
