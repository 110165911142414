// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './NavigationWithDisplayContainer.css';

export default class NavigationWithDisplayContainer extends Component {
    render() {
        return (
            <div>
                <Row className='navigation-row'>
                    <Col md={3} className='navigation-container'>
                        {this.props.navigationComponent}
                    </Col>
                    <Col md={9} className='display-container'>
                        {this.props.displayComponent}
                    </Col>
                </Row>

                <div>{this.props.actionButtonsComponent}</div>
            </div>
        );
    }
}
