// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import * as FaIcons from '@fortawesome/free-solid-svg-icons';
import NavigationWithDisplayContainer from '../common/NavigationWithDisplayContainer';
import BottomRowButtonActions from '../BottomRowButtonActions';
import MpsNavigation from './navigation/MpsNavigation';
import MpsDisplay from './display/MpsDisplay';
import LabeledInput from '../controls/form/LabeledInput';
import SimpleModal from '../controls/modal/SimpleModal';
import OkCancelModal from '../controls/modal/OkCancelModal';
import { aquireTokenSilentOrPopup, getAccountId } from '../../auth/MsalUtils';
import { deepClone, generateGuid } from './MpsUtils';
import * as MpsConstants from './MpsConstants';
import API from '../../API';
import LoadingSpinner from '../common/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackConfirmationDialog from '../controls/modal/BackConfirmationDialog';
import SubmitDraftModal from '../controls/modal/SubmitDraftModal';
import './MpsContainer.css';

export class MpsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedDraftDetails: null,

            loading: false,
            loadingMessageId: null,

            root: null,
            previousCommitMessages: [],

            showValidationModal: null,
            validation: {
                msg: '',
                variant: 'danger',
                results: ''
            },

            commitMessage: '',
            commitMessageErrorId: null,

            environment: '',
            provider: '',
            page: '',

            showBackConfirmation: false,
            showSubmitDraftModal: false
        };
    }

    intl = this.props.intl;

    componentWillMount = () => {
        if (!this.props.initialState) {
            this.tryFetchUserDraft();
        }
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    // Used to figure out which files are initially present in the repository.
    // This information is later used to mark files for deletion.
    getFlatFileList = (root) => {
        let fileList = [];

        for (let env of root.environments) {
            for (let provider of env.providers) {
                fileList.push(deepClone(provider.configFile.fullPath));

                for (let file of provider.gatewayFiles) {
                    fileList.push(deepClone(file.fullPath));
                }

                for (let file of provider.notificationFiles) {
                    fileList.push(deepClone(file.fullPath));
                }

                for (let file of provider.resourceFiles) {
                    fileList.push(deepClone(file.fullPath));
                }

                for (let file of provider.dataFiles) {
                    fileList.push(deepClone(file.fullPath));
                }
            }
        }

        return fileList;
    };

    pushSuccessToast = (bodyId) => {
        if (!!this.props.pushToast && !!this.mounted) {
            this.props.pushToast({
                variant: 'success',
                titleId: 'toast-title-success',
                bodyId: bodyId
            });
        }
    };

    pushWarningToast = (bodyId) => {
        if (!!this.props.pushToast && !!this.mounted) {
            this.props.pushToast({
                variant: 'warning',
                titleId: 'toast-title-warning',
                bodyId: bodyId
            });
        }
    };

    pushErrorToast = (bodyId) => {
        if (!!this.props.pushToast && !!this.mounted) {
            this.props.pushToast({
                variant: 'danger',
                titleId: 'toast-title-danger',
                bodyId: bodyId
            });
        }
    };

    mpsAuthorizedCall = (isUserCall, subPath, method, body) => {
        return new Promise((resolve, reject) => {
            aquireTokenSilentOrPopup((tokenResponse) => {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken
                    }
                };

                const userId = getAccountId();
                const apiUserPrefix = !!isUserCall ? `/users/${userId}` : '';
                const apiPath = `${apiUserPrefix}/mps/${subPath}`;

                let successCallback = (response) => {
                    if (!!this.mounted) {
                        resolve(response);
                    }
                };
                let errorCallback = (error) => {
                    if (!!this.mounted) {
                        reject(error);
                    }
                };

                if (method === MpsConstants.HTTP_GET) {
                    API.get(apiPath, config).then(successCallback).catch(errorCallback);
                } else if (method === MpsConstants.HTTP_POST) {
                    API.post(apiPath, body, config).then(successCallback).catch(errorCallback);
                } else if (method === MpsConstants.HTTP_DELETE) {
                    API.delete(apiPath, config).then(successCallback).catch(errorCallback);
                }
            });
        });
    };

    mpsValidationCall = (body, draftId) => {
        return new Promise((resolve, reject) => {
            this.mpsAuthorizedCall(true, `drafts/validate/${draftId}`, MpsConstants.HTTP_POST, body)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    tryFetchUserDraft = () => {
        this.setState({ loading: true, loadingMessageId: 'getting-latest-config' });
        this.mpsAuthorizedCall(true, `drafts/${this.props.draft.draftGuid}`, MpsConstants.HTTP_GET)
            .then((response) => {
                if (!!response.data) {
                    let newRoot = response.data.root;
                    this.pushSuccessToast('mps-fetch-user-draft-success');

                    this.setState({
                        loading: false,
                        root: newRoot,
                        allFiles: this.getFlatFileList(newRoot),
                        previousCommitMessages: response.data.commitMessages
                    });
                } else {
                    console.log(error);
                    this.pushErrorToast('mps-fetch-user-draft-error');
                }
            })
            .catch((error) => {
                console.log(error);
                this.pushErrorToast('mps-fetch-user-draft-error');
            });
    };

    resetCommitHistoryModals = () => {
        this.setState({
            commitMessage: '',
            commitMessageErrorId: null
        });
    };

    pruneRoot = (root) => {
        const contentProperties = ['configFile', 'gatewayFiles', 'notificationFiles', 'resourceFiles', 'dataFiles'];
        let newRoot = deepClone(root);

        for (let env of newRoot.environments) {
            env.providers = env.providers.filter((provider) => provider.updatedContentProperties?.length > 0);
            for (let provider of env.providers) {
                let deletedContentProperties = contentProperties.filter((prop) => !provider.updatedContentProperties.includes(prop));
                for (let prop of deletedContentProperties) {
                    delete provider[prop];
                }
            }
        }
        newRoot.environments = newRoot.environments.filter((env) => env.providers?.length > 0);

        return newRoot;
    };

    saveOrSubmitUserDraft = (submit) => {
        const isActivePullRequest = !!this.state.root?.isActivePullRequest;
        let toastMsgObj = isActivePullRequest ? 'sub' : 'draft';
        let toastMsgOp = !!submit ? 'submit' : 'save';
        let toastMsgPrefix = `mps-${toastMsgOp}-${toastMsgObj}`;

        let apiPath = 'drafts' + (!!submit ? '/submit' : '');
        apiPath += `/${this.props.draft.draftGuid}`;
        let body = {
            root: this.pruneRoot(this.state.root),
            message: this.state.commitMessage,
            draftDetails: null
        };

        if (!!submit) {
            body.draftDetails = this.state.updatedDraftDetails;
        }

        this.setState({ loading: true, loadingMessageId: 'validating-config' });
        this.mpsValidationCall(body, this.props.draft.draftGuid)
            .then((validationResponse) => {
                if (!!validationResponse.data) {
                    const isValid = !!validationResponse.data.isValid;
                    const isSaveDraft = !submit && !isActivePullRequest;

                    let variant = 'danger';
                    if (isValid) {
                        variant = 'success';
                    } else if (!isValid && isSaveDraft) {
                        variant = 'warning';
                    }

                    this.configureAndShowValidationModal(variant, validationResponse.data.output);

                    if (isValid || isSaveDraft) {
                        const loadingMessageId = !!submit ? 'submitting-draft' : 'saving-draft';
                        this.setState({ loadingMessageId: loadingMessageId });
                        this.mpsAuthorizedCall(true, apiPath, MpsConstants.HTTP_POST, body)
                            .then((response) => {
                                console.log(response);

                                this.pushSuccessToast(`${toastMsgPrefix}-success`);
                                this.tryFetchUserDraft();
                            })
                            .catch((error) => {
                                console.log(error);

                                if (error.response?.status === 304) {
                                    this.pushWarningToast(`${toastMsgPrefix}-warning`);
                                } else {
                                    this.pushErrorToast(`${toastMsgPrefix}-error`);
                                }

                                this.setState({ loading: false });
                            });
                    } else {
                        this.setState({ loading: false });
                    }
                } else {
                    this.pushErrorToast('mps-validation-request-failed-error');
                }
            })
            .catch((validationError) => {
                console.log(validationError);

                if (validationError.response?.status === 304) {
                    this.pushWarningToast(`${toastMsgPrefix}-warning`);
                } else {
                    this.pushErrorToast('mps-validation-request-failed-error');
                }

                this.setState({ loading: false });
            });

        return true;
    };

    renderSaveModal = () => {
        if (this.state.root?.isActivePullRequest) {
            return null;
        }

        return (
            <Form.Group>
                <Button variant='success' onClick={() => this.saveOrSubmitUserDraft(false)} disabled={!this.state.root?.hasChanges}>
                    <FontAwesomeIcon className='mr-1' size='sm' icon={FaIcons.faSave} />
                    {this.props.intl.formatMessage({ id: 'mps-save-draft' })}
                </Button>
            </Form.Group>
        );
    };

    onSubmitButtonClicked = () => {
        const newDraftDetails = deepClone(this.props.draft);
        this.setState({ updatedDraftDetails: newDraftDetails, showSubmitDraftModal: true });
    };

    renderSubmitModal = () => {
        const buttonTextId = this.state.root?.isActivePullRequest ? 'mps-save-sub' : 'mps-submit-draft';
        const isDisabled = !this.state.root?.isReadyForSubmission && !this.state.root?.hasChanges;

        return (
            <Form.Group>
                <Button variant='primary' onClick={this.onSubmitButtonClicked} disabled={isDisabled}>
                    <FontAwesomeIcon className='mr-1' size='sm' icon={FaIcons.faUpload} />
                    {this.props.intl.formatMessage({ id: buttonTextId })}
                </Button>
            </Form.Group>
        );
    };

    renderUndoModal = () => {
        let buttonVariant = 'warning';
        let buttonIcon = FaIcons.faUndo;
        let buttonTextId = 'mps-undo-changes';
        let buttonDisabled = this.state.loading || !this.state.root?.hasChanges;

        return (
            <OkCancelModal
                buttonVariant={buttonVariant}
                icon={buttonIcon}
                buttonTextId={buttonTextId}
                buttonDisabled={buttonDisabled}
                reuseButton={true}
                onSuccess={() => {
                    this.tryFetchUserDraft();
                    return true;
                }}
            >
                <h5>{this.intl.formatMessage({ id: 'mps-undo-changes-body' })}</h5>
            </OkCancelModal>
        );
    };

    configureAndShowValidationModal = (variant, results) => {
        if (!!this.state.showValidationModal) {
            let validationMsg = this.props.intl.formatMessage({ id: `mps-validation-msg-${variant}` });
            let faIcon = null;
            switch (variant) {
                case 'danger':
                    faIcon = FaIcons.faTimesCircle;
                    break;
                case 'warning':
                    faIcon = FaIcons.faExclamationCircle;
                    break;
                case 'success':
                    faIcon = FaIcons.faCheckCircle;
                    break;
            }

            const iconClassName = `mps-validation-icon-${variant}`;
            const icon = <FontAwesomeIcon icon={faIcon} className={iconClassName} size='xl' />;

            this.setState({
                validation: {
                    msg: validationMsg,
                    icon: icon,
                    variant: variant,
                    results: results
                }
            });

            this.state.showValidationModal();
        }
    };

    addUpdatedContentProperty = (provider, contentProperty) => {
        if (provider != null) {
            provider.updatedContentProperties = [...new Set([...(provider.updatedContentProperties ?? []), contentProperty])];
        }
    };

    handleNavigationChange = (e) => {
        if (!!e.isDeleteProvider && !!e.environment && !!e.provider) {
            let newRoot = this.state.root;
            newRoot.hasChanges = true;

            let selectedEnv = newRoot.environments?.find((item) => item.name === e.environment);
            let selectedProvider = selectedEnv?.providers?.find((item) => item.name === e.provider);
            if (!!selectedProvider.configFile) {
                selectedProvider.configFile.contents = null;
                this.addUpdatedContentProperty(selectedProvider, 'configFile');
            }

            this.setState({ root: newRoot });
        } else if (!!e.isNewProvider && !!e.environment && !!e.provider) {
            let newRoot = this.state.root;
            newRoot.hasChanges = true;

            let selectedEnv = newRoot.environments?.find((item) => item.name === e.environment);
            let newProvider = {
                configFile: deepClone(newRoot.constants.emptyConfigFile),
                gatewayFiles: [],
                notificationFiles: [],
                resourceFiles: [],
                dataFiles: []
            };

            let sep = '/';
            let envProviderSep = selectedEnv.path.endsWith(sep) ? '' : sep;
            newProvider.name = e.provider;
            newProvider.path = `${selectedEnv.path}${envProviderSep}${e.provider}${sep}`;
            newProvider.gatewayPathPrefix = `${newProvider.path}Gateways${sep}`;
            newProvider.notificationPathPrefix = `${newProvider.path}Notifications${sep}`;
            newProvider.resourcePathPrefix = `${newProvider.path}Resources${sep}`;
            newProvider.dataPathPrefix = `${newProvider.path}Data${sep}`;
            newProvider.configFile.fullPath = `${newProvider.path}Configuration.json`;
            newProvider.configFile.contents.providerId = !!e.existingProviderId ? e.existingProviderId : generateGuid();
            newProvider.configFile.contents.defaultLang = 'en-US';
            this.addUpdatedContentProperty(newProvider, 'configFile');
            selectedEnv.providers.push(newProvider);

            this.setState({
                root: newRoot,
                provider: e.provider
            });
        } else {
            // Navigation menu change took place.
            let newState = {};

            if (!!e.environment) {
                newState.environment = e.environment;
            }

            if (!!e.provider) {
                newState.provider = e.provider;
            }

            if (!!e.page) {
                newState.page = e.page;
            }

            this.setState(newState);
        }
    };

    handleDisplayChange = (newValue, environment, provider, ...propNames) => {
        let newRoot = this.state.root;
        let selectedEnv = newRoot.environments?.find((item) => item.name === environment);
        let selectedProvider = selectedEnv?.providers?.find((item) => item.name === provider);

        newRoot.hasChanges = true;
        this.addUpdatedContentProperty(selectedProvider, propNames[0]);

        if (!!selectedProvider) {
            let obj = selectedProvider;
            for (let i = 0; i < propNames.length - 1; i++) {
                obj = obj[propNames[i]];
            }

            obj[propNames[propNames.length - 1]] = newValue;
        }

        this.setState({ root: newRoot });
    };

    getMpsNavigation() {
        return (
            <MpsNavigation
                root={this.state.root}
                environment={this.state.environment}
                provider={this.state.provider}
                page={this.state.page}
                onChange={this.handleNavigationChange}
            />
        );
    }

    getMpsDisplay() {
        return (
            <MpsDisplay
                draft={this.props.draft}
                root={this.state.root}
                allFiles={this.state.allFiles}
                pushToast={this.props.pushToast}
                environment={this.state.environment}
                provider={this.state.provider}
                page={this.state.page}
                onChange={this.handleDisplayChange}
            />
        );
    }

    goBackToLanding() {
        this.props.onBackButtonClicked();
        this.setState({ showBackConfirmation: false });
    }

    onBackButtonClicked() {
        if (this.state.root?.hasChanges) {
            this.setState({ showBackConfirmation: true });
            return;
        }

        this.props.onBackButtonClicked();
    }

    onSubmitDraft = () => {
        this.setState({ showSubmitDraftModal: false });
        this.saveOrSubmitUserDraft(true);
    };

    onDraftDetailsUpdated = (newDraftDetails) => {
        this.setState({ updatedDraftDetails: newDraftDetails });
    };

    render() {
        let mainContent = null;
        if (this.state.loading) {
            mainContent = <LoadingSpinner loadingTextId={this.state.loadingMessageId} />;
        } else {
            mainContent = (
                <div className='mps-container'>
                    <Row style={{ marginBottom: '8px' }}>
                        <Col md={1}>
                            <Button onClick={() => this.onBackButtonClicked()} style={{ width: '76px' }}>
                                <FontAwesomeIcon icon={FaIcons.faAngleLeft} style={{ marginRight: '4px' }} />
                                {this.props.intl.formatMessage({ id: 'back' })}
                            </Button>
                        </Col>
                        <Col md={{ span: 3, offset: 4 }}>
                            <p className='text-center' style={{ fontWeight: 600, fontSize: 'larger' }}>
                                {this.props.draft.title}
                            </p>
                        </Col>
                    </Row>
                    <NavigationWithDisplayContainer navigationComponent={this.getMpsNavigation()} displayComponent={this.getMpsDisplay()} />
                </div>
            );
        }

        let bottomRowButtons = this.state.loading ? null : (
            <div>
                <div className='p-1' />
                <BottomRowButtonActions>
                    {this.renderUndoModal()}
                    {this.renderSaveModal()}
                    {this.renderSubmitModal()}
                </BottomRowButtonActions>
            </div>
        );

        return (
            <div>
                {mainContent}
                {bottomRowButtons}
                <SimpleModal
                    titleId={`mps-validation-title-${this.state.validation?.variant}`}
                    titleIcon={this.state.validation?.icon}
                    setShowModal={(newHandler) => {
                        this.setState({ showValidationModal: newHandler });
                    }}
                    hideButton={true}
                >
                    <div style={{ whiteSpace: 'pre-wrap' }}>{this.state.validation?.msg}</div>
                    <LabeledInput
                        type='textarea'
                        controlId='validationResults'
                        labelId='mps-validation-results-label'
                        value={this.state.validation?.results}
                        disabled={true}
                        rows={16}
                    />
                </SimpleModal>
                <BackConfirmationDialog
                    show={this.state.showBackConfirmation}
                    handleClose={() => this.setState({ showBackConfirmation: false })}
                    handleSubmit={() => this.goBackToLanding()}
                />
                <SubmitDraftModal
                    show={this.state.showSubmitDraftModal}
                    draftDetails={this.state.updatedDraftDetails}
                    onDraftDetailsUpdated={this.onDraftDetailsUpdated}
                    handleClose={() => this.setState({ showSubmitDraftModal: false })}
                    handleSubmit={this.onSubmitDraft}
                />
            </div>
        );
    }
}

export default injectIntl(MpsContainer);
