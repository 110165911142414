// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import TooltipLabel from '../../controls/form/TooltipLabel';
import LabeledInput from '../../controls/form/LabeledInput';
import SimpleModal from '../../controls/modal/SimpleModal';
import ExpandableList from '../../controls/list/ExpandableList';
import CollapsableContainer from '../../controls/container/CollapsableContainer';
import TabbedContainer from '../../controls/container/TabbedContainer';
import * as MpsConstants from '../MpsConstants';
import { deepClone, generateGuid } from '../MpsUtils';

export class MpsGatewaysPage extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
        this.didGatewaysChange = false;
    }

    intl = this.props.intl;

    handleConfigValueChange = (newValue, ...propNames) => {
        if (!!this.props.onChange) {
            this.props.onChange(newValue, 'configFile', 'contents', ...propNames);
        }
    };

    handleGatewayValueChange = (newValue, id, lang, ...propNames) => {
        let { gatewayFiles } = this.props;
        if (Array.isArray(gatewayFiles) && !!this.props.onChange) {
            let index = gatewayFiles.findIndex((gwFile) => gwFile.id === id && gwFile.lang === lang);
            this.props.onChange(newValue, 'gatewayFiles', index, 'contents', ...propNames);
        }
    };

    handleGatewayFilesChange = (addedFiles, removedFiles, overrides) => {
        if (Array.isArray(this.props.gatewayFiles) && !!this.props.onChange) {
            let gwFiles = deepClone(this.props.gatewayFiles);

            for (let addedFile of addedFiles.reverse()) {
                let deleteIndex = gwFiles.findIndex((file) => file.fullPath === addedFile.fullPath);
                if (deleteIndex >= 0) {
                    gwFiles.splice(deleteIndex, 1);
                }

                let id = overrides?.addId ?? addedFile.id;
                let insertIndex = overrides?.addAtEnd ? gwFiles.length : gwFiles.map((file) => file.id).lastIndexOf(id) + 1;
                if (insertIndex === 0) {
                    insertIndex = gwFiles.length;
                }

                gwFiles.splice(insertIndex, 0, addedFile);
            }

            for (let removedFile of removedFiles) {
                let deleteIndex = gwFiles.findIndex((file) => file.fullPath === removedFile.fullPath);

                if (this.props.allFiles.includes(removedFile.fullPath)) {
                    gwFiles[deleteIndex].contents = null;
                } else {
                    gwFiles.splice(deleteIndex, 1);
                }
            }

            this.didGatewaysChange = true;
            this.props.onChange(gwFiles, 'gatewayFiles');
        }
    };

    // WARN: when deleting or renaming a file, the REST API expects a JSON object
    // with the "contents" set to null and the original "fullPath" intact.
    // e.g. { contents: null, fullPath: "..." } - mark this file for deletion.
    handleGatewayFilesIdChange = (newId, oldId) => {
        if (Array.isArray(this.props.gatewayFiles) && !!this.props.onChange && !!this.props.gatewayPathPrefix) {
            let gwFiles = this.props.gatewayFiles;
            if (gwFiles.some((file) => file.id === newId && !!file.contents)) {
                return;
            }

            let oldGwFiles = deepClone(gwFiles.filter((file) => file.id === oldId));
            let newGwFiles = deepClone(oldGwFiles);
            newGwFiles.forEach((newGwFile) => {
                let dummy = this.getEmptyGatewayFile(newId, newGwFile.lang);

                newGwFile.id = dummy.id;
                newGwFile.fileName = dummy.fileName;
                newGwFile.fullPath = dummy.fullPath;
            });

            this.handleGatewayFilesChange(newGwFiles, oldGwFiles, { addId: oldId });
        }
    };

    getGatewayFileIds = (gatewayFiles) => {
        // Keep the old, existing IDs sorted.
        let oldNonUniqueIds = gatewayFiles?.filter((gwFile) => !!gwFile.contents && !gwFile.isNew)?.map((gwFile) => gwFile.id) ?? [];

        oldNonUniqueIds?.sort((left, right) => {
            return left - right;
        });

        // Maintain the order of the newly added IDs. Do NOT sort!
        let newNonUniqueIds = gatewayFiles?.filter((gwFile) => !!gwFile.contents && !!gwFile.isNew)?.map((gwFile) => gwFile.id) ?? [];

        let uniqueIds = [...new Set([...oldNonUniqueIds, ...newNonUniqueIds])];
        return uniqueIds;
    };

    getAvailableIds = () => {
        let gwFileIds = this.getGatewayFileIds(this.props.gatewayFiles);
        let availableIds = [...Array(MpsConstants.MAX_GATEWAYS).keys()].filter((id) => gwFileIds.indexOf(id) < 0);

        return availableIds;
    };

    getGatewayFilesById = (id) => {
        return this.props.gatewayFiles?.filter((gwFile) => gwFile.id === id && !!gwFile.contents);
    };

    getEmptyGatewayFile = (id, lang, controlGuid = generateGuid()) => {
        let emptyGwFile = deepClone(this.props.constants?.emptyGatewayFile) || { contents: {} };
        emptyGwFile.id = parseInt(id);
        emptyGwFile.lang = lang;
        emptyGwFile.fileName = emptyGwFile.fileName.replace('{id}', id).replace('{lang}', lang);
        emptyGwFile.fullPath = this.props.gatewayPathPrefix + emptyGwFile.fileName;
        emptyGwFile.isNew = true;
        emptyGwFile.controlGuid = controlGuid;

        return emptyGwFile;
    };

    addEmptyGatewayFile = () => {
        if (!!this.props.onChange) {
            let availableIds = this.getAvailableIds();
            if (availableIds.length <= 0) {
                return;
            }

            let emptyGwFile = this.getEmptyGatewayFile(availableIds[0], this.props.config?.defaultLang ?? 'en-US');
            this.handleGatewayFilesChange([emptyGwFile], [], { addAtEnd: true });
        }
    };

    componentDidUpdate(prevProps) {
        if (this.didGatewaysChange) {
            const gatewayFilesLength = this.getGatewayFileIds(this.props.gatewayFiles).length;
            const prevGatewayFilesLength = this.getGatewayFileIds(prevProps.gatewayFiles).length;

            if (gatewayFilesLength !== prevGatewayFilesLength && gatewayFilesLength > 0) {
                this.mainRef.current.focus();
            }
        }

        this.didGatewaysChange = false;
    }

    renderGatewayFileItem = (gwFileItem, gwFileIndex) => {
        let ctrlIdSuffix = gwFileItem.id + gwFileItem.lang;
        let contents = gwFileItem.contents;

        return (
            <div>
                <hr />
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='color'
                            controlId={'backgroundColor' + ctrlIdSuffix}
                            labelId='mps-gateway-backgroundcolor-label'
                            tooltipId='mps-gateway-backgroundcolor-tooltip'
                            value={contents?.backgroundColor}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'backgroundColor');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='color'
                            controlId={'hyperlinkFontColor' + ctrlIdSuffix}
                            labelId='mps-gateway-hyperlinkfontcolor-label'
                            tooltipId='mps-gateway-hyperlinkfontcolor-tooltip'
                            value={contents?.hyperlinkFontColor}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'hyperlinkFontColor');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='color'
                            controlId={'bodyFontColor' + ctrlIdSuffix}
                            labelId='mps-gateway-bodyfontcolor-label'
                            tooltipId='mps-gateway-bodyfontcolor-tooltip'
                            value={contents?.bodyFontColor}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'bodyFontColor');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='textarea'
                            controlId={'bodyText' + ctrlIdSuffix}
                            labelId='mps-gateway-bodytext-label'
                            tooltipId='mps-gateway-bodytext-tooltip'
                            value={contents?.bodyText}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'bodyText');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='color'
                            controlId={'buttonFontColor' + ctrlIdSuffix}
                            labelId='mps-gateway-buttonfontcolor-label'
                            tooltipId='mps-gateway-buttonfontcolor-tooltip'
                            value={contents?.buttonFontColor}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'buttonFontColor');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId={'buttonText' + ctrlIdSuffix}
                            labelId='mps-gateway-buttontext-label'
                            tooltipId='mps-gateway-buttontext-tooltip'
                            value={contents?.buttonText}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'buttonText');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='color'
                            controlId={'buttonColor' + ctrlIdSuffix}
                            labelId='mps-gateway-buttoncolor-label'
                            tooltipId='mps-gateway-buttoncolor-tooltip'
                            value={contents?.buttonColor}
                            onChange={(newValue) => {
                                this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'buttonColor');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-gateway-images-label'
                            tooltipId='mps-gateway-images-tooltip'
                            count={contents?.images?.length}
                            highContrast
                        >
                            <ExpandableList
                                labelId='mps-gateway-images-label'
                                tooltipId='mps-gateway-images-tooltip'
                                deleteButtonAriaId='mps-gateway-images-delete-button'
                                items={contents?.images}
                                renderItem={(imgItem, imgIndex, containerRef) => {
                                    return (
                                        <LabeledInput
                                            type='select'
                                            controlId={`imageFileName${imgIndex}-${ctrlIdSuffix}`}
                                            value={imgItem?.filename}
                                            options={['', ...this.props.availableResourceFileNames]}
                                            onChange={(newValue) => {
                                                this.handleGatewayValueChange(
                                                    newValue,
                                                    gwFileItem.id,
                                                    gwFileItem.lang,
                                                    'images',
                                                    imgIndex,
                                                    'filename'
                                                );
                                            }}
                                            inputRef={containerRef}
                                        />
                                    );
                                }}
                                emptyItem={{ filename: '' }}
                                onChange={(newValue) => {
                                    this.handleGatewayValueChange(newValue, gwFileItem.id, gwFileItem.lang, 'images');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
            </div>
        );
    };

    render() {
        let config = !!this.props.config ? this.props.config : {};
        let gatewayFileIds = this.getGatewayFileIds(this.props.gatewayFiles);
        let unusedGatewayFileIds = this.getAvailableIds();
        let rowPadding = 'p-3';

        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId='defaultGatewayId'
                            labelId='mps-defaultgatewayid-label'
                            tooltipId='mps-defaultgatewayid-tooltip'
                            value={config.defaultGatewayId}
                            options={[null, ...gatewayFileIds]}
                            onChange={(newValue) => {
                                this.handleConfigValueChange(newValue, 'defaultGatewayId');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId='offerUri'
                            labelId='mps-offeruri-label'
                            tooltipId='mps-offeruri-tooltip'
                            value={config.offerUri}
                            onChange={(newValue) => {
                                this.handleConfigValueChange(newValue, 'offerUri');
                            }}
                        />
                    </Col>
                </Row>
                <hr />
                <TooltipLabel labelId='mps-gatewayfiles-label' tooltipId='mps-gatewayfiles-tooltip' />
                {gatewayFileIds.map((gwFileId, gwFileIndex) => {
                    let labelText = this.props.intl.formatMessage({ id: 'mps-gatewayfile-label' }, { id: gwFileId });

                    let items = this.getGatewayFilesById(gwFileId);
                    let excludedTabOptions = items.map((item) => item.lang);
                    let rowClassName = gwFileIndex % 2 === 0 ? 'bg-light' : 'bg-white';
                    rowClassName += ` ${rowPadding}`;

                    const containerRef = gwFileIndex === gatewayFileIds.length - 1 ? this.mainRef : null;

                    const controlGuid = items[0].controlGuid;

                    return (
                        <div className={rowClassName} key={`gatewayId${controlGuid}`}>
                            <CollapsableContainer
                                label={labelText}
                                controlId={`gatewayCollapsableContainerId${controlGuid}`}
                                inputRef={containerRef}
                                controls={[
                                    <Button
                                        variant='outline-secondary'
                                        size='sm'
                                        aria-label={this.props.intl.formatMessage(
                                            { id: 'delete-item-button-aria-label' },
                                            { text: labelText }
                                        )}
                                        onClick={() =>
                                            this.handleGatewayFilesChange(
                                                [],
                                                this.props.gatewayFiles?.filter((file) => file.id === gwFileId)
                                            )
                                        }
                                        disabled={config.defaultGatewayId === gwFileId}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} size='sm' />
                                    </Button>
                                ]}
                            >
                                <Row>
                                    <Col>
                                        <LabeledInput
                                            type='select'
                                            controlId={'gatewayFileId' + gwFileId}
                                            labelId='mps-gatewayfile-id-label'
                                            tooltipId='mps-gatewayfile-id-tooltip'
                                            value={gwFileId}
                                            options={[gwFileId, ...unusedGatewayFileIds]}
                                            onChange={(newId) => this.handleGatewayFilesIdChange(parseInt(newId), gwFileId)}
                                            disabled={config.defaultGatewayId === gwFileId}
                                        />
                                    </Col>
                                    <Col>{/* BLANK */}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TabbedContainer
                                            controlId={`gatewayTabbedContainerId${gwFileId}`}
                                            items={items}
                                            emptyItem={this.getEmptyGatewayFile(gwFileId, '{lang}', controlGuid)}
                                            renderItem={this.renderGatewayFileItem}
                                            onChange={this.handleGatewayFilesChange}
                                            tabOptions={this.props.constants?.languages}
                                            excludedTabOptions={excludedTabOptions}
                                            tabPropName='lang'
                                            placeholderPropNames={['fileName', 'fullPath']}
                                        />
                                    </Col>
                                </Row>
                            </CollapsableContainer>
                        </div>
                    );
                })}
                <Row>
                    <Col>
                        <Button
                            onClick={this.addEmptyGatewayFile}
                            className='mt-2 mb-2 w-100'
                            disabled={unusedGatewayFileIds.length <= 0}
                            aria-label={this.props.intl.formatMessage({ id: 'mps-add-gateway-aria-label' })}
                        >
                            <FontAwesomeIcon className='mr-1' icon={faPlusCircle} size='lg' />
                            {this.props.intl.formatMessage({ id: 'add' })}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default injectIntl(MpsGatewaysPage);
