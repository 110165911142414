// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BottomRowButtonActions.css';

export default class BottomRowButtonActions extends Component {
    render() {
        const cols = this.props.children.map((child, index) => (
            <Col key={`bottomRowButtonAction-${index}`} sm={3}>
                {child}
            </Col>
        ));
        return (
            <Container>
                <Row className='bottom-action-buttons justify-content-sm-center'>{cols}</Row>
            </Container>
        );
    }
}
