import { Component, createRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DraftDetails from '../DraftDetailsjs';
import SelectDraftContainer from '../select-draft-container/SelectDraftContainer';
import { aquireTokenSilentOrPopup, createAuthorizationHeaders, getAccountId } from '../../../../auth/MsalUtils';
import API from '../../../../API';
import DiscardDraftModal from '../DiscardDraftModal';
import './DraftsContainer.css';
import { injectIntl } from 'react-intl';
import LoadingSpinner from '../../../common/LoadingSpinner';

export class DraftsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            draftDetails: null,
            selectedDraft: null,
            draftList: [],
            showDiscardDraftModal: false,
            isLoading: false,
            loadingMessageId: null
        };

        this.openDraftButtonRef = createRef();
    }

    componentWillMount() {
        this.requestDrafts();
    }

    componentDidUpdate(prevProp, prevState) {
        if (this.state.selectedDraft != null && prevState.selectedDraft != this.state.selectedDraft) {
            this.openDraftButtonRef.current.focus();
        }
    }

    showLoadingScreen(loadingMessage) {
        this.setState({ isLoading: true, loadingMessageId: loadingMessage });
    }

    hideLoadingScreen() {
        this.setState({ isLoading: false, loadingMessageId: null });
    }

    requestDrafts() {
        this.showLoadingScreen('getting-drafts');
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            API.get(`/users/${userGuid}/drafts/all`, headers)
                .then((response) => {
                    this.setState({ draftList: response.data.drafts });

                    // If a draft is selected, we need to update the details with what we got in the response
                    if (!!this.state.selectedDraft) {
                        const selectedDraft = response.data.drafts.find((draft) => draft.draftGuid === this.state.selectedDraft.draftGuid);
                        const updatedDraftDetails = this.createDraftDetailsItem(selectedDraft);
                        this.setState({ draftDetails: updatedDraftDetails });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'failed-to-get-drafts' }));
                })
                .finally(() => {
                    this.hideLoadingScreen();
                });
        });
    }

    handleDraftSelected = (draftItem) => {
        const newDraftDetails = this.createDraftDetailsItem(draftItem);
        this.setState({ draftDetails: newDraftDetails, selectedDraft: draftItem });
    };

    createDraftDetailsItem(draftItem) {
        const newDraftDetails = {
            title: draftItem.title,
            type: draftItem.type,
            modifiedDate: draftItem.lastUpdated,
            description: draftItem.description,
            background: draftItem.background
        };

        return newDraftDetails;
    }

    onDraftDetailsUpdate = (newDraftDetails) => {
        newDraftDetails.isEdited = true;
        this.setState({ draftDetails: newDraftDetails });
    };

    onSaveDraftDetails = () => {
        this.sendUpdatedDraftRequest();
    };

    sendUpdatedDraftRequest() {
        this.showLoadingScreen('updating-draft-details');
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            const selectedDraft = this.state.draftList.find((draftItem) => draftItem.draftGuid === this.state.selectedDraft.draftGuid);

            if (!selectedDraft) {
                console.log("Couldn't find the draft with guid " + this.state.selectedDraft.draftGuid);
            }

            let draftDetails = this.state.draftDetails;
            const apiEndpoint =
                selectedDraft.type === 'Cosa'
                    ? `/users/${userGuid}/cosa/drafts/${selectedDraft.pullRequestId}`
                    : `/users/${userGuid}/mps/drafts/${selectedDraft.pullRequestId}`;
            API.post(
                apiEndpoint,
                {
                    title: draftDetails.title,
                    description: draftDetails.description,
                    background: draftDetails.background
                },
                headers
            )
                .then(() => {
                    this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'draft-details-updated-success' }));
                    this.requestDrafts();
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'draft-details-updated-failed' }));
                    this.hideLoadingScreen();
                });
        });
    }

    onNewDraftSubmitted = (newDraft) => {
        this.showLoadingScreen('creating-new-draft');
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            const apiEndpoint = newDraft.type === 'COSA' ? `/users/${userGuid}/cosa/drafts/create` : `/users/${userGuid}/mps/drafts/create`;

            API.post(
                apiEndpoint,
                {
                    title: newDraft.draftTitle,
                    description: newDraft.description,
                    background: newDraft.background
                },
                headers
            )
                .then(() => {
                    this.requestDrafts();
                    this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'new-draft-create-success' }));
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'new-draft-create-failed' }));
                    this.hideLoadingScreen();
                });
        });
    };

    discardSelectedDraft = () => {
        if (!this.state.selectedDraft) {
            return;
        }

        this.showLoadingScreen('discarding-draft');

        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            const apiEndpoint =
                this.state.draftDetails.type === 'Cosa'
                    ? `/users/${userGuid}/cosa/drafts/${this.state.selectedDraft.draftGuid}`
                    : `/users/${userGuid}/mps/drafts/${this.state.selectedDraft.draftGuid}`;

            API.delete(apiEndpoint, headers)
                .then(() => {
                    this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'draft-discard-success' }));
                    this.setState({ selectedDraft: null, draftDetails: null });
                    this.requestDrafts();
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'draft-discard-failed' }));
                    this.hideLoadingScreen();
                });
        });
    };

    onDiscardDraftButtonClicked = () => {
        this.setState({ showDiscardDraftModal: true });
    };

    onDiscardDraft = () => {
        this.discardSelectedDraft();
        this.closeDiscardDraftModal();
    };

    closeDiscardDraftModal = () => {
        this.setState({
            showDiscardDraftModal: false
        });
    };

    isDraftSelected() {
        return !!this.state.selectedDraft;
    }

    onOpenDraftButtonClicked = () => {
        if (this.state.selectedDraft.type === 'Cosa') {
            this.props.openCosaConfig(this.state.selectedDraft);
        } else {
            this.props.openMpsConfig(this.state.selectedDraft);
        }
    };

    getLoadingScreen() {
        return <LoadingSpinner loadingTextId={this.state.loadingMessageId} />;
    }

    getDraftButtonOptions() {
        if (this.state.draftList === null || this.state.draftList.length === 0) {
            return '';
        }
        return (
            <Row className='justify-content-sm-center'>
                <Col sm='2'>
                    <Button
                        className='open-draft-btn'
                        style={{ minWidth: '135px', width: '100%', marginBottom: '8px' }}
                        disabled={!this.isDraftSelected()}
                        onClick={this.onOpenDraftButtonClicked}
                        ref={this.openDraftButtonRef}
                        aria-label={this.props.intl.formatMessage(
                            { id: 'open-draft-aria-label' },
                            { draftName: this.state.selectedDraft?.title }
                        )}
                    >
                        {this.props.intl.formatMessage({ id: 'open-draft' })}
                    </Button>
                </Col>
                <Col sm={{ span: 2, offset: 2 }}>
                    <Button
                        className='discard-draft-btn'
                        variant='danger'
                        style={{ minWidth: '135px', width: '100%' }}
                        disabled={!this.isDraftSelected()}
                        onClick={this.onDiscardDraftButtonClicked}
                        aria-label={this.props.intl.formatMessage(
                            { id: 'discard-draft-aria-label' },
                            { draftName: this.state.selectedDraft?.title }
                        )}
                    >
                        {this.props.intl.formatMessage({ id: 'discard-draft' })}
                    </Button>
                </Col>
            </Row>
        );
    }

    getDraftDetailsPane() {
        if (this.state.draftList === null || this.state.draftList.length === 0) {
            return (
                <div className='create-draft'>
                    <p>{this.props.intl.formatMessage({ id: 'create-draft-to-start' })}</p>
                </div>
            );
        }

        return (
            <DraftDetails
                draftDetails={this.state.draftDetails}
                onDraftDetailsUpdate={this.onDraftDetailsUpdate}
                onSaveDraftDetails={this.onSaveDraftDetails}
            />
        );
    }

    getTableAndData() {
        return (
            <div>
                <Row>
                    <Col lg='8'>
                        <SelectDraftContainer
                            draftList={this.state.draftList}
                            selectedDraft={this.state.selectedDraft}
                            onDraftSelected={this.handleDraftSelected}
                            onNewDraftSubmitted={this.onNewDraftSubmitted}
                        />
                        {this.getDraftButtonOptions()}
                    </Col>
                    <Col lg='4'>{this.getDraftDetailsPane()}</Col>
                </Row>
                <div>
                    <DiscardDraftModal
                        show={this.state.showDiscardDraftModal}
                        onDiscardDraft={this.onDiscardDraft}
                        handleClose={this.closeDiscardDraftModal}
                    />
                </div>
            </div>
        );
    }

    render() {
        let content = null;
        if (this.state.isLoading) {
            content = this.getLoadingScreen();
        } else {
            content = this.getTableAndData();
        }

        return <div>{content}</div>;
    }
}

export default injectIntl(DraftsContainer);
