// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import SettingTreeName from './SettingTreeName';
import { getSettingValuesForPath, MAP, TREE, generateNewSettingValue } from '../settingtypes/SettingFactory';
import './SettingTreeItem.css';
import { deepClone } from '../../mps/MpsUtils';

export default class SettingTreeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
        this.mapChildSettingRef = React.createRef();
    }

    isTreeItem() {
        if (this.props.setting.Type === TREE && this.props.setting.ChildSettings != null) {
            for (let i = 0; i < this.props.setting.ChildSettings.length; i++) {
                const childSettingType = this.props.setting.ChildSettings[i].Type;
                if (childSettingType === MAP || childSettingType === TREE) {
                    return true;
                }
            }
        } else if (this.props.setting.Type === MAP && this.props.setting.Validation.MapSettingValues != null) {
            for (let i = 0; i < this.props.setting.Validation.MapSettingValues.length; i++) {
                const childSettingType = this.props.setting.Validation.MapSettingValues[i].Type;
                if (childSettingType === MAP || childSettingType === TREE) {
                    return true;
                }
            }
        }

        return false;
    }

    handleOnExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    handleOnItemSelected = () => {
        this.setState({ isExpanded: true });
        if (this.hasChangeableSettings()) {
            this.props.onChildItemSelected(this.props.setting, this.props.settingValue, this.props.itemPath);
        }
    };

    mapSettingWithValue(setting, settingValue, childItemPath, containerRef) {
        return (
            <SettingTreeItem
                setting={setting}
                settingValue={settingValue}
                onChildItemSelected={this.props.onChildItemSelected}
                itemPath={childItemPath}
                canBeDeleted={this.props.setting.IsInstance}
                onNavigationItemAdded={this.props.onNavigationItemAdded}
                onNavigationItemDeleted={this.props.onNavigationItemDeleted}
                selectedItemPath={this.props.selectedItemPath}
                inputRef={containerRef}
            />
        );
    }

    getInstancesSettingValuesForSetting(instanceSetting, settingValue) {
        if (settingValue == null) {
            return [];
        }
        const instances = [];
        for (let i = 0; i < settingValue.childSettings.length; i++) {
            let childSetting = settingValue.childSettings[i];
            if (instanceSetting.Path === childSetting.path) {
                instances.push(childSetting);
            }
        }

        return instances;
    }

    getTreeSettingChildrenForDisplay() {
        let childrenSettings = [];
        for (let i = 0; i < this.props.setting.ChildSettings.length; i++) {
            const childSetting = this.props.setting.ChildSettings[i];
            if (childSetting.Type === TREE || childSetting.Type === MAP) {
                const settingValue = getSettingValuesForPath(
                    childSetting.Path,
                    this.props.settingValue == null ? null : this.props.settingValue.childSettings,
                    true
                );
                const childSettingPathSplit = childSetting.Path.split('/');
                const childItemPath = this.props.itemPath + '/' + childSettingPathSplit[childSettingPathSplit.length - 1];
                childrenSettings.push(this.mapSettingWithValue(childSetting, settingValue, childItemPath));
            }
        }

        return childrenSettings;
    }

    getMapSettingChildrenForDisplay() {
        let childrenSettings = [];
        const settingToDisplay = this.props.setting.Validation.MapSettingValues[0];
        if (settingToDisplay.Type === TREE || settingToDisplay.Type === MAP) {
            const childSettingPathSplit = settingToDisplay.Path.split('/');
            if (this.props.setting.IsInstance) {
                const instancesForSetting = this.getInstancesSettingValuesForSetting(settingToDisplay, this.props.settingValue);
                instancesForSetting.forEach((instance, index) => {
                    const childItemPath =
                        this.props.itemPath + '/' + childSettingPathSplit[childSettingPathSplit.length - 1] + '.' + instance.guid;
                    const containerRef = index === instancesForSetting.length - 1 ? this.mapChildSettingRef : null;
                    childrenSettings.push(this.mapSettingWithValue(settingToDisplay, instance, childItemPath, containerRef));
                });
            } else {
                const childItemPath = this.props.itemPath + '/' + childSettingPathSplit[childSettingPathSplit.length - 1];
                const settingValue = getSettingValuesForPath(
                    settingToDisplay.Path,
                    this.props.settingValue == null ? null : this.props.settingValue.childSettings
                );
                childrenSettings.push(this.mapSettingWithValue(settingToDisplay, settingValue, childItemPath));
            }
        }

        return childrenSettings;
    }

    getSettingsForDisplay() {
        let childrenSettings = [];

        if (!this.isTreeItem()) {
            return childrenSettings;
        }

        if (this.props.setting.Type === TREE) {
            childrenSettings = this.getTreeSettingChildrenForDisplay();
        } else if (this.props.setting.Type === MAP) {
            childrenSettings = this.getMapSettingChildrenForDisplay();
        }

        return childrenSettings;
    }

    hasChangeableSettings() {
        if (this.props.setting.Type === TREE && this.props.setting.ChildSettings != null) {
            for (let i = 0; i < this.props.setting.ChildSettings.length; i++) {
                const childSetting = this.props.setting.ChildSettings[i];
                if (childSetting.Type != TREE && childSetting.Type != MAP) {
                    return true;
                }
            }
        } else if (this.props.setting.Type === MAP && this.props.setting.Validation.MapSettingValues != null) {
            for (let i = 0; i < this.props.setting.Validation.MapSettingValues.length; i++) {
                const childSetting = this.props.setting.Validation.MapSettingValues[i];
                if (childSetting.Type != TREE && childSetting.Type != MAP) {
                    return true;
                }
            }
        }

        return false;
    }

    handleDeleteSetting = () => {
        this.props.onNavigationItemDeleted(this.props.itemPath);
    };

    handleAddingSetting = () => {
        let settingValue = deepClone(this.props.settingValue);
        if (settingValue == null) {
            settingValue = generateNewSettingValue(this.props.setting, null);
        }

        if (settingValue.childSettings == null) {
            settingValue.childSettings = [];
        }

        const childSettingValue = generateNewSettingValue(this.props.setting.Validation.MapSettingValues[0], null);
        settingValue.childSettings.push(childSettingValue);

        this.setState({ isExpanded: true });
        this.props.onNavigationItemAdded(settingValue, this.props.itemPath);
    };

    getAddChildAriaName() {
        if (!this.props.setting.IsInstance) {
            return null;
        }

        const childSetting = this.props.setting.Validation.MapSettingValues[0];
        return childSetting.DisplayName;
    }

    componentDidUpdate(prevProps) {
        if (!this.isTreeItem() || !this.props.setting.IsInstance || this.props.setting.Type === TREE) {
            return;
        }

        const settingToDisplay = this.props.setting.Validation.MapSettingValues[0];
        if (settingToDisplay.Type === TREE || settingToDisplay.Type === MAP) {
            const currentInstancesForSettingLength = this.getInstancesSettingValuesForSetting(
                settingToDisplay,
                this.props.settingValue
            ).length;
            const prevInstancesForSettingLength = this.getInstancesSettingValuesForSetting(settingToDisplay, prevProps.settingValue).length;

            if (currentInstancesForSettingLength > 0 && currentInstancesForSettingLength !== prevInstancesForSettingLength) {
                this.mapChildSettingRef.current.focus();
            }
        }
    }

    render() {
        const childSettings = this.getSettingsForDisplay();
        const isSelected = this.props.itemPath === this.props.selectedItemPath;
        const settingNameId =
            this.props.setting.Path === '/Connections/Cellular/Connection' ? this.props.settingValue.attributes[0].value : null;
        return (
            <div>
                <SettingTreeName
                    settingName={this.props.setting.DisplayName}
                    settingId={settingNameId}
                    isTreeItem={this.isTreeItem()}
                    isExpanded={this.state.isExpanded}
                    onExpand={this.handleOnExpand}
                    onItemSelected={this.handleOnItemSelected}
                    canBeDeleted={this.props.canBeDeleted}
                    onDeleteClicked={this.handleDeleteSetting}
                    canAddSetting={this.props.setting.IsInstance}
                    onAddClicked={this.handleAddingSetting}
                    isSelected={isSelected}
                    addChildAriaLabel={this.getAddChildAriaName()}
                    inputRef={this.props.inputRef}
                />
                <div className='setting-item-children'>{this.state.isExpanded && childSettings}</div>
            </div>
        );
    }
}
