// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import { isNumNonZeroOrEmpty, deepClone, generateGuid } from '../../mps/MpsUtils';
import LabeledInput from '../form/LabeledInput';
import ExpandableList from './ExpandableList';
import './DoubleTextList.css';

export class DoubleTextList extends Component {
    handleItemValueChange = (newValue, index, propName) => {
        if (!!this.props.onChange) {
            if (!!this.props.numeric && !isNumNonZeroOrEmpty(newValue)) {
                return;
            }

            let newItems = deepClone(this.props.items);
            newItems[index][propName] = newValue;

            this.props.onChange(newItems);
        }
    };

    handleItemsListChange = (newItems) => {
        if (!!this.props.onChange) {
            this.props.onChange(newItems);
        }
    };

    renderItem = (item, index, containerRef) => {
        let firstType = !!this.props.firstType ? this.props.firstType : 'text';
        let secondType = !!this.props.secondType ? this.props.secondType : 'text';

        let { controlId } = this.props;
        if (!controlId) {
            controlId = generateGuid();
        }

        return (
            <Container>
                <Row>
                    <Col className='device-list-col-first'>
                        <LabeledInput
                            type={firstType}
                            controlId={`${controlId}-First${index}`}
                            labelId={this.props.firstLabelId}
                            value={item[this.props.firstPropName]}
                            onChange={(newItem) => this.handleItemValueChange(newItem, index, this.props.firstPropName)}
                            inputRef={containerRef}
                        />
                    </Col>
                    <Col className='device-list-col-second'>
                        <LabeledInput
                            type={secondType}
                            controlId={`${controlId}-Second${index}`}
                            labelId={this.props.secondLabelId}
                            value={item[this.props.secondPropName]}
                            onChange={(newItem) => this.handleItemValueChange(newItem, index, this.props.secondPropName)}
                        />
                    </Col>
                </Row>
                {!!this.props.renderItemChild ? this.props.renderItemChild(item, index) : null}
            </Container>
        );
    };

    render() {
        let { label, labelId, tooltip, tooltipId, overrideEmptyItem, items, deleteButtonAriaId } = this.props;

        var emptyItem = !!overrideEmptyItem
            ? overrideEmptyItem
            : {
                  [this.props.firstPropName]: '',
                  [this.props.secondPropName]: ''
              };

        return (
            <div>
                <ExpandableList
                    label={label}
                    labelId={labelId}
                    tooltip={tooltip}
                    tooltipId={tooltipId}
                    deleteButtonAriaId={deleteButtonAriaId}
                    items={items}
                    renderItem={this.renderItem}
                    emptyItem={emptyItem}
                    onChange={this.handleItemsListChange}
                />
            </div>
        );
    }
}

export default injectIntl(DoubleTextList);
