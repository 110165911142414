import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { stringNullOrWhiteSpace } from '../../../utils/StringUtils';
import { deepClone } from '../../mps/MpsUtils';

export class SubmissionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                titleError: false,
                descriptionError: false
            }
        };
    }

    validateDraftDetails(newSubmissionDetails) {
        const newErrors = deepClone(this.state.errors);
        newErrors.titleError = stringNullOrWhiteSpace(newSubmissionDetails.title);
        newErrors.descriptionError = stringNullOrWhiteSpace(newSubmissionDetails.description);

        this.setState({ errors: newErrors });
    }

    handleUpdate = (parameterName) => (evt) => {
        const submissionDetails = deepClone(this.props.submissionDetails);
        submissionDetails[parameterName] = evt.target.value;

        this.validateDraftDetails(submissionDetails);
        this.props.onSubmissionDetailsUpdate(submissionDetails);
    };

    hasError() {
        return !!this.state.errors.titleError || !!this.state.errors.descriptionError;
    }

    getAttentionNeededAlert() {
        if (!this.props.submissionDetails.needsAttention) {
            return '';
        }

        return (
            <Alert variant='primary'>
                <Alert.Heading>
                    <FontAwesomeIcon icon={faExclamationTriangle} className='icon-warning' style={{ marginRight: '12px' }} />
                    {this.props.intl.formatMessage({ id: 'action-needed' })}
                </Alert.Heading>
                <p>{this.props.submissionDetails.needsAttentionMessage}</p>
            </Alert>
        );
    }

    getSubmissionDetails() {
        return (
            <div>
                {this.getAttentionNeededAlert()}
                <Form.Group>
                    <Form.Label htmlFor='submission-details-title'>
                        {this.props.intl.formatMessage({ id: 'submission-details-title' })}
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            id='submission-details-title'
                            type='text'
                            value={this.props.submissionDetails.title}
                            onChange={this.handleUpdate('title')}
                            disabled={!this.props.submissionDetails.canEdit}
                            isInvalid={this.state.errors.titleError}
                        />
                        <Form.Control.Feedback type='invalid' aria-live='polite'>
                            {this.props.intl.formatMessage({ id: 'error-title-empty' })}
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'type' })}</Form.Label>
                    <p>{this.props.intl.formatMessage({ id: this.props.submissionDetails.type.toLowerCase() })}</p>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'modified' })}</Form.Label>
                    <p>{new Date(this.props.submissionDetails.modifiedDate).toLocaleString()}</p>
                    <Form.Label htmlFor='submission-details-description'>{this.props.intl.formatMessage({ id: 'description' })}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            id='submission-details-description'
                            type='text'
                            value={this.props.submissionDetails.description}
                            onChange={this.handleUpdate('description')}
                            disabled={!this.props.submissionDetails.canEdit}
                            isInvalid={this.state.errors.descriptionError}
                        />
                        <Form.Control.Feedback type='invalid' aria-live='polite'>
                            {this.props.intl.formatMessage({ id: 'error-description-empty' })}
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Label htmlFor='submission-details-background'>{this.props.intl.formatMessage({ id: 'background' })}</Form.Label>
                    <Form.Control
                        id='submission-details-background'
                        type='text'
                        value={this.props.submissionDetails.background}
                        onChange={this.handleUpdate('background')}
                        disabled={!this.props.submissionDetails.canEdit}
                    />
                </Form.Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button disabled={!this.props.submissionDetails.isEdited || this.hasError()} onClick={this.props.saveSubmissionDetails}>
                        {this.props.intl.formatMessage({ id: 'save-changes' })}
                    </Button>
                </div>
            </div>
        );
    }

    getNoSubmissionDetails() {
        return <p>{this.props.intl.formatMessage({ id: 'no-submission-selected' })}</p>;
    }

    render() {
        let itemToShow = null;
        if (!!this.props.submissionDetails) {
            itemToShow = this.getSubmissionDetails();
        } else {
            itemToShow = this.getNoSubmissionDetails();
        }

        return <div>{itemToShow}</div>;
    }
}

export default injectIntl(SubmissionDetails);
