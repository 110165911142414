// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './TargetCondition.css';
import { TextInput } from '../../input/TextInput';
import { RangeInput } from '../../input/RangeInput';
import { injectIntl } from 'react-intl';

export class TargetCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: ''
        };
    }

    handleOnChange = (newValue) => {
        let targetConditionValue = null;
        if (this.props.value == null) {
            targetConditionValue = {
                name: this.props.schema.Name,
                value: ''
            };
        } else {
            targetConditionValue = { ...this.props.value };
        }

        if (newValue === '') {
            newValue = null;
        }
        targetConditionValue.value = newValue;
        this.props.onChange({ target: { value: targetConditionValue } });
    };

    getInputUi(inputType) {
        let value = null;
        if (this.props.value != null) {
            value = this.props.value.value;
        }

        return (
            <TextInput
                inputType={inputType}
                value={value}
                displayName={this.props.schema.Name}
                description={this.props.schema.Description}
                handleChange={this.handleOnChange}
            />
        );
    }

    isNumber(str) {
        return /^\d+$/.test(str);
    }

    validateRange(first, second) {
        if ((first == null || first === '') && (second == null || second === '')) {
            this.setState({ errors: '' });
            return;
        }

        if (first !== '' && !this.isNumber(first)) {
            this.setState({ errors: this.props.intl.formatMessage({ id: 'first-value-must-be-number' }) });
            return;
        }

        if (second !== '' && !this.isNumber(second)) {
            this.setState({ errors: this.props.intl.formatMessage({ id: 'second-value-must-be-number' }) });
            return;
        }

        if ((first == null || first === '') && !(second == null || second === '')) {
            this.setState({
                errors: this.props.intl.formatMessage({ id: 'first-value-needed' })
            });
            return;
        }
        if ((second == null || second === '') && !(first == null || first === '')) {
            this.setState({
                errors: this.props.intl.formatMessage({ id: 'second-value-needed' })
            });
            return;
        }

        if (parseInt(first) > parseInt(second)) {
            this.setState({
                errors: this.props.intl.formatMessage({ id: 'first-less-than-second' })
            });
            return;
        }

        this.setState({ errors: '' });
    }

    handleChangeRange = (pair) => {
        let targetConditionValue = null;
        if (this.props.value == null) {
            targetConditionValue = {
                name: this.props.schema.Name,
                value: 'range:,'
            };
        } else {
            targetConditionValue = { ...this.props.value };
        }

        let first = pair.first;
        let second = pair.second;

        targetConditionValue.value = 'range:' + first + ',' + second;
        if (targetConditionValue.value === 'range:,') {
            targetConditionValue.value = null;
        }
        this.validateRange(first, second);

        this.props.onChange({ target: { value: targetConditionValue } });
    };

    getRangeIntUi() {
        let first = '';
        let second = '';

        if (this.props.value != null) {
            const split = this.props.value.value.substring('range:'.length).split(',');
            first = split[0] ?? '';
            second = split[1] ?? '';
        }

        const firstAriaLabel = this.props.schema.Name + ' ' + this.props.intl.formatMessage({ id: 'first-value' });
        const secondAriaLabel = this.props.schema.Name + ' ' + this.props.intl.formatMessage({ id: 'second-value' });

        return (
            <div>
                <RangeInput
                    displayName={this.props.schema.Name}
                    description={this.props.schema.Description}
                    first={first}
                    second={second}
                    handleOnChange={this.handleChangeRange}
                    errors={this.state.errors}
                    firstAriaLabel={firstAriaLabel}
                    secondAriaLabel={secondAriaLabel}
                />
            </div>
        );
    }

    getUiBasedOnType() {
        if (this.props.schema.Type === 0) {
            return this.getInputUi('number');
        } else if (this.props.schema.Type === 2) {
            return this.getRangeIntUi();
        } else {
            return this.getInputUi('text');
        }
    }

    render() {
        return (
            <div className='target-condition'>
                <Form.Group>{this.getUiBasedOnType()}</Form.Group>
            </div>
        );
    }
}

export default injectIntl(TargetCondition);
