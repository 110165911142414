// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, ButtonGroup, Button } from 'react-bootstrap';
import TooltipLabel from './TooltipLabel';
import { generateGuid, deepClone } from '../../mps/MpsUtils';
import './MultiSelect.css';

export class MultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            error: ''
        };
    }

    handleInputChange = (e) => {
        this.setState({
            value: e.target.value,
            error: ''
        });
    };

    handleInputKeyPress = (e) => {
        if (!this.props.onChange) {
            return;
        }

        if (e.key === 'Enter') {
            let validValue = e.target.value;
            if (!!this.props.restricted) {
                validValue = this.getValidValue(e.target.value);

                if (!validValue) {
                    let errorMsg = this.props.intl.formatMessage({ id: 'error-option-invalid' });
                    this.setState({ error: errorMsg });
                    return;
                }
            }

            if (!!this.props.unique && this.props.items?.includes(validValue)) {
                let errorMsg = this.props.intl.formatMessage({ id: 'error-item-exists' });
                this.setState({ error: errorMsg });
                return;
            }

            let newItems = deepClone(this.props.items) ?? [];
            if (Array.isArray(newItems)) {
                newItems.unshift(validValue);
            }

            this.props.onChange(newItems);
            this.setState({ value: '' });
        }
    };

    getValidValue = (value) => {
        if (!!this.props.options) {
            for (let opt of this.props.options) {
                if (opt.toUpperCase() === value.toUpperCase()) {
                    return opt;
                }
            }
        }

        return null;
    };

    removeItem = (index) => {
        let newItems = deepClone(this.props.items);
        newItems.splice(index, 1);

        this.props.onChange(newItems);
    };

    renderItem = (text, index) => {
        return (
            <ButtonGroup key={'multiSelectTag' + index} className='multi-select-tag'>
                <Button variant='light' size='sm' className='multi-select-tag-body' disabled>
                    {text}
                </Button>
                <Button
                    variant='secondary'
                    size='sm'
                    onClick={() => this.removeItem(index)}
                    aria-label={this.props.intl.formatMessage({ id: 'delete-item-button-aria-label' }, { text: text })}
                >
                    X
                </Button>
            </ButtonGroup>
        );
    };

    render() {
        let { controlId, label, labelId, tooltip, tooltipId, items, options } = this.props;

        let dataListId = !!controlId && controlId.length > 0 ? controlId + 'DataList' : 'dataList' + generateGuid();
        let showEmptyDataList = !this.state.value || !!this.state.error;

        return (
            <div>
                <Form.Group controlId={controlId}>
                    <TooltipLabel label={label} labelId={labelId} tooltip={tooltip} tooltipId={tooltipId} />
                    <Form.Control
                        type='text'
                        value={this.state.value}
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleInputKeyPress}
                        isInvalid={!!this.state.error}
                        list={showEmptyDataList ? 'emptyDataListId' : dataListId}
                        placeholder={this.props.intl.formatMessage({ id: 'multiselect-input-placeholder' })}
                        autoComplete='off'
                        maxLength='255'
                    />
                    <Form.Control.Feedback type='invalid' aria-live='polite'>
                        {this.state.error}
                    </Form.Control.Feedback>
                    <datalist id={dataListId}>
                        {options?.map((opt) => (
                            <option key={opt} value={opt}></option>
                        ))}
                    </datalist>
                    <datalist id='emptyDataList' />
                </Form.Group>
                <Form.Group>{items?.map((item, index) => this.renderItem(item, index))}</Form.Group>
            </div>
        );
    }
}

export default injectIntl(MultiSelect);
