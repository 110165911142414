// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import LabeledInput from '../form/LabeledInput';
import ExpandableList from './ExpandableList';
import { deepClone, generateGuid } from '../../mps/MpsUtils';

export default class SingleTextList extends Component {
    handleItemChange = (newItem, index) => {
        if (!!this.props.onChange) {
            let newItems = deepClone(this.props.items);
            newItems[index] = newItem;

            this.props.onChange(newItems);
        }
    };

    handleItemsChange = (newItems) => {
        if (!!this.props.onChange) {
            this.props.onChange(newItems);
        }
    };

    renderItem = (item, index, containerRef) => {
        let { controlId } = this.props;
        if (!controlId) {
            controlId = generateGuid();
        }

        return (
            <LabeledInput
                type='text'
                controlId={`${controlId}-Input{index}`}
                labelId={this.props.itemLabelId}
                value={item}
                onChange={(newItem) => this.handleItemChange(newItem, index)}
                numeric={!!this.props.numeric ? 1 : 0}
                inputRef={containerRef}
            />
        );
    };

    render() {
        let { label, labelId, tooltip, tooltipId, items, deleteButtonAriaId } = this.props;

        return (
            <div>
                <ExpandableList
                    label={label}
                    labelId={labelId}
                    tooltip={tooltip}
                    tooltipId={tooltipId}
                    items={items}
                    deleteButtonAriaId={deleteButtonAriaId}
                    renderItem={this.renderItem}
                    emptyItem={''}
                    onChange={this.handleItemsChange}
                />
            </div>
        );
    }
}
