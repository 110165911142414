// Copyright (C) Microsoft Corporation. All rights reserved.
import React from 'react';
import Layout from './components/Layout';
import PortalAuth from './components/PortalAuth';

export default () => (
    <Layout>
        <PortalAuth />
    </Layout>
);
