// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToolTip from 'react-tooltip';
import './DescriptionToolTip.css';

export default class DescriptionToolTip extends Component {
    render() {
        let iconSize = !!this.props.iconSize ? this.props.iconSize : 'md';

        let fullDescription = this.props.description;
        if (!!this.props.descriptionPrefix) {
            fullDescription = this.props.descriptionPrefix + '. ' + fullDescription;
        }

        return (
            <div className={this.props.className}>
                <span
                    className='description-tooltip-container'
                    role='tooltip'
                    tabIndex='0'
                    aria-label={fullDescription}
                    data-for={this.props.tooltipId}
                    onFocus={() => {
                        ReactToolTip.show(this.mainRef);
                    }}
                    data-tip={this.props.description}
                    data-multiline={true}
                    ref={(ref) => (this.mainRef = ref)}
                >
                    <FontAwesomeIcon icon='question-circle' size={iconSize} />
                    <ReactToolTip
                        className='description-tool-tip'
                        id={this.props.tooltipId}
                        globalEventOff='keydown'
                        effect='solid'
                        overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
                            // this logic is to make sure the tooltip stays within the window
                            const d = document.documentElement;
                            left = Math.min(d.clientWidth - node.clientWidth, left);
                            top = Math.min(d.clientHeight - node.clientHeight, top);
                            left = Math.max(0, left);
                            top = Math.max(0, top);
                            return { top, left };
                        }}
                    />
                </span>
            </div>
        );
    }
}
