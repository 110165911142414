// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
export class ModalContainer extends Component {
    getSubmitMessage() {
        const { intl } = this.props;

        if (this.props.submissionButtonText == null) {
            return intl.formatMessage({ id: 'submit' });
        }

        return this.props.submissionButtonText;
    }

    render() {
        const { intl } = this.props;
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title as='h4'>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                <Modal.Footer>
                    {this.props.handleSubmit && (
                        <Button disabled={!!this.props.submitButtonDisabled} variant='primary' onClick={this.props.handleSubmit}>
                            {this.getSubmitMessage()}
                        </Button>
                    )}
                    {this.props.handleClose && (
                        <Button variant='secondary' onClick={this.props.handleClose}>
                            {intl.formatMessage({ id: 'close' })}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(ModalContainer);
