// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ErrorBoundary from './common/ErrorBoundary';
import CosaContainer from './cosa/CosaContainer';
import MpsContainer from './mps/MpsContainer';
import ToastQueue from './controls/toast/ToastQueue';
import './PortalContainer.css';
import PortalLanding from './landing/PortalLanding';

export const LANDING_PAGE = 1;
export const COSA_PAGE = 2;
export const MPS_PAGE = 3;

export class PortalContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCosaLoading: false,
            cosaSchema: null,
            cosaProfiles: null,
            cosaPreviousCommits: null,
            assets: [],
            previousCommits: [],
            submissions: [],
            selectedDraft: null,
            selectedPage: LANDING_PAGE,

            mpsLoading: false,
            getMpsState: null,
            pushToast: null,

            somethingWentWrong: false
        };
    }

    componentWillMount() {}

    showErrorPage() {
        this.setState({ somethingWentWrong: true });
    }

    // TODO: We should put this in some kind of service so that we can access it throughout the application.
    pushSuccessToast(bodyId, bodyText) {
        if (!!this.state.pushToast) {
            this.state.pushToast({
                variant: 'success',
                titleId: 'toast-title-success',
                bodyId: bodyId,
                bodyText: bodyText
            });
        }
    }

    pushErrorToast(bodyId, bodyText) {
        if (!!this.state.pushToast) {
            this.state.pushToast({
                variant: 'danger',
                titleId: 'toast-title-danger',
                bodyId: bodyId,
                bodyText: bodyText
            });
        }
    }

    openCosaConfig = (selectedDraft) => {
        this.setState({ selectedDraft: selectedDraft, selectedPage: COSA_PAGE });
    };

    openMpsConfig = (selectedDraft) => {
        this.setState({ selectedDraft: selectedDraft, selectedPage: MPS_PAGE });
    };

    onBackButtonClicked = () => {
        this.setState({ selectedDraft: null, selectedPage: LANDING_PAGE });
    };

    getPageToRender() {
        const currentPage = this.state.selectedPage;
        return (
            <div>
                {currentPage === LANDING_PAGE && (
                    <PortalLanding
                        openCosaConfig={this.openCosaConfig}
                        openMpsConfig={this.openMpsConfig}
                        pushSuccessToast={(bodyId, bodyText) => this.pushSuccessToast(bodyId, bodyText)}
                        pushErrorToast={(bodyId, bodyText) => this.pushErrorToast(bodyId, bodyText)}
                    />
                )}
                {currentPage === COSA_PAGE && (
                    <CosaContainer
                        draft={this.state.selectedDraft}
                        pushSuccessToast={(bodyId, bodyText) => this.pushSuccessToast(bodyId, bodyText)}
                        pushToast={this.state.pushToast}
                        onBackButtonClicked={this.onBackButtonClicked}
                    />
                )}
                {currentPage === MPS_PAGE && (
                    <MpsContainer
                        draft={this.state.selectedDraft}
                        pushToast={this.state.pushToast}
                        onBackButtonClicked={this.onBackButtonClicked}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <main>
                <ErrorBoundary>
                    <ToastQueue
                        setPushToastHandler={(newHandler) => {
                            this.setState({ pushToast: newHandler });
                        }}
                    />
                    {this.getPageToRender()}
                </ErrorBoundary>
            </main>
        );
    }
}

export default injectIntl(PortalContainer);
