// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import SettingTreeName from '../settings/SettingTreeName';
import '../settings/SettingTreeItem.css';
import { injectIntl } from 'react-intl';

export class TargetTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
        this.childTreeStateRef = React.createRef();
    }

    handleTargetItemSelected = (targetState) => () => {
        this.props.targetItemSelected(targetState);
    };

    handleOnExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    handleOnTargetSelected = () => {
        this.setState({ isExpanded: true });
    };

    handleOnAddTargetState = () => {
        this.setState({ isExpanded: true });
        this.props.onTargetItemAdded();
    };

    handleOnDeleteTargetState = (targetState) => () => {
        this.props.onTargetItemDeleted(targetState);
    };

    componentDidUpdate(prevProps) {
        const currentTargetStateLength = this.props.target.targetState.length;
        const prevTargetStateLength = prevProps.target.targetState.length;
        if (currentTargetStateLength > 0 && currentTargetStateLength !== prevTargetStateLength) {
            this.childTreeStateRef.current.focus();
        }
    }

    createChildTreeItem(targetState, index) {
        const isSelected = this.props.selectedItemPath === targetState.id;
        const containerRef = index === this.props.target.targetState.length - 1 ? this.childTreeStateRef : null;
        return (
            <SettingTreeName
                settingName={this.props.intl.formatMessage({ id: 'target' }) + (index + 1)}
                onItemSelected={this.handleTargetItemSelected(targetState)}
                isSelected={isSelected}
                canBeDeleted={true}
                onDeleteClicked={this.handleOnDeleteTargetState(targetState)}
                inputRef={containerRef}
            />
        );
    }

    render() {
        const treeNames = this.props.target.targetState.map((targetState, index) => this.createChildTreeItem(targetState, index));
        return (
            <div>
                <SettingTreeName
                    settingName={this.props.intl.formatMessage({ id: 'targets' })}
                    isTreeItem={true}
                    isExpanded={this.state.isExpanded}
                    onItemSelected={this.handleOnTargetSelected}
                    onExpand={this.handleOnExpand}
                    canAddSetting={true}
                    onAddClicked={this.handleOnAddTargetState}
                    addChildAriaLabel={this.props.intl.formatMessage({ id: 'target' })}
                />
                <div className='setting-item-children'>{this.state.isExpanded && treeNames}</div>
            </div>
        );
    }
}

export default injectIntl(TargetTree);
