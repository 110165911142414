import { Component } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { stringNullOrWhiteSpace } from '../../../utils/StringUtils';
import ModalContainer from '../../ModalContainer';
import { deepClone } from '../../mps/MpsUtils';

export class SubmitDraftModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                descriptionError: false
            }
        };
    }

    handleDraftDetailsChange = (propName) => (evt) => {
        const newDraftDetails = deepClone(this.props.draftDetails);
        newDraftDetails[propName] = evt.target.value;
        this.props.onDraftDetailsUpdated(newDraftDetails);
    };

    validateDraftDetails(newDraftDetails) {
        const newErrors = deepClone(this.state.errors);
        newErrors.descriptionError = stringNullOrWhiteSpace(newDraftDetails.description);

        this.setState({ errors: newErrors });
    }

    render() {
        const draftTitle = !!this.props.draftDetails ? this.props.draftDetails.title : '';
        return (
            <ModalContainer
                title={this.props.intl.formatMessage({ id: 'submit-draft-title' }, { draftTitle: draftTitle })}
                show={this.props.show}
                handleClose={this.props.handleClose}
                handleSubmit={this.props.handleSubmit}
            >
                <Form.Group>
                    <p>{this.props.intl.formatMessage({ id: 'submit-draft-fill-out-description' })}</p>
                    <Form.Label htmlFor='submission-modal-description'>
                        {this.props.intl.formatMessage({ id: 'submit-draft-description-label' })}
                    </Form.Label>
                    <Form.Text>{this.props.intl.formatMessage({ id: 'draft-description-info' })}</Form.Text>
                    <InputGroup>
                        <Form.Control
                            id='submission-modal-description'
                            type='text'
                            value={this.props.draftDetails?.description}
                            onChange={this.handleDraftDetailsChange('description')}
                        />
                        <Form.Control.Feedback type='invalid' aria-live='polite'>
                            {this.props.intl.formatMessage({ id: 'error-description-empty' })}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor='submission-modal-background'>
                        {this.props.intl.formatMessage({ id: 'submit-draft-background-label' })}
                    </Form.Label>
                    <Form.Text>{this.props.intl.formatMessage({ id: 'draft-background-info' })}</Form.Text>
                    <Form.Control
                        id='submission-modal-background'
                        type='text'
                        value={this.props.draftDetails?.background}
                        onChange={this.handleDraftDetailsChange('background')}
                    />
                </Form.Group>
            </ModalContainer>
        );
    }
}

export default injectIntl(SubmitDraftModal);
