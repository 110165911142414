// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TooltipLabel from '../form/TooltipLabel';
import './CollapsableContainer.css';

export class CollapsableContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    toggleVisibility = () => {
        this.setState({ visible: !this.state.visible });
    };

    getLabel = () => {
        let labelText = '';
        let suffixText = '';

        if (!!this.props.label) {
            labelText = this.props.label;
        } else if (!!this.props.labelId) {
            labelText = this.props.intl.formatMessage({ id: this.props.labelId });
        }

        if (!!this.props.labelSuffix) {
            suffixText = this.props.labelSuffix;
        } else if (!!this.props.labelSuffixId) {
            suffixText = this.props.intl.formatMessage({ id: this.props.labelSuffixId });
        }

        let fullText = labelText + suffixText;
        return fullText;
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.toggleVisibility();
        }
    };

    render() {
        let { label, labelId, labelSuffix, labelSuffixId, tooltip, tooltipId, controlId } = this.props;
        let faIcon = this.state.visible ? faChevronDown : faChevronRight;
        let childrenClassName = this.state.visible ? '' : 'd-none';

        return (
            <div>
                <Form.Group className='collapsable-container-header mb-0'>
                    <Row>
                        <Col
                            md='auto'
                            className='expandable pr-0'
                            onClick={this.toggleVisibility}
                            tabIndex={0}
                            role='button'
                            aria-label={this.getLabel()}
                            onClick={this.toggleVisibility}
                            aria-expanded={this.state.visible}
                        >
                            <FontAwesomeIcon
                                className='collapsable-container-chevron d-inline-block align-middle ml-2 mr-2'
                                icon={faIcon}
                                size='sm'
                            />
                        </Col>
                        <Col
                            className='expandable pl-0'
                            onKeyPress={this.onKeyPress}
                            onClick={this.toggleVisibility}
                            ref={this.props.inputRef}
                        >
                            <TooltipLabel
                                labelClassName='collapsable-container-label'
                                rootClassName='d-inline-block'
                                label={label}
                                labelId={labelId}
                                labelSuffix={labelSuffix}
                                labelSuffixId={labelSuffixId}
                                tooltip={tooltip}
                                tooltipId={tooltipId}
                            />
                        </Col>
                        {this.props.controls?.map((ctrl, index) => (
                            <Col md='auto' key={controlId + 'CollapsableContainerControl' + index}>
                                {ctrl}
                            </Col>
                        ))}
                    </Row>
                </Form.Group>
                <div className={childrenClassName}>
                    <hr />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default injectIntl(CollapsableContainer);
