// Copyright (C) Microsoft Corporation. All rights reserved.
import * as Msal from '@azure/msal-browser';

const FORGOT_PASSWORD_ERROR = 'AADB2C90118';

/**
 * The "test" scope is just for the prototype. When we start updating the scope for the actual
 * application, we should update accordingly
 */
const appConfig = {
    b2cScopes: ['https://mobileoperatorconfigportal.onmicrosoft.com/api/api.read'],
    webApi: 'https://mobileoperatorconfigportal.onmicrosoft.com/api'
};

const hostUrl = window.location.protocol + '//' + window.location.host;

/**
 * We should probably move this so that it isn't accessible but we should
 * only have one instance of the UserAgentApplication. Otherwise, caching issues
 * might occur
 */
const myMSALObj = new Msal.PublicClientApplication({
    auth: {
        clientId: '19bc3863-a297-4239-9960-a673fa919316', // Client Id for the application
        authority: 'https://mobileoperatorconfigportal.b2clogin.com/mobileoperatorconfigportal.onmicrosoft.com/B2C_1A_signup_signin_mfa', //This is your tenant info
        knownAuthorities: ['mobileoperatorconfigportal.b2clogin.com'],
        redirectUri: hostUrl,
        postLogoutRedirectUri: hostUrl
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
});

let isAuthenticating = true;
const authenticatingCallbacks = [];

myMSALObj
    .handleRedirectPromise()
    .then((response) => {
        // The accounts object will not be ready until this promise is resolved.
        // So we wait until it is resolved and call any callbacks that might be waiting
        isAuthenticating = false;
        while (authenticatingCallbacks.length > 0) {
            const callback = authenticatingCallbacks.pop();
            callback();
        }
    })
    .catch((error) => {
        if (error.errorMessage.includes(FORGOT_PASSWORD_ERROR)) {
            forgotPasswordRedirect();
        } else {
            console.log(error);
        }
    });

const loginRequest = {
    scopes: appConfig.b2cScopes
};
const forgotPasswordRequest = {
    scopes: appConfig.b2cScopes,
    authority: 'https://mobileoperatorconfigportal.b2clogin.com/mobileoperatorconfigportal.onmicrosoft.com/B2C_1A_PASSWORDRESET'
};

/**
 * Below are a bunch of helper functions used for the prototype.
 */

export function isUserAuthenticating() {
    return isAuthenticating;
}

export function waitForAuthenticatingCallbacks(callback) {
    authenticatingCallbacks.push(callback);
}

export function loginPopup() {
    return myMSALObj.loginPopup(loginRequest);
}

export function loginRedirect() {
    myMSALObj.loginRedirect(loginRequest);
}

export function forgotPasswordRedirect() {
    myMSALObj.loginRedirect(forgotPasswordRequest);
}

export function logout() {
    myMSALObj.logout();
}

function aquireTokenSilent() {
    loginRequest.account = getAccount();
    return myMSALObj.acquireTokenSilent(loginRequest);
}

function aquireTokenPopup() {
    loginRequest.account = getAccount();
    return myMSALObj.acquireTokenPopup(loginRequest);
}

export function getAccountId() {
    const account = getAccount();
    if (account == null) {
        return null;
    }

    return account.localAccountId;
}

export function getAccount() {
    const accounts = myMSALObj.getAllAccounts();
    if (accounts === null || accounts.length == 0) {
        return null;
    }

    return myMSALObj.getAllAccounts()[0];
}

export function isUserSignedIn() {
    if (isAuthenticating) {
        return false;
    }

    const account = getAccount();
    return account != null;
}

const aquireTokenCallBacks = [];
let inPopup = false;

export function aquireTokenSilentOrPopup(callback) {
    aquireTokenSilent()
        .then((token) => {
            callback(token);
        })
        .catch((error) => {
            if (error.name === 'InteractionRequiredAuthError') {
                aquireTokenCallBacks.push(callback);
                if (!inPopup) {
                    inPopup = true;
                    aquireTokenPopup()
                        .then((token) => {
                            while (aquireTokenCallBacks.length > 0) {
                                let cb = aquireTokenCallBacks.shift();
                                cb(token);
                            }
                            inPopup = false;
                        })
                        .catch((error) => {
                            logout();
                        });
                }
            } else {
                // Failed to get the token, need to login interactively
                myMSALObj.loginRedirect(loginRequest);
            }
        });
}

export function createAuthorizationHeaders(token) {
    const headers = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    };

    return headers;
}
