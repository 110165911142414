// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import SettingTreeName from './SettingTreeName';
import { getSettingValuesForPath, MAP, TREE } from '../settingtypes/SettingFactory';
import SettingTreeItem from './SettingTreeItem';
import './SettingGroupTreeItem.css';

export default class SettingGroupTreeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
    }

    mapSettingsToValues(setting) {
        const settingValue = getSettingValuesForPath(
            setting.Path,
            this.props.settingValue == null ? null : this.props.settingValue.childSettings,
            true
        );
        return (
            <div>
                <SettingTreeItem
                    setting={setting}
                    settingValue={settingValue}
                    onChildItemSelected={this.props.onChildItemSelected}
                    itemPath={setting.Path}
                    onNavigationItemAdded={this.props.onNavigationItemAdded}
                    onNavigationItemDeleted={this.props.onNavigationItemDeleted}
                    selectedItemPath={this.props.selectedItemPath}
                />
            </div>
        );
    }

    getSettingsForDisplay() {
        let childrenSettings = [];
        for (let i = 0; i < this.props.settingGroup.Settings.length; i++) {
            const childSetting = this.props.settingGroup.Settings[i];
            if (childSetting.Type != MAP && childSetting.Type != TREE) {
                continue;
            }

            childrenSettings.push(this.mapSettingsToValues(childSetting));
        }

        return childrenSettings;
    }

    onTreeNameClicked = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    isTreeItem() {
        for (let i = 0; i < this.props.settingGroup.Settings.length; i++) {
            const childSetting = this.props.settingGroup.Settings[i];
            if (childSetting.Type == MAP || childSetting.Type == TREE) {
                return true;
            }
        }

        return false;
    }

    hasChangeableSettings() {
        for (let i = 0; i < this.props.settingGroup.Settings.length; i++) {
            const childSetting = this.props.settingGroup.Settings[i];
            if (childSetting.Type != TREE && childSetting.Type != MAP) {
                return true;
            }
        }

        return false;
    }

    onGroupItemClicked = () => {
        this.setState({ isExpanded: true });
        if (this.hasChangeableSettings()) {
            this.props.onGroupItemSelected(this.props.settingGroup, this.props.settingValue, this.props.settingGroup.Path);
        }
    };

    render() {
        const childrenSettings = this.getSettingsForDisplay();
        const isSelected = this.props.settingGroup.Path === this.props.selectedItemPath;
        return (
            <div>
                <SettingTreeName
                    settingName={this.props.settingGroup.Name}
                    isTreeItem={this.isTreeItem()}
                    isExpanded={this.state.isExpanded}
                    onExpand={this.onTreeNameClicked}
                    onItemSelected={this.onGroupItemClicked}
                    isSelected={isSelected}
                />
                <div className='setting-group-item-children'>{this.state.isExpanded && childrenSettings}</div>
            </div>
        );
    }
}
