// Copyright (C) Microsoft Corporation. All rights reserved.
import React from 'react';
import TextSetting from './TextSetting';
import IntSetting from './IntSetting';
import BooleanSetting from './BooleanSetting';
import OrderedListSetting from './OrderedListSetting';
import AssetSetting from './AssetSetting';
import { CreateNewGuid } from '../../../Utils';

const TEXT = 0;
const INT = 1;
const BOOL = 2;
export const MAP = 3;
const ASSET = 4;
const ORDERED_LIST = 5;
export const TREE = 6;

export function buildSetting(setting, settingValue, onChange, onAssetsChange, displayNameOverride = null) {
    let displayName = displayNameOverride;
    if (displayName == null) {
        displayName = setting.DisplayName;
    }

    if (setting.Type === TEXT) {
        return <TextSetting setting={setting} settingValue={settingValue} onChange={onChange} displayName={displayName} />;
    } else if (setting.Type === INT) {
        return <IntSetting setting={setting} settingValue={settingValue} onChange={onChange} displayName={displayName} />;
    } else if (setting.Type === BOOL) {
        return <BooleanSetting setting={setting} settingValue={settingValue} onChange={onChange} displayName={displayName} />;
    } else if (setting.Type === ASSET) {
        return (
            <AssetSetting
                setting={setting}
                settingValue={settingValue}
                onChange={onChange}
                onAssetsChange={onAssetsChange}
                displayName={displayName}
            />
        );
    } else if (setting.Type === ORDERED_LIST) {
        return <OrderedListSetting setting={setting} settingValue={settingValue} onChange={onChange} displayName={displayName} />;
    } else {
        console.log('Could not build type ' + setting.type);
    }
}

function recurseSettingValueGroup(path, setting) {
    if (setting.path === path) {
        return setting;
    }

    if (setting.childSettings == null) {
        return null;
    }

    for (let i = 0; i < setting.childSettings.length; i++) {
        let result = recurseSettingValueGroup(path, setting.childSettings[i]);
        if (result != null) {
            return result;
        }
    }

    return null;
}

export function getSettingValuesForPath(path, settingValues, shouldRecurse = false) {
    if (settingValues == null) {
        return null;
    }

    for (let i = 0; i < settingValues.length; i++) {
        let setting = settingValues[i];
        if (setting.path === path) {
            return setting;
        }
    }

    if (shouldRecurse) {
        // recurse settings
        for (let i = 0; i < settingValues.length; i++) {
            let setting = settingValues[i];
            let result = recurseSettingValueGroup(path, setting);
            if (result != null) {
                return result;
            }
        }
    }

    return null;
}

function recurseSetting(path, setting) {
    if (setting.Path === path) {
        return setting;
    }

    if (setting.Type == MAP) {
        for (let i = 0; i < setting.Validation.MapSettingValues.length; i++) {
            const childSetting = recurseSetting(path, setting.Validation.MapSettingValues[i]);
            if (childSetting != null) {
                return childSetting;
            }
        }
    }
    if (setting.Type == TREE) {
        for (let i = 0; i < setting.ChildSettings.length; i++) {
            const childSetting = recurseSetting(path, setting.ChildSettings[i]);
            if (childSetting != null) {
                return childSetting;
            }
        }
    }

    return null;
}

function recurseSettingGroup(path, settingGroup) {
    for (let i = 0; i < settingGroup.Settings.length; i++) {
        const setting = recurseSetting(path, settingGroup.Settings[i]);
        if (setting != null) {
            return setting;
        }
    }

    return null;
}

export function getSettingWithPath(path, settingGroups) {
    for (let i = 0; i < settingGroups.length; i++) {
        const setting = recurseSettingGroup(path, settingGroups[i]);
        if (setting != null) {
            return setting;
        }
    }

    return null;
}

export function createNewSettingValue(name, path, childSettings, attributes, value, guid) {
    return {
        name: name,
        path: path,
        childSettings: childSettings,
        attributes: attributes,
        value: value,
        guid: guid
    };
}

export function generateNewSettingValue(setting, initialSettingValue) {
    let settingValue = null;
    if (initialSettingValue == null) {
        settingValue = {
            name: setting.Path.split('/').pop(),
            path: setting.Path,
            childSettings: null,
            attributes: null,
            value: null,
            guid: CreateNewGuid()
        };
    } else {
        settingValue = { ...initialSettingValue };
    }

    return settingValue;
}
