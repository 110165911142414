// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import LabeledInput from '../../controls/form/LabeledInput';
import CollapsableContainer from '../../controls/container/CollapsableContainer';
import * as MpsConstants from '../MpsConstants';
import { deepClone } from '../MpsUtils';

const NEW_LINE = '\n';
const CSV_SEP = ',';

export class MpsDataFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contents: this.getFlattenedValue(this.props.value?.contents)
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
            this.setState({
                contents: this.getFlattenedValue(this.props.value?.contents)
            });
        }
    }

    getEmptyDataFile = () => {
        let emptyDataFile = {
            contents: [],
            fileName: this.props.fileName,
            fullPath: this.props.dataPathPrefix + this.props.fileName
        };

        return emptyDataFile;
    };

    getType = (fileName) => {
        switch (fileName) {
            case MpsConstants.CHANNEL_IMEIS_FILE_NAME:
                return 'Channel';
            case MpsConstants.SPONSOR_IMEIS_FILE_NAME:
                return 'Sponsor';
            case MpsConstants.INSTALLEDPROFILE_IMEIS_FILE_NAME:
                return 'InstalledProfile';
            default:
                return null;
        }
    };

    getFlattenedValue = (nestedImeis) => {
        if (Array.isArray(nestedImeis)) {
            const sepWithSpace = CSV_SEP + ' ';

            let lines = nestedImeis.map((line) => line.join(sepWithSpace));
            let content = lines.join(NEW_LINE);

            return content;
        }

        return '';
    };

    handleIsEnabledChange = (isEnabled) => {
        if (!!this.props.onChange) {
            if (!!isEnabled) {
                let newValue = deepClone(this.props.value) || this.getEmptyDataFile();
                newValue.contents = newValue.previousContents || [];
                newValue.previousContents = null;

                this.props.onChange(newValue, this.props.fileName);
            } else {
                this.props.onChange(null, this.props.fileName);
            }
        }
    };

    handleContentsChange = (newContents) => {
        if (!!this.props.onChange && !!this.props.value) {
            let newDataFile = deepClone(this.props.value);

            let lines = newContents.split(NEW_LINE).filter((line) => !!line.trim());
            let newNestedContents = lines.map((line) =>
                line
                    .split(CSV_SEP)
                    .map((imei) => imei.trim())
                    .filter((imei) => !!imei)
            );

            newDataFile.contents = newNestedContents;
            this.setState({ contents: newContents });
            this.props.onChange(newDataFile, this.props.fileName);
        }
    };

    render() {
        let { value, controlId } = this.props;

        let isEnabled = value?.contents != null;
        let type = this.getType(this.props.fileName);
        let lcType = type?.toLowerCase();

        return (
            <CollapsableContainer
                labelId={`mps-${lcType}-imeis-label`}
                tooltipId={`mps-${lcType}-imeis-tooltip`}
                controlId={controlId}
                labelSuffixId={isEnabled ? 'mps-data-imeis-enabled-suffix' : 'mps-data-imeis-disabled-suffix'}
            >
                <Row>
                    <Col>
                        <LabeledInput
                            type='checkbox'
                            controlId={controlId + 'IsEnabled'}
                            labelId={`mps-${lcType}-imeis-isenabled-label`}
                            tooltipId={`mps-${lcType}-imeis-isenabled-tooltip`}
                            value={isEnabled}
                            onChange={this.handleIsEnabledChange}
                        />
                    </Col>
                    <Col>{/* BLANK */}</Col>
                </Row>
                {isEnabled ? (
                    <Row>
                        <Col>
                            <LabeledInput
                                type='textarea'
                                controlId={controlId + 'List'}
                                labelId={`mps-${lcType}-imeis-list-label`}
                                tooltipId={`mps-${lcType}-imeis-list-tooltip`}
                                value={this.state.contents}
                                onChange={this.handleContentsChange}
                                rows={16}
                            />
                        </Col>
                    </Row>
                ) : null}
            </CollapsableContainer>
        );
    }
}

export default injectIntl(MpsDataFile);
