// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

export class NoProfileSelectedContainer extends Component {
    render() {
        return <p>{this.props.intl.formatMessage({ id: 'no-profile-selected' })}</p>;
    }
}

export default injectIntl(NoProfileSelectedContainer);
