// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import ModalContainer from '../ModalContainer';
import { Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { aquireTokenSilentOrPopup } from '../../auth/MsalUtils';
import API from '../../API';

export class CreatePpkgModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null,
            options: []
        };
    }

    handleChange = (evt) => {
        this.setState({ selectedOption: evt.target.value });
    };

    handleSubmit = () => {
        this.props.handleSubmit(this.state.selectedOption);
    };

    componentWillMount() {
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            API.get('/cosa/releases', headers)
                .then((response) => {
                    let selectedOption = null;
                    if (response.data.releases.length > 0) {
                        selectedOption = response.data.releases[0].value;
                    }
                    this.setState({ selectedOption: selectedOption, options: response.data.releases });
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    render() {
        const { intl } = this.props;

        return (
            <ModalContainer
                title={intl.formatMessage({ id: 'get-test-package' })}
                show={this.props.show}
                handleClose={this.props.handleClose}
                handleSubmit={this.handleSubmit}
            >
                <p>{intl.formatMessage({ id: 'save-changes-for-ppkg' })}</p>
                <p>{intl.formatMessage({ id: 'test-package-note' })}</p>
                <Form.Group>
                    <Form.Label>{intl.formatMessage({ id: 'os-version' })}</Form.Label>
                    <Form.Control
                        as='select'
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                        aria-label={intl.formatMessage({ id: 'os-version' })}
                    >
                        {this.state.options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </ModalContainer>
        );
    }
}

export default injectIntl(CreatePpkgModal);
