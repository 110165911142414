import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { Table } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './SortableTable.css';

export class SortableTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedField: null,
            config: {
                ascending: false
            }
        };
    }

    sortTable(columnName) {
        let config = this.state.config;
        if (columnName === this.state.sortedField) {
            config.ascending = !config.ascending;
        } else {
            config.ascending = false;
        }

        this.setState({ sortedField: columnName, config: config });
    }

    onColumnHeaderClicked = (columnName) => () => {
        this.sortTable(columnName);
    };

    onColumnHeaderKeyPress = (columnName) => (e) => {
        if (e.key === 'Enter') {
            this.sortTable(columnName);
        }
    };

    onRowSelected(rowItem) {
        if (!!this.props.onRowClicked) {
            this.props.onRowClicked(rowItem);
        }
    }

    onRowClicked = (rowItem) => () => {
        this.onRowSelected(rowItem);
    };

    getRowClass(item) {
        let className = 'pointer-selectable';
        if (!!item.isSelected) {
            className += ' selected-row';
        }

        return className;
    }

    getCaretIcon(headerItem) {
        if (this.state.sortedField === headerItem.fieldName && this.state.config.ascending) {
            return faCaretUp;
        }

        return faCaretDown;
    }

    createHeaderText(headerItem) {
        let headerName = '';
        if (!!headerItem.columnName) {
            headerName = headerItem.columnName;
        }

        return <th key={headerItem.fieldName}>{headerName}</th>;
    }

    createHeaderButton(headerItem) {
        let sortingIcon = '';
        if (!!headerItem.sortable) {
            sortingIcon = <FontAwesomeIcon className='caret-icon' icon={this.getCaretIcon(headerItem)} />;
        }

        let headerName = '';
        if (!!headerItem.columnName) {
            headerName = headerItem.columnName;
        }

        let ariaSort = null;
        if (!!headerItem.sortable && headerItem.fieldName == this.state.sortedField) {
            ariaSort = this.state.config.ascending ? 'ascending' : 'descending';
        }

        const headerButtonDiv = (
            <div
                role='button'
                onClick={this.onColumnHeaderClicked(headerItem.fieldName)}
                onKeyPress={this.onColumnHeaderKeyPress(headerItem.fieldName)}
                tabIndex={0}
                aria-describedby='SortInstructions'
            >
                {headerName}
                {sortingIcon}
            </div>
        );

        // According to the aria-sort documentation, we should only have one aria-sort attribute for a table. This means that we need to move the aria-sort to the currently sorted column
        // However, there seems to be a bug with narrator where it tends to announce the last sorted column when selected a new sort column.
        // Document stating that the aria-sort must be moved: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-sort
        if (!!ariaSort) {
            return (
                <th key={headerItem.fieldName} aria-sort={ariaSort}>
                    {headerButtonDiv}
                </th>
            );
        } else {
            return <th key={headerItem.fieldName}>{headerButtonDiv}</th>;
        }
    }

    createHeader(headerItem) {
        if (!!headerItem.sortable) {
            return this.createHeaderButton(headerItem);
        }

        return this.createHeaderText(headerItem);
    }

    getItemContent(item, fieldName) {
        if (!!item[fieldName]) {
            return item[fieldName];
        }

        return '';
    }

    handleRowKeyPress = (item) => (e) => {
        if (e.key === 'Enter') {
            this.onRowSelected(item);
        }
    };

    render() {
        const { headerItems, bodyItems } = this.props;

        const sortedField = this.state.sortedField;
        let sortedItems = [...bodyItems];

        if (sortedField !== null) {
            sortedItems.sort((a, b) => {
                const config = this.state.config;
                const l = `${a[sortedField]}`.toLowerCase();
                const r = `${b[sortedField]}`.toLowerCase();
                if (l < r) {
                    return config.ascending ? -1 : 1;
                } else if (l > r) {
                    return config.ascending ? 1 : -1;
                } else {
                    return 0;
                }
            });
        }

        return (
            <div>
                <span id='SortInstructions' className='visually-hidden'>
                    {this.props.intl.formatMessage({ id: 'sortable-table-sort-instructions' })}
                </span>
                <Table bordered responsive hover tabindex={0}>
                    <thead>
                        <tr>{headerItems.map((headerItem) => this.createHeader(headerItem))}</tr>
                    </thead>
                    <tbody>
                        {sortedItems.map((item) => (
                            <tr
                                key={item.key}
                                onClick={this.onRowClicked(item)}
                                onKeyPress={this.handleRowKeyPress(item)}
                                className={this.getRowClass(item)}
                                tabIndex={0}
                                aria-label={this.props.intl.formatMessage(
                                    { id: 'sortable-table-select-row-aria-label' },
                                    { itemName: item.name }
                                )}
                            >
                                {headerItems.map((headerItem) => (
                                    <td>{!!headerItem.fieldName && this.getItemContent(item, headerItem.fieldName)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default injectIntl(SortableTable);
