// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Button, Row, Col, ProgressBar, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt, faUpload, faCloud, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import MicrosoftSpinner from '../../controls/animation/MicrosoftSpinner';
import TooltipLabel from '../../controls/form/TooltipLabel';
import LabeledInput from '../../controls/form/LabeledInput';
import SimpleModal from '../../controls/modal/SimpleModal';
import { generateGuid } from '../MpsUtils';
import { aquireTokenSilentOrPopup, getAccountId } from '../../../auth/MsalUtils';
import API from '../../../API';

export class MpsResourcesPage extends Component {
    intl = this.props.intl;
    supportedTypes = ['image/png', 'image/jpeg'];
    maxFileSizeMB = 32;

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            fileInputResetKey: generateGuid(),
            modalContent: null
        };
    }

    getMimeType = (resFile) => {
        let ext = resFile?.fileName?.split('.').pop();

        switch (ext?.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return null;
        }
    };

    resetUploadControl = () => {
        this.setState({
            selectedFile: null,
            fileInputResetKey: generateGuid()
        });
    };

    isUploading = () => {
        let isUploading = this.props.resourceFiles?.some((file) => Number.isInteger(file.percentage));
        return isUploading;
    };

    getEmptyResFile = (fileName) => {
        let emptyResFile = {
            contents: '',
            fullPath: this.props.resourcePathPrefix + fileName,
            fileName: fileName
        };

        return emptyResFile;
    };

    resFileExists = (fileName) => {
        let fullPath = this.props.resourcePathPrefix + fileName;
        let fileExists = !!this.props.resourceFiles?.some((file) => file.fullPath === fullPath && file.contents !== null);

        return fileExists;
    };

    addOrUpdateResFile = (newFile, contents, percentage) => {
        if (!!this.props.onChange) {
            let fileName = newFile.name;
            let fullPath = this.props.resourcePathPrefix + newFile.name;
            let matchResFile = this.props.resourceFiles.find((file) => file.fullPath === fullPath);

            let newResFile = matchResFile ?? this.getEmptyResFile(fileName);
            newResFile.contents = contents;
            if (Number.isInteger(percentage)) {
                if (percentage > 0 && percentage < 100) {
                    newResFile.percentage = percentage;
                } else {
                    delete newResFile.percentage;
                }
            } else {
                let newFiles = [newResFile, ...this.props.resourceFiles.filter((file) => file.fullPath !== fullPath)];
                this.props.onChange(newFiles, 'resourceFiles');
            }
        }
    };

    removeResFile = (index) => {
        if (!!this.props.onChange && this.props.resourceFiles?.length > 0) {
            let newFiles = this.props.resourceFiles;
            if (this.props.allFiles.some((fullPath) => fullPath === newFiles[index].fullPath)) {
                newFiles[index].contents = null;
            } else {
                newFiles.splice(index, 1);
            }

            this.props.onChange(newFiles, 'resourceFiles');
        }
    };

    handleFileInputChange = (newFile) => {
        if (!this.supportedTypes.includes(newFile?.type)) {
            if (!!this.props.pushToast) {
                let body = this.props.intl.formatMessage({ id: 'error-file-format' }, { mimeTypes: this.supportedTypes.join(', ') });
                this.props.pushToast({
                    variant: 'danger',
                    body: body
                });
            }

            this.resetUploadControl();
            return;
        }

        if (newFile.size > this.maxFileSizeMB * 1024 * 1024) {
            if (!!this.props.pushToast) {
                let body = this.props.intl.formatMessage({ id: 'error-file-size' }, { fileSize: this.maxFileSizeMB });
                this.props.pushToast({
                    variant: 'danger',
                    body: body
                });
            }

            this.resetUploadControl();
            return;
        }

        this.setState({ selectedFile: newFile });
    };

    handleUploadClick = () => {
        let { selectedFile } = this.state;
        if (!selectedFile) {
            if (!!this.props.pushToast) {
                this.props.pushToast({
                    variant: 'danger',
                    bodyId: 'error-file-notselected'
                });
            }
            return;
        }

        if (this.resFileExists(selectedFile?.name)) {
            if (!!this.props.pushToast) {
                this.props.pushToast({
                    variant: 'danger',
                    bodyId: 'error-file-exists'
                });
            }
            return;
        }

        // Update state with an empty file first.
        this.addOrUpdateResFile(selectedFile, '');

        let reader = new FileReader();
        reader.onloadstart = (e) => {
            this.addOrUpdateResFile(selectedFile, '', 0);
        };
        reader.onprogress = (e) => {
            if (e.lengthComputable) {
                let percentage = parseInt((e.loaded / e.total) * 100, 10);
                this.addOrUpdateResFile(selectedFile, '', percentage);
            }
        };
        reader.onload = (e) => {
            let base64Content = e.target.result?.split(',')[1];
            this.addOrUpdateResFile(selectedFile, base64Content);
        };
        reader.onloadend = () => {
            this.resetUploadControl();
        };
        reader.onerror = (e) => {
            console.log(e);
            if (!!this.props.pushToast) {
                this.props.pushToast({
                    variant: 'danger',
                    bodyId: 'error-failed-upload'
                });
            }
            this.removeResFile(0);
        };
        reader.readAsDataURL(selectedFile);
    };

    renderUploadControl = () => {
        let isUploading = this.isUploading();
        let isUploadDisabled = isUploading || !this.state.selectedFile;

        return (
            <Row>
                <Col>
                    <LabeledInput
                        type='file'
                        accept='image/png, image/jpeg'
                        labelId='mps-resourcefiles-upload-button-label'
                        tooltipId='mps-resourcefiles-upload-button-tooltip'
                        onChange={this.handleFileInputChange}
                        disabled={isUploading}
                        key={this.state.fileInputResetKey}
                    />
                    <Button onClick={this.handleUploadClick} disabled={isUploadDisabled}>
                        <FontAwesomeIcon className='mr-1' icon={faUpload} size='sm' variant='primary' />
                        {this.props.intl.formatMessage({ id: 'mps-resourcefiles-upload-button-text' })}
                    </Button>
                </Col>
                <Col>{/* BLANK */}</Col>
            </Row>
        );
    };

    loadModalContent = (resFile) => {
        let formattedContent = null;
        let mimeType = this.getMimeType(resFile);

        if (!!resFile.contents) {
            formattedContent = `data:${mimeType};base64,${resFile.contents}`;
            this.setState({ modalContent: formattedContent });
        } else {
            aquireTokenSilentOrPopup((tokenResponse) => {
                const userId = getAccountId();
                const apiPrefix = !!this.props.isUserDraft ? `/users/${userId}` : '';
                const apiSuffix = '/mps/images';
                const apiPath = apiPrefix + apiSuffix;
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken
                    },
                    params: {
                        path: resFile.fullPath,
                        draftId: this.props.draft?.draftGuid
                    }
                };

                API.get(apiPath, config)
                    .then((response) => {
                        formattedContent = `data:${mimeType};base64,${response.data}`;
                        this.setState({ modalContent: formattedContent });
                    })
                    .catch((error) => {
                        console.log(error);
                        if (!!this.props.pushToast) {
                            this.props.pushToast({
                                variant: 'danger',
                                bodyId: 'error-failed-download'
                            });
                        }
                    });
            });
        }
    };

    renderResFile = (resFile, index) => {
        if (resFile.contents === null) {
            return null;
        }

        let bottomContent = null;
        if (Number.isInteger(resFile.percentage)) {
            bottomContent = (
                <ProgressBar variant='primary' striped animated active now={resFile.percentage} label={`${resFile.percentage}%`} />
            );
        } else {
            let modalChild = null;
            if (!!this.state.modalContent) {
                modalChild = <Image className='d-block m-auto' fluid alt={resFile?.fileName} src={this.state.modalContent} />;
            } else {
                modalChild = (
                    <div className='row mt-5 text-center justify-content-center align-self-center'>
                        <MicrosoftSpinner />
                        <h3 className='d-inline-block text-center justify-content-center align-self-center'>
                            {this.intl.formatMessage({ id: 'mps-resourcefile-loading' })}
                        </h3>
                    </div>
                );
            }

            bottomContent = (
                <div>
                    <div className='d-inline-block'>
                        <SimpleModal
                            icon={faEye}
                            buttonText={this.props.intl.formatMessage({ id: 'mps-resourcefile-view-label' })}
                            buttonAria={this.props.intl.formatMessage(
                                { id: 'mps-resourcefile-view-aria-label' },
                                { text: resFile.fileName }
                            )}
                            onShow={() => {
                                this.loadModalContent(resFile);
                            }}
                            onClose={() => {
                                this.setState({ modalContent: null });
                            }}
                        >
                            {modalChild}
                        </SimpleModal>
                    </div>
                    <Button
                        className='ml-2'
                        variant='danger'
                        aria-label={this.props.intl.formatMessage({ id: 'delete-item-button-aria-label' }, { text: resFile.fileName })}
                        onClick={() => this.removeResFile(index)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} size='sm' />
                    </Button>
                </div>
            );
        }

        let resFileIcon = resFile.contents === '' ? faCloud : faCloudUploadAlt;
        return (
            <Form.Group className='float-left w-25 p-2' key={`resourceFile${index}`}>
                <TooltipLabel icon={resFileIcon} iconSize='lg' label={resFile.fileName} />
                {bottomContent}
            </Form.Group>
        );
    };

    render() {
        let { resourceFiles } = this.props;

        if (!resourceFiles) {
            return null;
        }

        return (
            <Form>
                {this.renderUploadControl()}
                <hr />
                {this.props.resourceFiles.map((resFile, resFileIndex) => this.renderResFile(resFile, resFileIndex))}
            </Form>
        );
    }
}

export default injectIntl(MpsResourcesPage);
