// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './TextSetting.css';
import { generateNewSettingValue } from './SettingFactory';
import TextInput, { EMPTY_DROP_DOWN_VALUE, NULL_DROP_DOWN_VALUE } from '../../input/TextInput';
import { injectIntl } from 'react-intl';

export class TextSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: ''
        };
    }
    isValidRegex(newValue) {
        let regex = new RegExp(this.props.setting.Validation.Pattern);
        return regex.test(newValue);
    }

    validate(newValue) {
        if (this.props.setting.Validation.AllowedValues == null) {
            if (this.props.setting.Validation.HasPattern && !this.isValidRegex(newValue)) {
                this.setState({
                    errors: this.props.intl.formatMessage({ id: 'string-is-not-valid' })
                });
                return false;
            }
        }

        this.setState({ errors: '' });
        return true;
    }

    handleOnChange = (evt) => {
        if (!this.validate(evt)) {
            return;
        }

        const settingValue = generateNewSettingValue(this.props.setting, this.props.settingValue);

        if (evt.length === 0 || evt === NULL_DROP_DOWN_VALUE) {
            evt = null;
        }

        settingValue.value = evt;
        this.props.onChange(settingValue);
    };

    render() {
        let items = null;
        if (this.props.setting.Validation.AllowedValues != null) {
            items = this.props.setting.Validation.AllowedValues.map(function (value) {
                return { key: value.Name, name: value.Name, value: value.Value };
            });
            items.unshift(EMPTY_DROP_DOWN_VALUE);
        }

        return (
            <div className='text-setting'>
                <TextInput
                    displayName={this.props.displayName}
                    description={this.props.setting.Description}
                    value={this.props.settingValue?.value}
                    handleChange={this.handleOnChange}
                    isInvalid={!!this.state.errors}
                    dropDownItems={items}
                />
            </div>
        );
    }
}

export default injectIntl(TextSetting);
