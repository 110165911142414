// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './RadioButtonGroup.css';

export class RadioButtonGroup extends Component {
    btnDefaultVariant = 'light';
    btnSelectedVariant = 'dark';

    constructor(props) {
        super(props);
        this.trySetSelectedOption();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            this.trySetSelectedOption();
        }
    }

    trySetSelectedOption = () => {
        let { defaultIndex, options } = this.props;
        if (!Number.isInteger(defaultIndex) || !(defaultIndex >= 0 && defaultIndex < options?.length)) {
            return;
        }

        if (options?.length > 0) {
            let newIndex = Math.max(options.indexOf(this.props.value), defaultIndex);
            let newValue = options[newIndex];
            this.props.onChange(newValue);
        }
    };

    handleClick = (newKey) => {
        this.props.onChange(newKey);
    };

    getDisplayName = (key) => {
        if (!!key) {
            var displayName = this.props.intl.formatMessage({
                id: 'mps-option-' + key.toLowerCase(),
                defaultMessage: key
            });

            if (!!displayName && displayName.length > 0) {
                return displayName;
            }
        }

        return null;
    };

    render() {
        return (
            <div>
                <div className='btn-group-vertical' data-toggle='buttons'>
                    {this.props.options?.map((opt) => {
                        return (
                            <Button
                                key={opt}
                                variant={opt === this.props.value ? this.btnSelectedVariant : this.btnDefaultVariant}
                                onClick={() => {
                                    this.handleClick(opt);
                                }}
                            >
                                {this.getDisplayName(opt)}
                            </Button>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default injectIntl(RadioButtonGroup);
