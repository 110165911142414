// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SimpleModal from '../../controls/modal/SimpleModal';
import DoubleTextList from '../../controls/list/DoubleTextList';
import TooltipLabel from '../../controls/form/TooltipLabel';
import LabeledInput from '../../controls/form/LabeledInput';
import CollapsableContainer from '../../controls/container/CollapsableContainer';
import TabbedContainer from '../../controls/container/TabbedContainer';
import MpsPromoNotification from '../notifications/MpsPromoNotification';
import * as MpsConstants from '../MpsConstants';
import { deepClone, generateGuid } from '../MpsUtils';

export class MpsNotificationsPage extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
        this.didUpdateNotifications = false;
    }

    intl = this.props.intl;

    handleConfigValueChange = (newValue, ...propNames) => {
        if (!!this.props.onChange) {
            this.props.onChange(newValue, 'configFile', 'contents', ...propNames);
        }
    };

    handleNotificationValueChange = (newValue, id, lang, ...propNames) => {
        let { notificationFiles } = this.props;
        if (Array.isArray(notificationFiles) && !!this.props.onChange) {
            let index = notificationFiles.findIndex((notFile) => notFile.id === id && notFile.lang === lang);
            this.props.onChange(newValue, 'notificationFiles', index, 'contents', ...propNames);
        }
    };

    handleNotificationFilesChange = (addedFiles, removedFiles, overrides) => {
        if (Array.isArray(this.props.notificationFiles) && !!this.props.onChange) {
            let notFiles = deepClone(this.props.notificationFiles);

            for (let addedFile of addedFiles.reverse()) {
                let deleteIndex = notFiles.findIndex((file) => file.fullPath === addedFile.fullPath);
                if (deleteIndex >= 0) {
                    notFiles.splice(deleteIndex, 1);
                }

                let id = overrides?.addId ?? addedFile.id;
                let insertIndex = overrides?.addAtEnd ? notFiles.length : notFiles.map((file) => file.id).lastIndexOf(id) + 1;
                if (insertIndex === 0) {
                    insertIndex = notFiles.length;
                }

                notFiles.splice(insertIndex, 0, addedFile);
            }

            for (let removedFile of removedFiles) {
                let deleteIndex = notFiles.findIndex((file) => file.fullPath === removedFile.fullPath);

                if (this.props.allFiles.includes(removedFile.fullPath)) {
                    notFiles[deleteIndex].contents = null;
                } else {
                    notFiles.splice(deleteIndex, 1);
                }
            }

            this.props.onChange(notFiles, 'notificationFiles');
            this.didUpdateNotifications = true;
        }
    };

    // WARN: when deleting or renaming a file, the REST API expects a JSON object
    // with the "contents" set to null and the original "fullPath" intact.
    // e.g. { contents: null, fullPath: "..." } - mark this file for deletion.
    handleNotificationFilesIdChange = (newId, oldId) => {
        if (Array.isArray(this.props.notificationFiles) && !!this.props.onChange && !!this.props.notificationPathPrefix) {
            let notFiles = this.props.notificationFiles;
            if (notFiles.some((file) => file.id === newId && !!file.contents)) {
                return;
            }

            let oldNotFiles = deepClone(notFiles.filter((file) => file.id === oldId));
            let newNotFiles = deepClone(oldNotFiles);
            newNotFiles.forEach((newNotFile) => {
                let dummy = this.getEmptyNotificationFile(newId, newNotFile.lang);

                newNotFile.id = dummy.id;
                newNotFile.fileName = dummy.fileName;
                newNotFile.fullPath = dummy.fullPath;
            });

            this.handleNotificationFilesChange(newNotFiles, oldNotFiles, { addId: oldId });
        }
    };

    getNotificationFileIds = (notificationFiles) => {
        // Keep the old, existing IDs sorted.
        let oldNonUniqueIds =
            notificationFiles?.filter((notFile) => !!notFile.contents && !notFile.isNew)?.map((notFile) => notFile.id) ?? [];

        oldNonUniqueIds.sort((left, right) => {
            return left - right;
        });

        // Maintain the order of the newly added IDs. Do NOT sort!
        let newNonUniqueIds =
            notificationFiles?.filter((notFile) => !!notFile.contents && !!notFile.isNew)?.map((notFile) => notFile.id) ?? [];

        let uniqueIds = [...new Set([...oldNonUniqueIds, ...newNonUniqueIds])];
        return uniqueIds;
    };

    getUsedNotificationFileIds = () => {
        let usedIds = [
            this.props.config?.promotionNotification?.notificationId,
            this.props.config?.channelNotification?.notificationId,
            this.props.config?.sponsorNotification?.notificationId,
            this.props.config?.installedProfileNotification?.notificationId
        ].filter((id) => Number.isInteger(id));

        return usedIds;
    };

    getAvailableIds = () => {
        let notFileIds = this.getNotificationFileIds(this.props.notificationFiles);
        let availableIds = [...Array(MpsConstants.MAX_NOTIFICATIONS).keys()].filter((id) => notFileIds.indexOf(id) < 0);

        return availableIds;
    };

    getNotificationFilesById = (id) => {
        return this.props.notificationFiles?.filter((notFile) => notFile.id === id && !!notFile.contents);
    };

    getEmptyNotificationFile = (id, lang, controlId = generateGuid()) => {
        let emptyNotFile = deepClone(this.props.constants?.emptyNotificationFile) || { contents: {} };
        emptyNotFile.id = parseInt(id);
        emptyNotFile.lang = lang;
        emptyNotFile.fileName = emptyNotFile.fileName.replace('{id}', id).replace('{lang}', lang);
        emptyNotFile.fullPath = this.props.notificationPathPrefix + emptyNotFile.fileName;
        emptyNotFile.isNew = true;
        emptyNotFile.controlGuid = controlId;

        return emptyNotFile;
    };

    addEmptyNotificationFile = () => {
        if (!!this.props.onChange) {
            let availableIds = this.getAvailableIds();
            if (availableIds.length <= 0) {
                return;
            }

            let emptNotFile = this.getEmptyNotificationFile(availableIds[0], this.props.config?.defaultLang ?? 'en-US');
            this.handleNotificationFilesChange([emptNotFile], [], { addAtEnd: true });
        }
    };

    componentDidUpdate(prevProps) {
        if (this.didUpdateNotifications) {
            const currentNotificationFilesLength = this.getNotificationFileIds(this.props.notificationFiles).length;
            const prevNotificationsFilesLength = this.getNotificationFileIds(prevProps.notificationFiles).length;

            if (currentNotificationFilesLength !== prevNotificationsFilesLength && currentNotificationFilesLength > 0) {
                this.mainRef.current.focus();
            }
        }

        this.didUpdateNotifications = false;
    }

    renderNotificationFileItem = (notFileItem, notFileIndex) => {
        let ctrlIdSuffix = notFileItem.id + notFileItem.lang;
        let contents = notFileItem.contents;
        let resourcesOptions = ['', ...this.props.availableResourceFileNames];

        return (
            <div>
                <hr />
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId={'primaryText' + ctrlIdSuffix}
                            labelId='mps-notification-primarytext-label'
                            tooltipId='mps-notification-primarytext-tooltip'
                            value={contents.primaryText}
                            onChange={(newValue) => {
                                this.handleNotificationValueChange(newValue, notFileItem.id, notFileItem.lang, 'primaryText');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='textarea'
                            controlId={'secondaryText' + ctrlIdSuffix}
                            labelId='mps-notification-secondarytext-label'
                            tooltipId='mps-notification-primarytext-tooltip'
                            value={contents.secondaryText}
                            onChange={(newValue) => {
                                this.handleNotificationValueChange(newValue, notFileItem.id, notFileItem.lang, 'secondaryText');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId={'logoFileName' + ctrlIdSuffix}
                            labelId='mps-notification-logofilename-label'
                            tooltipId='mps-notification-logofilename-tooltip'
                            value={contents.logoFileName}
                            options={resourcesOptions}
                            onChange={(newValue) => {
                                this.handleNotificationValueChange(newValue, notFileItem.id, notFileItem.lang, 'logoFileName');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId={'heroImageFileName' + ctrlIdSuffix}
                            labelId='mps-notification-heroimagefilename-label'
                            tooltipId='mps-notification-heroimagefilename-tooltip'
                            value={contents.heroImageFileName}
                            options={resourcesOptions}
                            onChange={(newValue) => {
                                this.handleNotificationValueChange(newValue, notFileItem.id, notFileItem.lang, 'heroImageFileName');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='checkbox'
                            controlId={'isAutoDismiss' + ctrlIdSuffix}
                            labelId='mps-notification-isautodismiss-label'
                            tooltipId='mps-notification-isautodismiss-tooltip'
                            value={contents.isAutoDismiss}
                            onChange={(newValue) => {
                                this.handleNotificationValueChange(newValue, notFileItem.id, notFileItem.lang, 'isAutoDismiss');
                            }}
                        />
                    </Col>
                    <Col md={6}>{/* BLANK */}</Col>
                </Row>
            </div>
        );
    };

    render() {
        let config = this.props.config || {};
        let notificationFileIds = this.getNotificationFileIds(this.props.notificationFiles);
        let unusedNotificationFileIds = this.getAvailableIds();
        let rowPadding = 'p-3';
        let currentInstanceId = `${this.props.env}-${config?.providerId}`;
        let isSmsNotificationEnabled = !!config?.notificationUri && config.notificationUri.trim().length !== 0;

        return (
            <Form>
                <div className={`bg-light ${rowPadding}`}>
                    <MpsPromoNotification
                        labelId='mps-promonotification-label'
                        tooltipId='mps-promonotification-tooltip'
                        controlId='promotionNotification'
                        constants={this.props.constants}
                        availableIds={notificationFileIds}
                        value={config?.promotionNotification}
                        onChange={(newValue, ...propNames) => {
                            this.handleConfigValueChange(newValue, 'promotionNotification', ...propNames);
                        }}
                        instanceId={currentInstanceId}
                    />
                </div>
                <div className={`bg-white ${rowPadding}`}>
                    <MpsPromoNotification
                        labelId='mps-channelnotification-label'
                        tooltipId='mps-channelnotification-tooltip'
                        controlId='channelNotification'
                        constants={this.props.constants}
                        availableIds={notificationFileIds}
                        value={config?.channelNotification}
                        onChange={(newValue, ...propNames) => {
                            this.handleConfigValueChange(newValue, 'channelNotification', ...propNames);
                        }}
                        bonusContent={
                            <LabeledInput
                                type='checkbox'
                                controlId='isExclusiveChannel'
                                labelId='mps-isexclusivechannel-label'
                                tooltipId='mps-isexclusivechannel-tooltip'
                                value={config?.isExclusiveChannel}
                                onChange={(newValue) => {
                                    this.handleConfigValueChange(newValue, 'isExclusiveChannel');
                                }}
                            />
                        }
                        instanceId={currentInstanceId}
                    />
                </div>
                <div className={`bg-light ${rowPadding}`}>
                    <MpsPromoNotification
                        labelId='mps-sponsornotification-label'
                        tooltipId='mps-sponsornotification-tooltip'
                        controlId='sponsorNotification'
                        constants={this.props.constants}
                        availableIds={notificationFileIds}
                        value={config?.sponsorNotification}
                        onChange={(newValue, ...propNames) => {
                            this.handleConfigValueChange(newValue, 'sponsorNotification', ...propNames);
                        }}
                        instanceId={currentInstanceId}
                    />
                </div>
                <div className={`bg-white ${rowPadding}`}>
                    <MpsPromoNotification
                        labelId='mps-installedprofilenotification-label'
                        tooltipId='mps-installedprofilenotification-tooltip'
                        controlId='installedProfileNotification'
                        constants={this.props.constants}
                        availableIds={notificationFileIds}
                        value={config?.installedProfileNotification}
                        onChange={(newValue, ...propNames) => {
                            this.handleConfigValueChange(newValue, 'installedProfileNotification', ...propNames);
                        }}
                        bonusContent={
                            <SimpleModal
                                labelId='mps-installedprofiledevices-label'
                                tooltipId='mps-installedprofiledevices-tooltip'
                                count={config?.installedProfileDevices?.length}
                                highContrast
                            >
                                <DoubleTextList
                                    labelId='mps-installedprofiledevices-label'
                                    tooltipId='mps-installedprofiledevices-tooltip'
                                    firstLabelId='mps-device-make-label'
                                    secondLabelId='mps-device-model-label'
                                    deleteButtonAriaId='mps-installedprofiledevices-delete-button'
                                    firstPropName='make'
                                    secondPropName='model'
                                    items={config?.installedProfileDevices}
                                    onChange={(newValue) => {
                                        this.handleConfigValueChange(newValue, 'installedProfileDevices');
                                    }}
                                />
                            </SimpleModal>
                        }
                        instanceId={currentInstanceId}
                    />
                </div>
                <div className={`bg-light ${rowPadding}`}>
                    <CollapsableContainer
                        labelId='mps-smsnotification-label'
                        tooltipId='mps-smsnotification-tooltip'
                        labelSuffixId={isSmsNotificationEnabled ? 'mps-notification-enabled-suffix' : 'mps-notification-disabled-suffix'}
                    >
                        <Row>
                            <Col md={6}>
                                <LabeledInput
                                    type='text'
                                    controlId='notificationUri'
                                    labelId='mps-notificationuri-label'
                                    tooltipId='mps-notificationuri-tooltip'
                                    value={config?.notificationUri}
                                    onChange={(newValue) => {
                                        this.handleConfigValueChange(newValue, 'notificationUri');
                                    }}
                                />
                            </Col>
                            <Col md={6}>{/* BLANK */}</Col>
                        </Row>
                    </CollapsableContainer>
                </div>
                <hr />

                <TooltipLabel labelId='mps-notificationfiles-label' tooltipId='mps-notificationfiles-tooltip' />
                {notificationFileIds.map((notFileId, notFileIndex) => {
                    let labelText = this.props.intl.formatMessage({ id: 'mps-notificationfile-label' }, { id: notFileId });

                    let items = this.getNotificationFilesById(notFileId);
                    let excludedTabOptions = items.map((item) => item.lang);
                    let rowClassName = notFileIndex % 2 === 0 ? 'bg-light' : 'bg-white';
                    rowClassName += ` ${rowPadding}`;

                    const containerRef = notFileIndex === notificationFileIds.length - 1 ? this.mainRef : null;

                    const controlGuid = items[0].controlGuid;

                    return (
                        <div className={rowClassName} key={`notificationId${controlGuid}`}>
                            <CollapsableContainer
                                label={labelText}
                                controlId={`notificationCollapsableContainerId${controlGuid}`}
                                inputRef={containerRef}
                                controls={[
                                    <Button
                                        variant='outline-secondary'
                                        size='sm'
                                        aria-label={this.props.intl.formatMessage(
                                            { id: 'delete-item-button-aria-label' },
                                            { text: labelText }
                                        )}
                                        onClick={() =>
                                            this.handleNotificationFilesChange(
                                                [],
                                                this.props.notificationFiles?.filter((file) => file.id === notFileId)
                                            )
                                        }
                                        disabled={this.getUsedNotificationFileIds().some((id) => id === notFileId)}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} size='sm' />
                                    </Button>
                                ]}
                            >
                                <Row>
                                    <Col>
                                        <LabeledInput
                                            type='select'
                                            controlId={'notificationFileId' + notFileId}
                                            labelId='mps-notificationfile-id-label'
                                            tooltipId='mps-notificationfile-id-tooltip'
                                            value={notFileId}
                                            options={[notFileId, ...unusedNotificationFileIds]}
                                            onChange={(newId) => this.handleNotificationFilesIdChange(parseInt(newId), notFileId)}
                                            disabled={this.getUsedNotificationFileIds().some((id) => id === notFileId)}
                                        />
                                    </Col>
                                    <Col>{/* BLANK */}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TabbedContainer
                                            controlId={`notificationTabbedContainerId${notFileId}`}
                                            items={items}
                                            emptyItem={this.getEmptyNotificationFile(notFileId, '{lang}', controlGuid)}
                                            renderItem={this.renderNotificationFileItem}
                                            onChange={this.handleNotificationFilesChange}
                                            tabOptions={this.props.constants?.languages}
                                            excludedTabOptions={excludedTabOptions}
                                            tabPropName='lang'
                                            placeholderPropNames={['fileName', 'fullPath']}
                                        />
                                    </Col>
                                </Row>
                            </CollapsableContainer>
                        </div>
                    );
                })}
                <Row>
                    <Col>
                        <Button
                            onClick={this.addEmptyNotificationFile}
                            className='mt-2 mb-2 w-100'
                            disabled={unusedNotificationFileIds.length <= 0}
                            aria-label={this.props.intl.formatMessage({ id: 'mps-add-notificationfiles-aria-label' })}
                        >
                            <FontAwesomeIcon className='mr-1' icon={faPlusCircle} size='lg' />
                            {this.props.intl.formatMessage({ id: 'add' })}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default injectIntl(MpsNotificationsPage);
