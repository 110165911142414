import { Component } from 'react';
import { injectIntl } from 'react-intl';
import ModalContainer from '../../ModalContainer';

export class BackConfirmationDialog extends Component {
    render() {
        return (
            <ModalContainer
                title={this.props.intl.formatMessage({ id: 'back-confirmation-dialog-title' })}
                show={this.props.show}
                handleClose={this.props.handleClose}
                handleSubmit={this.props.handleSubmit}
                submissionButtonText={this.props.intl.formatMessage({ id: 'ok' })}
            >
                <p>{this.props.intl.formatMessage({ id: 'back-confirmation-dialog-description' })}</p>
            </ModalContainer>
        );
    }
}

export default injectIntl(BackConfirmationDialog);
