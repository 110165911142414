import { Component } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { stringNullOrWhiteSpace } from '../../../utils/StringUtils';
import { deepClone } from '../../mps/MpsUtils';

export class DraftDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                titleError: false,
                descriptionError: false
            }
        };
    }

    validateDraftDetails(newDraftDetails) {
        const newErrors = deepClone(this.state.errors);
        newErrors.titleError = stringNullOrWhiteSpace(newDraftDetails.title);
        newErrors.descriptionError = stringNullOrWhiteSpace(newDraftDetails.description);

        this.setState({ errors: newErrors });
    }

    handleUpdate = (parameterName) => (evt) => {
        const draftDetails = deepClone(this.props.draftDetails);
        draftDetails[parameterName] = evt.target.value;

        this.validateDraftDetails(draftDetails);
        this.props.onDraftDetailsUpdate(draftDetails);
    };

    hasError() {
        return !!this.state.errors.titleError || !!this.state.errors.descriptionError;
    }

    showNoDraftSelected() {
        return (
            <div>
                <p>{this.props.intl.formatMessage({ id: 'no-draft-selected' })}</p>
            </div>
        );
    }

    showDraftSelected() {
        return (
            <div>
                <Form.Group>
                    <Form.Label htmlFor='draft-details-title'>{this.props.intl.formatMessage({ id: 'draft-details-title' })}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            id='draft-details-title'
                            type='text'
                            value={this.props.draftDetails.title}
                            onChange={this.handleUpdate('title')}
                            isInvalid={this.state.errors.titleError}
                        />
                        <Form.Control.Feedback type='invalid' aria-live='polite'>
                            {this.props.intl.formatMessage({ id: 'error-title-empty' })}
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'type' })}</Form.Label>
                    <p>{this.props.intl.formatMessage({ id: this.props.draftDetails.type.toLowerCase() })}</p>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'modified' })}</Form.Label>
                    <p>{new Date(this.props.draftDetails.modifiedDate).toLocaleString()}</p>
                    <Form.Label htmlFor='draft-details-description'>{this.props.intl.formatMessage({ id: 'description' })}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            id='draft-details-description'
                            type='text'
                            value={this.props.draftDetails.description}
                            onChange={this.handleUpdate('description')}
                            isInvalid={this.state.errors.descriptionError}
                        />
                        <Form.Control.Feedback type='invalid' aria-live='polite'>
                            {this.props.intl.formatMessage({ id: 'error-description-empty' })}
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Label htmlFor='draft-details-background'>{this.props.intl.formatMessage({ id: 'background' })}</Form.Label>
                    <Form.Control
                        id='draft-details-background'
                        type='text'
                        value={this.props.draftDetails.background}
                        onChange={this.handleUpdate('background')}
                    />
                </Form.Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button disabled={!this.props.draftDetails.isEdited || this.hasError()} onClick={this.props.onSaveDraftDetails}>
                        {this.props.intl.formatMessage({ id: 'save-changes' })}
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        let itemToShow = null;
        if (!!this.props.draftDetails) {
            itemToShow = this.showDraftSelected();
        } else {
            itemToShow = this.showNoDraftSelected();
        }

        return <div>{itemToShow}</div>;
    }
}

export default injectIntl(DraftDetails);
