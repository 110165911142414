// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { isUserSignedIn, loginRedirect, isUserAuthenticating, waitForAuthenticatingCallbacks } from './MsalUtils';
import { connect } from 'react-redux';
import { updateLoginState } from '../store/LoginStatus';

export function withAuth(WrappedComponent) {
    class Auth extends Component {
        constructor(props) {
            super(props);
        }

        componentWillMount() {
            if (isUserAuthenticating()) {
                waitForAuthenticatingCallbacks(this.waitForAuthCallBack);
            } else {
                this.handleUserLoginState();
            }
        }

        render() {
            return this.props.authenticated && <WrappedComponent />;
        }

        waitForAuthCallBack = () => {
            this.handleUserLoginState();
        };

        handleUserLoginState() {
            if (!isUserSignedIn()) {
                loginRedirect();
            } else {
                this.props.dispatch(updateLoginState());
            }
        }
    }

    function mapStateToProps(state) {
        return {
            authenticated: state.loginReducer.hasLoggedInUser
        };
    }

    return connect(mapStateToProps)(Auth);
}
