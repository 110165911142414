// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { connect } from 'react-redux';
import { loginRedirect, logout } from '../auth/MsalUtils';
import './NavMenu.css';
import { updateLoginState, updateLogoutState } from '../store/LoginStatus';
import { FormattedMessage } from 'react-intl';

class NavMenu extends Component {
    getLoginStatus() {
        return this.props.hasLoggedInUser ? <FormattedMessage id='logout.text' /> : <FormattedMessage id='login.text' />;
    }

    loginOrLogout() {
        if (this.props.hasLoggedInUser) {
            this.props.dispatch(updateLogoutState());
            logout();
        } else {
            loginRedirect((error) => {
                if (!error) {
                    updateLoginState();
                }
                this.props.history.push('/');
            });
        }
    }

    render() {
        return (
            <Navbar bg='light'>
                <Navbar.Brand role='heading' aria-level='1' tabIndex='0'>
                    Mobile Operator Portal
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className='justify-content-end'>
                    <Nav>
                        <Nav.Link id='nav-link' onClick={() => this.loginOrLogout()}>
                            {this.getLoginStatus()}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        hasLoggedInUser: state.loginReducer.hasLoggedInUser
    };
}

export default connect(mapStateToProps)(NavMenu);
