// Copyright (C) Microsoft Corporation. All rights reserved.
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavMenu from './NavMenu';
import Footer from './Footer';
import './Layout.css';

export default (props) => (
    <Container fluid>
        <Row>
            <Col>
                <NavMenu />
            </Col>
        </Row>
        <Row className='portal-container mt-1'>
            <Col>{props.children}</Col>
        </Row>
        <Row>
            <Col>
                <Footer />
            </Col>
        </Row>
    </Container>
);
