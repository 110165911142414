import { Component, createRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import SelectSubmissionContainer from './SelectSubmissionContainer';
import SubmissionDetails from './SubmissionDetails';
import { aquireTokenSilentOrPopup, createAuthorizationHeaders, getAccountId } from '../../../auth/MsalUtils';
import API from '../../../API';
import DiscardSubmissionModal from './DiscardSubmissionModal';
import { injectIntl } from 'react-intl';
import LoadingSpinner from '../../common/LoadingSpinner';
import { ACTION_NEEDED } from './SelectSubmissionList';

export class SubmissionsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submissionDetails: null,
            selectedSubmission: null,
            submissionList: [],
            showDiscardSubmissionModal: false,
            isLoading: false,
            loadingMessageId: null
        };

        this.openSubmissionButtonRef = createRef();
    }

    onSubmissionDetailsUpdate = (newSubmissionDetails) => {
        newSubmissionDetails.isEdited = true;
        this.setState({ submissionDetails: newSubmissionDetails });
    };

    componentWillMount() {
        this.requestSubmissions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedSubmission != null && prevState.selectedSubmission != this.state.selectedSubmission) {
            this.openSubmissionButtonRef.current.focus();
        }
    }

    showLoadingScreen(loadingMessageId) {
        this.setState({ isLoading: true, loadingMessageId: loadingMessageId });
    }

    hideLoadingScreen() {
        this.setState({ isLoading: false, loadingMessageId: null });
    }

    requestSubmissions() {
        this.showLoadingScreen('getting-submissions');
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            API.get(`/users/${userGuid}/submissions/all`, headers)
                .then((response) => {
                    console.log(response);
                    this.setState({ submissionList: response.data.submissions });

                    if (!!this.state.selectedSubmission) {
                        const selectedSubmission = response.data.submissions.find(
                            (submission) => submission.draftGuid === this.state.selectedSubmission.draftGuid
                        );
                        const updatedSubmissionDetails = this.createSubmissionDetails(selectedSubmission);
                        this.setState({ submissionDetails: updatedSubmissionDetails });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'failed-to-get-submissions' }));
                })
                .finally(() => {
                    this.hideLoadingScreen();
                });
        });
    }

    onSubmissionSelected = (selectedSubmission) => {
        const newSubmissionDetails = this.createSubmissionDetails(selectedSubmission);
        this.setState({ submissionDetails: newSubmissionDetails, selectedSubmission: selectedSubmission });
    };

    createSubmissionDetails(submissionItem) {
        const newSubmissionDetails = {
            title: submissionItem.title,
            type: submissionItem.type,
            description: submissionItem.description,
            modifiedDate: submissionItem.lastUpdated,
            background: submissionItem.background,
            needsAttention: submissionItem.status === ACTION_NEEDED,
            needsAttentionMessage: submissionItem.needsAttentionMessage,
            canEdit: submissionItem.isActive,
            isEdited: false
        };

        return newSubmissionDetails;
    }

    onDiscardSelectedSubmission = () => {
        this.showLoadingScreen('deleting-submission');
        this.closeSubmissionModal();
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            const apiEndpoint =
                this.state.submissionDetails.type === 'Cosa'
                    ? `/users/${userGuid}/cosa/submissions/${this.state.selectedSubmission.draftGuid}`
                    : `/users/${userGuid}/mps/submissions/${this.state.selectedSubmission.draftGuid}`;
            API.delete(apiEndpoint, headers)
                .then((response) => {
                    console.log(response);
                    this.setState({ submissionList: response.data.submissions, submissionDetails: null });
                    this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'submission-discard-success' }));
                    this.requestSubmissions();
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'submission-discard-failed' }));
                    this.hideLoadingScreen();
                });
        });
    };

    closeSubmissionModal = () => {
        this.setState({ showDiscardSubmissionModal: false });
    };

    onDiscardSubmissionButtonClicked = () => {
        this.setState({ showDiscardSubmissionModal: true });
    };

    isSubmissionSelected() {
        return !!this.state.selectedSubmission && this.state.selectedSubmission.isActive;
    }

    sendUpdatedDraftRequest = () => {
        this.showLoadingScreen('updating-submission-details');
        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = createAuthorizationHeaders(tokenResponse.accessToken);

            const userGuid = getAccountId();
            const selectedDraft = this.state.submissionList.find(
                (draftItem) => draftItem.draftGuid === this.state.selectedSubmission.draftGuid
            );

            if (!selectedDraft) {
                console.log("Couldn't find the draft with guid " + this.state.selectedSubmission.draftGuid);
            }

            const draftDetails = this.state.submissionDetails;
            const apiEndpoint =
                selectedDraft.type === 'Cosa'
                    ? `/users/${userGuid}/cosa/drafts/${selectedDraft.pullRequestId}`
                    : `/users/${userGuid}/mps/drafts/${selectedDraft.pullRequestId}`;

            API.post(
                apiEndpoint,
                {
                    title: draftDetails.title,
                    description: draftDetails.description,
                    background: draftDetails.background
                },
                headers
            )
                .then((response) => {
                    console.log(response);
                    this.requestSubmissions();
                    this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'submission-details-updated-success' }));
                })
                .catch((error) => {
                    console.log(error);
                    this.props.pushErrorToast(this.props.intl.formatMessage({ id: 'submission-details-updated-failed' }));
                    this.hideLoadingScreen();
                });
        });
    };

    getLoadingScreen() {
        return <LoadingSpinner loadingTextId={this.state.loadingMessageId} />;
    }

    onOpenSubmissionButtonClicked = () => {
        if (this.state.selectedSubmission.type === 'Cosa') {
            this.props.openCosaConfig(this.state.selectedSubmission);
        } else {
            this.props.openMpsConfig(this.state.selectedSubmission);
        }
    };

    onDiscardSubmissionButtonClicked = () => {
        this.setState({ showDiscardSubmissionModal: true });
    };

    getSubmissionButtonOptions() {
        if (this.state.submissionList === null || this.state.submissionList.length === 0) {
            return '';
        }
        return (
            <Row className='justify-content-sm-center'>
                <Col sm='2'>
                    <Button
                        className='open-sub-btn'
                        style={{ minWidth: '135px', width: '100%', marginBottom: '8px' }}
                        disabled={!this.isSubmissionSelected()}
                        onClick={this.onOpenSubmissionButtonClicked}
                        ref={this.openSubmissionButtonRef}
                        aria-label={this.props.intl.formatMessage(
                            { id: 'open-submission-aria-label' },
                            { submissionName: this.state.selectedSubmission?.title }
                        )}
                    >
                        {this.props.intl.formatMessage({ id: 'open-submission' })}
                    </Button>
                </Col>
                <Col sm={{ span: 2, offset: 2 }}>
                    <Button
                        className='discard-sub-btn'
                        variant='danger'
                        style={{ minWidth: '135px', width: '100%' }}
                        disabled={!this.isSubmissionSelected()}
                        onClick={this.onDiscardSubmissionButtonClicked}
                        aria-label={this.props.intl.formatMessage(
                            { id: 'discard-submission-aria-label' },
                            { submissionName: this.state.selectedSubmission?.title }
                        )}
                    >
                        {this.props.intl.formatMessage({ id: 'discard-submission' })}
                    </Button>
                </Col>
            </Row>
        );
    }

    getSubmissionDetailsPane() {
        if (this.state.submissionList === null || this.state.submissionList.length === 0) {
            return (
                <div className='create-submission'>
                    <p>{this.props.intl.formatMessage({ id: 'create-submission-to-start' })}</p>
                </div>
            );
        }

        return (
            <SubmissionDetails
                submissionDetails={this.state.submissionDetails}
                onSubmissionDetailsUpdate={this.onSubmissionDetailsUpdate}
                saveSubmissionDetails={this.sendUpdatedDraftRequest}
            />
        );
    }

    getTableAndData() {
        return (
            <div>
                <Row>
                    <Col lg='8'>
                        <SelectSubmissionContainer
                            selectedSubmission={this.state.selectedSubmission}
                            submissionList={this.state.submissionList}
                            onSubmissionSelected={this.onSubmissionSelected}
                            selectedSubmission={this.state.selectedSubmission}
                        />
                        {this.getSubmissionButtonOptions()}
                    </Col>
                    <Col lg='4'>{this.getSubmissionDetailsPane()}</Col>
                </Row>
                <div>
                    <DiscardSubmissionModal
                        show={this.state.showDiscardSubmissionModal}
                        handleClose={this.closeSubmissionModal}
                        onDiscardSelectedSubmission={this.onDiscardSelectedSubmission}
                    />
                </div>
            </div>
        );
    }

    render() {
        let content = null;
        if (this.state.isLoading) {
            content = this.getLoadingScreen();
        } else {
            content = this.getTableAndData();
        }

        return <div>{content}</div>;
    }
}

export default injectIntl(SubmissionsContainer);
