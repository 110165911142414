// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import PortalContainer from './PortalContainer';
import { connect } from 'react-redux';
import { withAuth } from '../auth/Auth';

// This class is meant to ensure the authentication
// Better to do it one place that we know will need authentication than every where that might need it.
class PortalAuth extends Component {
    render() {
        return <PortalContainer />;
    }
}

export default connect()(withAuth(PortalAuth));
