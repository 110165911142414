// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import TooltipLabel from '../form/TooltipLabel';
import { generateGuid } from '../../mps/MpsUtils';
import DescriptionToolTip from '../../DescriptionToolTip';
import './SimpleModal.css';

export class SimpleModal extends Component {
    constructor(props) {
        super(props);

        if (!!this.props.setShowModal) {
            this.props.setShowModal(this.showModal);
        }

        this.state = {
            show: false
        };
    }

    componentWillUnmount = () => {
        if (!!this.props.setShowModal) {
            this.props.setShowModal(null);
        }
    };

    showModal = () => {
        this.setState({ show: true });
        if (this.props.onShow) {
            this.props.onShow();
        }
    };

    closeModal = () => {
        this.setState({ show: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    getTitleIcon() {
        if (!!this.props.titleIcon) {
            return <div className='simple-modal-title-icon'>{this.props.titleIcon}</div>;
        }

        return null;
    }

    getModalHeader = () => {
        let titleText = null;
        if (!!this.props.title) {
            titleText = this.props.title;
        } else if (!!this.props.titleId) {
            titleText = this.props.intl.formatMessage({ id: this.props.titleId });
        } else if (!!this.props.label) {
            titleText = this.props.label;
        } else if (!!this.props.labelId) {
            titleText = this.props.intl.formatMessage({ id: this.props.labelId });
        }
        const titleIcon = this.getTitleIcon();

        if (!!titleText) {
            return (
                <Modal.Header closeButton>
                    {!!titleIcon && titleIcon}
                    <Modal.Title as='h4'>{titleText}</Modal.Title>
                </Modal.Header>
            );
        }

        return null;
    };

    getButtonText() {
        let buttonText;
        if (!!this.props.buttonText) {
            buttonText = this.props.buttonText;
        } else if (!!this.props.buttonTextId) {
            buttonText = this.props.intl.formatMessage({ id: this.props.buttonTextId });
        } else {
            buttonText = this.props.intl.formatMessage({ id: 'edit' });
        }

        return buttonText;
    }

    getDisplayButtonChildren = () => {
        let countStr = !!this.props.count ? ' (' + this.props.count + ')' : '';

        let buttonText = this.getButtonText();
        let icon = this.props.icon;

        if (!this.props.buttonText && !this.props.buttonTextId) {
            icon = icon ?? faPencilAlt;
        }

        buttonText += countStr;

        if (!!buttonText) {
            return (
                <div>
                    <FontAwesomeIcon className='mr-1' icon={icon} size='sm' />
                    {buttonText}
                </div>
            );
        }

        return null;
    };

    getLabel() {
        let labelText;
        if (!!this.props.label) {
            labelText = this.props.label;
        } else if (!!this.props.labelId) {
            labelText = this.props.intl.formatMessage({ id: this.props.labelId });
        }

        return labelText;
    }

    getButtonAriaLabel() {
        let buttonText;
        if (!!this.props.buttonAria) {
            buttonText = this.props.buttonAria;
        } else {
            buttonText = this.getButtonText();
            buttonText = `${buttonText} ${this.getLabel()}`;
        }

        return buttonText;
    }

    getDisplayButton = () => {
        if (!!this.props.hideButton) {
            return null;
        }

        let displayButtonChildren = this.getDisplayButtonChildren();
        if (!!displayButtonChildren) {
            return (
                <div>
                    <TooltipLabel
                        label={this.props.label}
                        labelId={this.props.labelId}
                        tooltip={this.props.tooltip}
                        tooltipId={this.props.tooltipId}
                    />
                    <Button
                        className='simple-modal-show-button'
                        variant={!!this.props.highContrast ? 'secondary' : 'light'}
                        onClick={this.showModal}
                        disabled={this.props.buttonDisabled}
                        aria-label={this.getButtonAriaLabel()}
                    >
                        {displayButtonChildren}
                    </Button>
                </div>
            );
        }

        return null;
    };

    render() {
        return (
            <Form.Group>
                {this.getDisplayButton()}

                <Modal size='lg' centered scrollable show={this.state.show} onHide={this.closeModal}>
                    {this.getModalHeader()}
                    <Modal.Body className='simple-modal-body' tabindex='0'>
                        {this.state.show ? this.props.children : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={this.closeModal}>
                            {this.props.intl.formatMessage({ id: 'close' })}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form.Group>
        );
    }
}

export default injectIntl(SimpleModal);
