// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';

export default class OrderedListSetting extends Component {
    render() {
        // TODO: this setting doesn't appear to be used in the COSA file just yet
        return (
            <div>
                <p>Ordered List setting !!!! {this.props.displayName}</p>
            </div>
        );
    }
}
