// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import NavigationWithDisplayContainer from '../../common/NavigationWithDisplayContainer';
import SettingTreeNavigationContainer from '../settings/SettingTreeNavigationContainer';
import SettingsContainer from '../settings/SettingsContainer';
import './EditProfileSettingsContainer.css';
import { createNewSettingValue } from '../settingtypes/SettingFactory';
import { CreateNewGuid } from '../../../Utils';
import NoSettingsSelectedContainer from '../settings/NoSettingsSelectedContainer';
import TargetsContainer from '../targets/TargetsContainer';
import { deepClone } from '../../mps/MpsUtils';

export class EditProfileSettingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSetting: null,
            selectedSettingValue: null,
            selectedGroupSetting: null,
            selectedTargetState: null,
            itemPath: null
        };
    }

    onBackButtonPressed = () => {
        this.resetState();
        this.props.deselectProfile();
    };

    handleOnChildItemSelected = (setting, settingValue, itemPath) => {
        this.setState({
            selectedSetting: setting,
            selectedGroupSetting: null,
            selectedSettingValue: settingValue,
            itemPath: itemPath,
            selectedTargetState: null
        });
    };

    handleOnGroupItemSelected = (groupSetting, settingValue, itemPath) => {
        this.setState({
            selectedGroupSetting: groupSetting,
            selectedSetting: null,
            selectedSettingValue: settingValue,
            itemPath: itemPath,
            selectedTargetState: null
        });
    };

    createConnectionIdWithIndex(profileName, index) {
        return `${profileName}_i${index}$(__MVID)@WAP`;
    }

    updateConnectionsAttributes(profileName, cellularSetting) {
        let connectionIds = [];
        for (let i = 0; i < cellularSetting.childSettings.length; i++) {
            const connectionSetting = cellularSetting.childSettings[i];
            if (connectionSetting.attributes != null && connectionSetting.attributes.length != 0) {
                connectionIds.push(connectionSetting.attributes[0].value);
            }
        }

        let idIdx = 0;
        for (let i = 0; i < cellularSetting.childSettings.length; i++) {
            const connectionSetting = cellularSetting.childSettings[i];
            if (connectionSetting.attributes == null || connectionSetting.attributes.length === 0) {
                let newConnectionId = this.createConnectionIdWithIndex(profileName, idIdx);
                while (connectionIds.includes(newConnectionId)) {
                    idIdx++;
                    newConnectionId = this.createConnectionIdWithIndex(profileName, idIdx);
                }
                connectionSetting.attributes = [];
                connectionSetting.attributes.push({ name: 'ConnectionName', value: newConnectionId });
            }
        }
    }

    adjustProfileSettingsAndValues(profile) {
        let settings = [].concat(profile.settings);
        while (settings.length > 0) {
            const setting = settings.pop();
            if (setting.childSettings != null && setting.childSettings.length > 0) {
                settings = settings.concat(setting.childSettings);
            }

            if (setting.path === '/Connections/Cellular' && setting.childSettings != null && setting.childSettings.length > 0) {
                this.updateConnectionsAttributes(profile.profileName, setting);
            }
        }
    }

    handleAddingSettingValue(settingValue, itemPath) {
        const profileSettings = deepClone(this.props.profile.profile.settings);
        let searchList = profileSettings;
        const settingPathSplit = itemPath.split('/');
        let currentSettingName = '';
        let currentSetting = null;
        for (let i = 1; i < settingPathSplit.length; i++) {
            const settingNameSplit = settingPathSplit[i].split('.');
            currentSettingName += '/' + settingNameSplit[0];
            let foundChild = false;

            if (searchList != null) {
                if (settingNameSplit.length === 2) {
                    for (let j = 0; j < searchList.length; j++) {
                        if (searchList[j].guid == settingNameSplit[1]) {
                            currentSetting = searchList[j];
                            searchList = searchList[j].childSettings;
                            foundChild = true;
                            break;
                        }
                    }
                } else {
                    for (let j = 0; j < searchList.length; j++) {
                        if (searchList[j].path === currentSettingName) {
                            currentSetting = searchList[j];
                            searchList = searchList[j].childSettings;
                            foundChild = true;
                            break;
                        }
                    }
                }
            }

            if (!foundChild) {
                const newSetting = createNewSettingValue(settingPathSplit[i], currentSettingName, [], null, null, CreateNewGuid());
                searchList.push(newSetting);
                currentSetting = newSetting;
                searchList = newSetting.childSettings;
            }
        }

        currentSetting.childSettings = settingValue.childSettings;

        const profile = deepClone(this.props.profile);
        profile.profile.settings = profileSettings;
        this.adjustProfileSettingsAndValues(profile.profile);

        this.props.updateProfile(profile);
    }

    handleSelectedSettingValueChange = (settingValue) => {
        this.setState({ selectedSettingValue: settingValue });
        this.handleAddingSettingValue(settingValue, this.state.itemPath);
    };

    onNavigationItemAdded = (settingValue, itemPath) => {
        this.handleAddingSettingValue(settingValue, itemPath);
    };

    onNavigationItemDeleted = (itemPath) => {
        if (this.state.itemPath === itemPath) {
            this.resetState();
        }

        const profileSettings = deepClone(this.props.profile.profile.settings);
        let searchList = profileSettings;
        const settingPathSplit = itemPath.split('/');
        let parentSetting = null;
        let currentSettingName = '';
        let settingList = [];

        for (let i = 1; i < settingPathSplit.length - 1; i++) {
            const settingNameSplit = settingPathSplit[i].split('.');
            currentSettingName += '/' + settingNameSplit[0];

            let foundChild = false;
            if (settingNameSplit.length === 2) {
                for (let j = 0; j < searchList.length; j++) {
                    if (searchList[j].guid == settingNameSplit[1]) {
                        parentSetting = searchList[j];
                        settingList.push(parentSetting);
                        searchList = searchList[j].childSettings;
                        foundChild = true;
                        break;
                    }
                }
            } else {
                for (let j = 0; j < searchList.length; j++) {
                    if (searchList[j].path === currentSettingName) {
                        parentSetting = searchList[j];
                        settingList.push(parentSetting);
                        searchList = searchList[j].childSettings;
                        foundChild = true;
                        break;
                    }
                }
            }

            if (!foundChild) {
                return;
            }
        }

        const settingNameSplit = settingPathSplit[settingPathSplit.length - 1].split('.');
        for (let i = 0; i < parentSetting.childSettings.length; i++) {
            if (parentSetting.childSettings[i].guid === settingNameSplit[1]) {
                parentSetting.childSettings.splice(i, 1);
            }
        }

        const profile = deepClone(this.props.profile);
        profile.profile.settings = profileSettings;
        this.adjustProfileSettingsAndValues(profile.profile);
        this.props.updateProfile(profile);
    };

    onProfileDeleted = (guid) => {
        this.resetState();
        this.props.onProfileDeleted(guid);
    };

    resetState() {
        this.setState({
            selectedSetting: null,
            selectedSettingGroup: null,
            selectedSettingValue: null,
            itemPath: null,
            selectedTargetState: null
        });
    }

    targetItemSelected = (targetState) => {
        this.setState({
            selectedSetting: null,
            selectedSettingGroup: null,
            itemPath: targetState.id,
            selectedSettingValue: null,
            selectedTargetState: targetState
        });
    };

    onTargetStateChange = (newTargetState) => {
        this.setState({ selectedTargetState: newTargetState });

        const profile = deepClone(this.props.profile);
        let index = -1;
        for (let i = 0; i < profile.target.targetState.length; i++) {
            if (newTargetState.id === profile.target.targetState[i].id) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            return;
        }

        profile.target.targetState[index] = newTargetState;
        this.props.updateProfile(profile);
    };

    onTargetItemAdded = () => {
        const profile = deepClone(this.props.profile);
        profile.target.targetState.push({ id: CreateNewGuid(), targetConditions: [] });
        this.props.updateProfile(profile);
        this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'target-item-added' }));
    };

    onTargetItemDeleted = (targetState) => {
        if (this.state.selectedTargetState != null && this.state.selectedTargetState.id === targetState.id) {
            this.resetState();
        }

        const profile = deepClone(this.props.profile);
        let index = -1;
        for (let i = 0; i < profile.target.targetState.length; i++) {
            if (profile.target.targetState[i].id === targetState.id) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            return;
        }

        profile.target.targetState.splice(index, 1);
        this.props.updateProfile(profile);
        this.props.pushSuccessToast(this.props.intl.formatMessage({ id: 'target-item-deleted' }));
    };

    getSettingTreeNavigation() {
        return (
            <SettingTreeNavigationContainer
                profile={this.props.profile}
                schema={this.props.schema}
                onBackButtonPressed={this.onBackButtonPressed}
                onChildItemSelected={this.handleOnChildItemSelected}
                onGroupItemSelected={this.handleOnGroupItemSelected}
                onNavigationItemAdded={this.onNavigationItemAdded}
                onNavigationItemDeleted={this.onNavigationItemDeleted}
                selectedItemPath={this.state.itemPath}
                onProfileDeleted={this.onProfileDeleted}
                onProfileNameChanged={this.props.onProfileNameChanged}
                targetItemSelected={this.targetItemSelected}
                onTargetItemAdded={this.onTargetItemAdded}
                onTargetItemDeleted={this.onTargetItemDeleted}
            />
        );
    }

    getSettingContainer() {
        return (
            <SettingsContainer
                schema={this.props.schema}
                profile={this.props.profile}
                selectedSetting={this.state.selectedSetting}
                selectedGroupSetting={this.state.selectedGroupSetting}
                selectedSettingValue={this.state.selectedSettingValue}
                onSettingValueChanged={this.handleSelectedSettingValueChange}
                onAssetsChange={this.props.updateAssets}
                selectedItemPath={this.state.itemPath}
            />
        );
    }

    getTargetContainer() {
        return (
            <TargetsContainer
                targetConditionSchema={this.props.schema.TargetConditions}
                targetState={this.state.selectedTargetState}
                onTargetStateChange={this.onTargetStateChange}
                selectedItemPath={this.state.selectedItemPath}
            />
        );
    }

    getDisplayContainer() {
        if (this.state.selectedSetting != null || this.state.selectedGroupSetting != null) {
            return this.getSettingContainer();
        } else if (this.state.selectedTargetState != null) {
            return this.getTargetContainer();
        } else {
            return <NoSettingsSelectedContainer />;
        }
    }

    render() {
        return (
            <NavigationWithDisplayContainer
                navigationComponent={this.getSettingTreeNavigation()}
                displayComponent={this.getDisplayContainer()}
            />
        );
    }
}

export default injectIntl(EditProfileSettingContainer);
