// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Container, Col, Row } from 'react-bootstrap';
import { isAlphaNumeric } from '../../mps/MpsUtils';
import * as FaIcons from '@fortawesome/free-solid-svg-icons';
import LabeledInput from '../../controls/form/LabeledInput';
import RadioButtonGroup from '../../controls/RadioButtonGroup';
import OkCancelModal from '../../controls/modal/OkCancelModal';
import * as MpsConstants from '../MpsConstants';
import './MpsNavigation.css';

export class MpsNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newProviderName: '',
            newProviderNameErrorId: null
        };
    }

    pages = [
        MpsConstants.CONFIGURATION_PAGE,
        MpsConstants.GATEWAYS_PAGE,
        MpsConstants.NOTIFICATIONS_PAGE,
        MpsConstants.RESOURCES_PAGE,
        MpsConstants.DATA_PAGE
    ];

    getEnvDisplayName = (envKey) => {
        return this.props.intl.formatMessage({ id: 'mps-env-' + envKey.toLowerCase() });
    };

    getEnvArray = () => {
        if (Array.isArray(this.props.root.environments) && this.props.root.environments.length > 0) {
            return this.props.root.environments.map((env) => env.name);
        }

        return [];
    };

    getProviderArray = (envKey) => {
        if (!!envKey) {
            var envMatch = this.props.root.environments.find((env) => env.name === envKey);
            if (!!envMatch) {
                return envMatch.providers.filter((provider) => !!provider?.configFile?.contents).map((provider) => provider.name);
            }
        }

        return [];
    };

    getExistingProviderIdByName = (nameToSearch) => {
        let { root } = this.props;

        for (let env of root?.environments) {
            for (let provider of env?.providers) {
                let providerName = provider.name;
                let providerId = provider.configFile?.contents?.providerId;

                if (!!providerId && nameToSearch === providerName) {
                    return providerId;
                }
            }
        }

        return null;
    };

    handleEnvironmentChange = (newEnvKey) => {
        this.props.onChange({
            environment: newEnvKey
        });
    };

    handleProviderChange = (newProviderKey) => {
        this.props.onChange({
            provider: newProviderKey
        });
    };

    handlePageChange = (newPageKey) => {
        this.props.onChange({
            page: newPageKey
        });
    };

    renderPageButtons = (isVisible) => {
        if (!isVisible) {
            return null;
        }

        return (
            <Row>
                <Col>
                    <RadioButtonGroup value={this.props.page} options={this.pages} onChange={this.handlePageChange} defaultIndex={0} />
                </Col>
            </Row>
        );
    };

    renderAddProviderModal = () => {
        let buttonVariant = 'primary';
        let buttonIcon = FaIcons.faPlusCircle;
        let buttonTextId = 'mps-add-provider';
        let titleId = 'mps-add-provider-title';

        return (
            <OkCancelModal
                buttonVariant={buttonVariant}
                icon={buttonIcon}
                titleId={titleId}
                buttonTextId={buttonTextId}
                reuseButton={true}
                fullWidthButton={true}
                onSuccess={() => {
                    if (!isAlphaNumeric(this.state.newProviderName)) {
                        this.setState({
                            newProviderNameErrorId: 'mps-add-provider-error'
                        });

                        return false;
                    }

                    this.props.onChange({
                        isNewProvider: true,
                        environment: this.props.environment,
                        provider: this.state.newProviderName,
                        existingProviderId: this.getExistingProviderIdByName(this.state.newProviderName)
                    });

                    return true;
                }}
            >
                <LabeledInput
                    type='text'
                    controlId='addProviderName'
                    labelId='mps-add-provider-label'
                    tooltipId='mps-add-provider-tooltip'
                    errorId={this.state.newProviderNameErrorId}
                    value={this.state.newProviderName}
                    onChange={(newValue) =>
                        this.setState({
                            newProviderName: newValue,
                            newProviderNameErrorId: null
                        })
                    }
                />
            </OkCancelModal>
        );
    };

    renderDeleteProviderModal = () => {
        let buttonVariant = 'danger';
        let buttonIcon = FaIcons.faTrashAlt;
        let buttonTextId = 'mps-delete-provider';
        let bodyText = this.props.intl.formatMessage(
            { id: 'mps-delete-provider-body' },
            {
                providerName: this.props.provider,
                environmentName: this.props.environment
            }
        );
        let titleText = this.props.intl.formatMessage(
            { id: 'mps-delete-provider-title' },
            {
                providerName: this.props.provider,
                environmentName: this.props.environment
            }
        );

        return (
            <OkCancelModal
                buttonVariant={buttonVariant}
                icon={buttonIcon}
                title={titleText}
                buttonTextId={buttonTextId}
                reuseButton={true}
                fullWidthButton={true}
                onSuccess={() => {
                    this.props.onChange({
                        isDeleteProvider: true,
                        environment: this.props.environment,
                        provider: this.props.provider
                    });

                    return true;
                }}
            >
                <h5>{bodyText}</h5>
            </OkCancelModal>
        );
    };

    render() {
        let environments = this.getEnvArray();
        let providers = this.getProviderArray(this.props.environment);

        return (
            <Container className='mps-navigation-container'>
                <hr />
                <Row>
                    <Col>
                        <LabeledInput
                            type='select'
                            controlId='environmentSelect'
                            labelId='mps-navigation-environment-label'
                            value={this.props.environment}
                            options={environments}
                            onChange={this.handleEnvironmentChange}
                            defaultIndex={0}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LabeledInput
                            type='select'
                            controlId='providerSelect'
                            labelId='mps-navigation-provider-label'
                            value={this.props.provider}
                            options={providers}
                            onChange={this.handleProviderChange}
                            defaultIndex={0}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>{this.renderAddProviderModal()}</Col>
                    <Col>{this.renderDeleteProviderModal()}</Col>
                </Row>
                <hr />
                {this.renderPageButtons(environments?.length > 0)}
            </Container>
        );
    }
}

export default injectIntl(MpsNavigation);
