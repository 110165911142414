// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import Profile from './Profile';
import './ProfileList.css';

export default class ProfilesList extends Component {
    contains(value, searchFor) {
        const v = (value || '').toLowerCase();
        let v2 = searchFor;
        if (v2) {
            v2 = v2.toLowerCase();
        }
        return v.indexOf(v2) > -1;
    }

    getProfilesForFilter() {
        if (this.props.profilesFilter == null || this.props.profilesFilter === '') {
            return this.props.profiles;
        }

        const filteredProfiles = [];
        const profilesFilterTokens = this.props.profilesFilter.split(' ');
        this.props.profiles.forEach((profile) => {
            if (profilesFilterTokens.every((value) => this.contains(profile.profileName, value))) {
                filteredProfiles.push(profile);
            }
        });

        return filteredProfiles;
    }

    render() {
        const filteredProfiles = this.getProfilesForFilter();
        const profilesToDisplay = filteredProfiles.map((profile) => (
            <Profile
                profile={profile}
                onProfileSelected={this.props.onProfileSelected}
                onProfileDeleted={this.props.onProfileDeleted}
                onProfileNameChanged={this.props.onProfileNameChanged}
                key={profile.targetRefs[0].id}
            />
        ));
        return <div className='profiles-list'>{profilesToDisplay}</div>;
    }
}
