// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import ModalContainer from '../../ModalContainer';
import { Modal, Form, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './EditProfileModal.css';

export class EditProfileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            showAreYouSure: false
        };
    }

    handleChange = (e) => {
        this.setState({ name: e.target.value });
    };

    handleSubmit = () => {
        this.setState({ showAreYouSure: true });
    };

    handleClose = () => {
        this.props.handleClose();
    };

    handleDone = () => {
        this.props.handleDone(this.state.name);
    };

    handleAreYouSureClosed = () => {
        this.setState({ showAreYouSure: false });
    };

    handleAreYouSureSubmit = () => {
        this.setState({ showAreYouSure: false });
        this.props.handleDelete();
    };

    render() {
        const { intl } = this.props;
        return (
            <div>
                <Modal show={this.props.show && !this.state.showAreYouSure} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{intl.formatMessage({ id: 'edit-profile' })}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>{intl.formatMessage({ id: 'name' })}</Form.Label>
                            <Form.Control
                                type='text'
                                onChange={this.handleChange}
                                value={this.state.name}
                                aria-label={intl.formatMessage({ id: 'name' })}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-delete' variant='danger' onClick={this.handleSubmit}>
                            {intl.formatMessage({ id: 'remove-profile' })}
                        </Button>
                        <Button className='btn-done' variant='primary' onClick={this.handleDone}>
                            {intl.formatMessage({ id: 'done' })}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ModalContainer
                    title={intl.formatMessage({ id: 'remove-profile' })}
                    show={this.state.showAreYouSure}
                    handleClose={this.handleAreYouSureClosed}
                    handleSubmit={this.handleAreYouSureSubmit}
                >
                    <div className='remove-profile'>
                        <p>{intl.formatMessage({ id: 'delete-profile-message' }, { profileName: this.state.name })}</p>
                        <p>{this.state.name}</p>
                        <p>{intl.formatMessage({ id: 'are-you-sure' })}</p>
                    </div>
                </ModalContainer>
            </div>
        );
    }
}

export default injectIntl(EditProfileModal);
