// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Spinner } from 'react-bootstrap';
import './MicrosoftSpinner.css';

export class MicrosoftSpinner extends Component {
    render() {
        let className = this.props.className ?? 'd-inline-block m-4';

        return (
            <div className={className}>
                <Row>
                    <Col className='m-0 pr-0'>
                        <Spinner animation='grow' variant='danger' />
                    </Col>
                    <Col className='m-0 p-0'>
                        <Spinner animation='grow' variant='success' />
                    </Col>
                </Row>
                <Row>
                    <Col className='m-0 pr-0'>
                        <Spinner animation='grow' variant='primary' />
                    </Col>
                    <Col className='m-0 p-0'>
                        <Spinner animation='grow' variant='warning' />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(MicrosoftSpinner);
