import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import SortableTable from '../../controls/table/sortable-table/SortableTable';

export class SelectDraftList extends Component {
    handleOnRowClicked = (rowItem) => {
        const selectedDraft = this.props.draftList.find((draftItem) => draftItem.draftGuid === rowItem.key);
        return this.props.onDraftSelected(selectedDraft);
    };

    render() {
        const headerItems = [
            { columnName: this.props.intl.formatMessage({ id: 'name' }), fieldName: 'name', sortable: true },
            { columnName: this.props.intl.formatMessage({ id: 'type' }), fieldName: 'type', sortable: true },
            { columnName: this.props.intl.formatMessage({ id: 'last-updated' }), fieldName: 'lastUpdated', sortable: true }
        ];
        const bodyItems = this.props.draftList.map((draft) => {
            return {
                key: draft.draftGuid,
                name: draft.title,
                type: this.props.intl.formatMessage({ id: draft.type.toLowerCase() }),
                lastUpdated: !!draft.lastUpdated ? new Date(draft.lastUpdated).toLocaleDateString() : '',
                isSelected: draft.draftGuid === this.props.selectedDraft?.draftGuid
            };
        });
        return <SortableTable headerItems={headerItems} bodyItems={bodyItems} onRowClicked={this.handleOnRowClicked} />;
    }
}

export default injectIntl(SelectDraftList);
