// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ModalContainer from './ModalContainer';
import { injectIntl } from 'react-intl';

export class SaveDraftModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    handleChange = (evt) => {
        this.setState({ message: evt.target.value });
    };

    handleSubmit = () => {
        this.props.handleSubmit(this.state.message);
        this.setState({ message: '' });
    };

    render() {
        const { intl } = this.props;
        return (
            <ModalContainer
                title={intl.formatMessage({ id: 'save-draft' })}
                show={this.props.show}
                handleClose={this.props.handleClose}
                handleSubmit={this.handleSubmit}
                submissionButtonText={intl.formatMessage({ id: 'save' })}
            >
                <Form.Group>
                    <Form.Label>{intl.formatMessage({ id: 'message-for-return' })}</Form.Label>
                    <Form.Control
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.message}
                        aria-label={intl.formatMessage({ id: 'message-for-return' })}
                    />
                </Form.Group>
            </ModalContainer>
        );
    }
}

export default injectIntl(SaveDraftModal);
