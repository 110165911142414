// Copyright (C) Microsoft Corporation. All rights reserved.
export const CONFIGURATION_PAGE = 'Configuration';
export const GATEWAYS_PAGE = 'Gateways';
export const NOTIFICATIONS_PAGE = 'Notifications';
export const RESOURCES_PAGE = 'Resources';
export const DATA_PAGE = 'Data';

export const CHANNEL_IMEIS_FILE_NAME = 'ChannelImeis.csv';
export const SPONSOR_IMEIS_FILE_NAME = 'SponsorImeis.csv';
export const INSTALLEDPROFILE_IMEIS_FILE_NAME = 'InstalledProfileImeis.csv';

export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_DELETE = 'DELETE';

export const MAX_GATEWAYS = 16;
export const MAX_NOTIFICATIONS = 32;
