// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './Footer.css';

export class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <ul className='footer-ul'>
                    <li className='footer-item'>
                        <a href='https://www.microsoft.com/trademarks'>{this.props.intl.formatMessage({ id: 'trademarks' })}</a>
                    </li>
                    <li className='footer-item'>
                        <a href='http://go.microsoft.com/fwlink/?LinkId=521839'>
                            {this.props.intl.formatMessage({ id: 'privacy-statement' })}
                        </a>
                    </li>
                    <li className='footer-item'>
                        <a href='https://go.microsoft.com/fwlink/?LinkID=206977'>{this.props.intl.formatMessage({ id: 'terms' })}</a>
                    </li>
                    <li className='footer-item'>© Microsoft 2024</li>
                </ul>
            </div>
        );
    }
}

export default injectIntl(Footer);
