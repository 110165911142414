// Copyright (C) Microsoft Corporation. All rights reserved.
import { MicrosoftSpinner } from '../controls/animation/MicrosoftSpinner';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

export class LoadingSpinner extends Component {
    render() {
        return (
            <div className='row mt-5 text-center justify-content-center align-self-center'>
                <MicrosoftSpinner />
                <h2 className='d-inline-block text-center justify-content-center align-self-center'>
                    {this.props.intl.formatMessage({ id: this.props.loadingTextId })}
                </h2>
            </div>
        );
    }
}

export default injectIntl(LoadingSpinner);
