// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import {
    TREE,
    MAP,
    getSettingValuesForPath,
    buildSetting,
    generateNewSettingValue,
    createNewSettingValue
} from '../settingtypes/SettingFactory';
import { CreateNewGuid } from '../../../Utils';
import { Col, Container, Row } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './SettingsContainer.css';

export class SettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
    }

    buildSettingWithKey(setting, displaySettingName, settingValue, onChangeFunction, key) {
        return (
            <div key={this.props.selectedItemPath + '/' + key}>
                {buildSetting(setting, settingValue, onChangeFunction, this.props.onAssetsChange, displaySettingName)}
            </div>
        );
    }

    settingContainerView() {
        const childSettings = [];
        if (this.props.selectedGroupSetting != null) {
            for (let i = 0; i < this.props.selectedGroupSetting.Settings.length; i++) {
                const childSetting = this.props.selectedGroupSetting.Settings[i];
                if (childSetting.Type === TREE || childSetting.Type === MAP) {
                    continue;
                }

                const childSettingValues = this.props.selectedSettingValue == null ? null : this.props.selectedSettingValue.childSettings;
                const childSettingValue = getSettingValuesForPath(childSetting.Path, childSettingValues, true);

                const key = childSetting.Path;
                childSettings.push(this.buildSettingWithKey(childSetting, null, childSettingValue, this.handleGroupItemSelected, key));
            }
        } else if (this.props.selectedSetting.Type === TREE) {
            for (let i = 0; i < this.props.selectedSetting.ChildSettings.length; i++) {
                const childSetting = this.props.selectedSetting.ChildSettings[i];
                if (childSetting.Type === TREE || childSetting.Type === MAP) {
                    continue;
                }

                const childSettingsValues = this.props.selectedSettingValue == null ? null : this.props.selectedSettingValue.childSettings;
                const childSettingValue = getSettingValuesForPath(childSetting.Path, childSettingsValues, true);

                const key = this.props.itemPath + '/' + childSetting.Path;
                childSettings.push(this.buildSettingWithKey(childSetting, null, childSettingValue, this.handleTreeSettingChange, key));
            }
        } else if (this.props.selectedSetting.Type === MAP) {
            const settingValue = this.props.selectedSettingValue;
            for (let i = 0; i < this.props.selectedSetting.Validation.AllowedValues.length; i++) {
                const allowedValue = this.props.selectedSetting.Validation.AllowedValues[i];
                let childSettingValue = null;
                if (settingValue != null) {
                    for (let j = 0; j < settingValue.childSettings.length; j++) {
                        const childSetting = settingValue.childSettings[j];
                        for (let k = 0; k < childSetting.attributes.length; k++) {
                            const attirbute = childSetting.attributes[k];
                            if (attirbute.value === allowedValue.Value) {
                                childSettingValue = childSetting;
                            }
                        }

                        if (childSettingValue != null) {
                            break;
                        }
                    }
                }

                const displaySettingName = this.props.selectedSetting.MapKey + ' ' + allowedValue.Value;

                const key = this.props.itemPath + '/' + i;
                childSettings.push(
                    this.buildSettingWithKey(
                        this.props.selectedSetting.Validation.MapSettingValues[0],
                        displaySettingName,
                        childSettingValue,
                        this.handleMapSettingChange(allowedValue),
                        key
                    )
                );
            }
        }

        if (childSettings.length === 3) {
            // There are only 3 settings so it doesn't make much sense to build out the columns
            return <div>{childSettings}</div>;
        }

        /*
         * I tried using the CSS column-count property but doesn't work sometimes because
         * the setting form (like IntSetting) might span between columns (i.e. the name
         * of the setting is in one column and the text box in another column)
         *
         * To work around this, I manually build out the columns and use bootstrap columns
         * to distribute them
         */
        const remainder = childSettings.length % 3;
        const firstColSize = Math.floor(childSettings.length / 3) + (remainder >= 1 ? 1 : 0);
        const secondColSize = Math.floor(childSettings.length / 3) + (remainder >= 2 ? 1 : 0);
        const thirdColSize = Math.floor(childSettings.length) / 3;
        const firstCol = childSettings.splice(0, firstColSize);
        const secondCol = childSettings.splice(0, secondColSize);
        const thirdCol = childSettings.splice(-thirdColSize);

        return (
            <Container className='settings-container'>
                <Row>
                    <Col>{firstCol}</Col>
                    <Col>{secondCol}</Col>
                    <Col>{thirdCol}</Col>
                </Row>
            </Container>
        );
    }

    getContainerView() {
        if (this.props.selectedSetting == null && this.props.selectedGroupSetting == null) {
            return this.noSettingSelectedView();
        }

        return this.settingContainerView();
    }

    handleTreeSettingChange = (childSettingValue) => {
        const settingValue = generateNewSettingValue(this.props.selectedSetting, this.props.selectedSettingValue);

        if (settingValue.childSettings == null) {
            settingValue.childSettings = [];
        }

        let index = -1;
        for (let i = 0; i < settingValue.childSettings.length; i++) {
            if (settingValue.childSettings[i].path === childSettingValue.path) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            settingValue.childSettings.push(childSettingValue);
        } else {
            if (
                childSettingValue.value == null &&
                (childSettingValue.childSettings == null || childSettingValue.childSettings.length == 0)
            ) {
                settingValue.childSettings.splice(index, 1);
            } else {
                settingValue.childSettings[index] = childSettingValue;
            }
        }

        this.props.onSettingValueChanged(settingValue);
    };

    handleMapSettingChange = (allowedValue) => (newSettingValue) => {
        const settingValue = generateNewSettingValue(this.props.selectedSetting, this.props.selectedSettingValue);

        if (settingValue.childSettings == null) {
            settingValue.childSettings = [];
        }

        let index = -1;
        for (let i = 0; i < settingValue.childSettings.length; i++) {
            const childSettingValue = settingValue.childSettings[i];
            for (let j = 0; j < childSettingValue.attributes.length; j++) {
                const attribute = childSettingValue.attributes[j];
                if (attribute.value === allowedValue.Value) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                break;
            }
        }

        if (index === -1) {
            newSettingValue.attributes = [{ name: this.props.selectedSetting.MapKey, value: allowedValue.Value }];
            settingValue.childSettings.push(newSettingValue);
        } else {
            if (newSettingValue.value == null && (newSettingValue.childSettings == null || newSettingValue.childSettings.length === 0)) {
                settingValue.childSettings.splice(index, 1);
            } else {
                settingValue.childSettings[index] = newSettingValue;
            }
        }

        this.props.onSettingValueChanged(settingValue);
    };

    handleGroupItemSelected = (childSettingValue) => {
        const settingValue =
            this.props.selectedSettingValue == null
                ? createNewSettingValue(
                      this.props.selectedGroupSetting.Name,
                      this.props.selectedGroupSetting.Path,
                      null,
                      null,
                      null,
                      CreateNewGuid()
                  )
                : this.props.selectedSettingValue;

        if (settingValue.childSettings == null) {
            settingValue.childSettings = [];
        }

        let index = -1;
        for (let i = 0; i < settingValue.childSettings.length; i++) {
            if (childSettingValue.path == settingValue.childSettings[i].path) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            settingValue.childSettings.push(childSettingValue);
        } else {
            if (
                childSettingValue.value == null &&
                (childSettingValue.childSettings == null || childSettingValue.childSettings.length == 0)
            ) {
                settingValue.childSettings.splice(index, 1);
            } else {
                settingValue.childSettings[index] = childSettingValue;
            }
        }

        this.props.onSettingValueChanged(settingValue);
    };

    componentDidMount() {
        if (this.mainRef.current != null) {
            this.mainRef.current.focus();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedSetting == null && this.props.selectedGroupSetting == null) {
            return;
        }

        // Got a setting from having none, just focus
        if (prevProps.selectedSetting == null && prevProps.selectedGroupSetting == null) {
            this.mainRef.current.focus();
        }

        if (prevProps.selectedItemPath != this.props.selectedItemPath) {
            this.mainRef.current.focus();
        }
    }

    getContainerViewAriaName() {
        if (this.props.selectedSetting == null && this.props.selectedGroupSetting == null) {
            return this.props.intl.formatMessage({ id: 'no-setting-selected' });
        }

        const settingName =
            this.props.selectedSetting == null ? this.props.selectedGroupSetting.Name : this.props.selectedSetting.DisplayName;

        return this.props.intl.formatMessage({ id: 'setting-selected-container-aria' }, { settingName: settingName });
    }

    render() {
        return (
            <div className={this.props.className} ref={this.mainRef} tabIndex={0} aria-label={this.getContainerViewAriaName()}>
                {this.getContainerView()}
            </div>
        );
    }
}

export default injectIntl(SettingsContainer);
