// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './IntSetting.css';
import { generateNewSettingValue } from './SettingFactory';
import TextInput, { EMPTY_DROP_DOWN_VALUE, NULL_DROP_DOWN_VALUE } from '../../input/TextInput';

export default class IntSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: ''
        };
    }

    validate(newValue) {
        if (this.props.setting.Validation.AllowedValues == null) {
            // Only need to validate range values because the dropdown should have the allowed values
            if (this.props.setting.Validation.HasLowerBound && parseInt(newValue, 10) < this.props.setting.Validation.LowerBound) {
                this.setState({ errors: 'Value must be greater than ' + this.props.setting.Validation.LowerBound });
                return false;
            }
            if (this.props.setting.Validation.HasUpperBound && parseInt(newValue, 10) > this.props.setting.Validation.UpperBound) {
                this.setState({ errors: 'Value must be less than ' + this.props.setting.Validation.UpperBound });
                return false;
            }
        }

        this.setState({ errors: '' });
        return true;
    }

    handleOnChange = (evt) => {
        if (!this.validate(evt)) {
            return;
        }

        const settingValue = generateNewSettingValue(this.props.setting, this.props.settingValue);
        if (evt.length === 0 || evt === NULL_DROP_DOWN_VALUE) {
            evt = null;
        }
        settingValue.value = evt;
        this.props.onChange(settingValue);
    };

    getSettingValueOrDefault() {
        return this.props.settingValue?.value;
    }

    createAllowableValueDropDown() {
        const items = this.props.setting.Validation.AllowedValues.map(function (value) {
            return { key: value.Value, name: value.Name, value: value.Value };
        });
        items.unshift(EMPTY_DROP_DOWN_VALUE);

        return (
            <TextInput
                displayName={this.props.displayName}
                description={this.props.setting.Description}
                value={this.getSettingValueOrDefault()}
                handleChange={this.handleOnChange}
                dropDownItems={items}
            />
        );
    }

    createRangeValue() {
        let minValue = this.props.setting.Validation.HasLowerBound ? this.props.setting.Validation.LowerBound : null;
        let maxValue = this.props.setting.Validation.HasUpperBound ? this.props.setting.Validation.UpperBound : null;
        return (
            <TextInput
                displayName={this.props.displayName}
                description={this.props.setting.Description}
                value={this.getSettingValueOrDefault()}
                inputType='number'
                handleChange={this.handleOnChange}
                minValue={minValue}
                maxValue={maxValue}
            />
        );
    }

    createSettingUi() {
        if (this.props.setting.Validation.AllowedValues != null) {
            return this.createAllowableValueDropDown();
        } else {
            return this.createRangeValue();
        }
    }

    render() {
        const formControl = this.createSettingUi();
        return (
            <div class='int-setting'>
                <Form.Group>{formControl}</Form.Group>
            </div>
        );
    }
}
