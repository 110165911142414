// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { SettingNameDisplay } from '../cosa/SettingNameDisplay';

export const NULL_DROP_DOWN_VALUE = '!null!';
export const EMPTY_DROP_DOWN_VALUE = { key: NULL_DROP_DOWN_VALUE, name: '', value: NULL_DROP_DOWN_VALUE };

export class TextInput extends Component {
    handleChange = (evt) => {
        this.props.handleChange(evt.target.value);
    };

    createDropDownList() {
        return (
            <div>
                <SettingNameDisplay displayName={this.props.displayName} description={this.props.description} />
                <Form.Control
                    as='select'
                    value={this.props.value}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    aria-label={this.props.displayName}
                >
                    {this.props.dropDownItems.map((item) => (
                        <option
                            key={item.key}
                            value={item.value}
                            aria-label={
                                item.key === NULL_DROP_DOWN_VALUE
                                    ? this.props.intl.formatMessage({ id: 'unselected-aria-label' })
                                    : item.name
                            }
                        >
                            {item.name}
                        </option>
                    ))}
                </Form.Control>
            </div>
        );
    }

    createTextInput() {
        let inputType = this.props.inputType;
        if (inputType == null || inputType === '') {
            inputType = 'text';
        }
        return (
            <div>
                <SettingNameDisplay displayName={this.props.displayName} description={this.props.description} />
                <Form.Control
                    type={inputType}
                    value={this.props.value == null ? '' : this.props.value}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                    aria-label={this.props.displayName}
                    isInvalid={!!this.props.errors}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                />
                <Form.Control.Feedback type='invalid' aria-live='polite'>
                    {this.props.errors}
                </Form.Control.Feedback>
            </div>
        );
    }

    render() {
        let renderItem = null;
        if (this.props.dropDownItems != null) {
            renderItem = this.createDropDownList();
        } else {
            renderItem = this.createTextInput();
        }
        return <div>{renderItem}</div>;
    }
}

export default injectIntl(TextInput);
