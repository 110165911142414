import { Component } from 'react';
import { Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import CosaMpsFilterButtons, { FilterButtonState } from '../common/CosaMpsFilterButtons';
import { getFilteredDraftsOrSubmissionList } from '../common/DraftSubmissionUtils';
import SelectSubmissionList from './SelectSubmissionList';

export class SelectSubmissionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredText: '',
            filterButtonState: FilterButtonState.NO_BUTTON_SELECTED
        };
    }

    onFilterTextChange = (evt) => {
        const filteredText = evt.target.value;
        this.setState({ filteredText: filteredText });
    };

    onFilterButtonClicked = (newState) => {
        this.setState({ filterButtonState: newState });
    };

    getActiveSubmissions(filteredSubmissions) {
        const activeSubmissions = [];
        filteredSubmissions.forEach((submission) => {
            if (submission.isActive) {
                activeSubmissions.push(submission);
            }
        });

        return activeSubmissions;
    }

    getInactiveSubmissions(filteredSubmissions) {
        const inactiveSubmissions = [];
        filteredSubmissions.forEach((submission) => {
            if (!submission.isActive) {
                inactiveSubmissions.push(submission);
            }
        });

        return inactiveSubmissions;
    }

    render() {
        const filteredSubmissionList = getFilteredDraftsOrSubmissionList(
            this.props.submissionList,
            this.state.filteredText,
            this.state.filterButtonState
        );
        const activeSubmissions = this.getActiveSubmissions(filteredSubmissionList);
        const inactiveSubmissions = this.getInactiveSubmissions(filteredSubmissionList);

        return (
            <div>
                <Form.Group>
                    <Form.Control
                        type='text'
                        onChange={this.onFilterTextChange}
                        value={this.state.filteredText}
                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                    />
                </Form.Group>

                <CosaMpsFilterButtons onFilterButtonClicked={this.onFilterButtonClicked} />
                <Form.Label>{this.props.intl.formatMessage({ id: 'active-submissions' })}</Form.Label>
                <SelectSubmissionList
                    submissionList={activeSubmissions}
                    onSubmissionSelected={this.props.onSubmissionSelected}
                    selectedSubmission={this.props.selectedSubmission}
                />
                <Form.Label>{this.props.intl.formatMessage({ id: 'previous-submissions' })}</Form.Label>
                <SelectSubmissionList
                    submissionList={inactiveSubmissions}
                    onSubmissionSelected={this.props.onSubmissionSelected}
                    selectedSubmission={this.props.selectedSubmission}
                />
            </div>
        );
    }
}

export default injectIntl(SelectSubmissionContainer);
