// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { generateGuid } from '../../mps/MpsUtils';
import TooltipLabel from './TooltipLabel';

export class RestrictedTextInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            error: ''
        };
    }

    handleInputChange = (e) => {
        this.setState({
            value: e.target.value,
            error: ''
        });
    };

    handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitValue(e.target.value);
        }
    };

    submitValue = (value) => {
        if (!this.props.onChange) {
            return;
        }

        let validValue = this.getValidValue(value);
        if (!validValue) {
            let errorMsg = this.props.intl.formatMessage({ id: 'error-option-invalid' });
            this.setState({ error: errorMsg });
            return;
        }

        if (this.props.excludedOptions?.includes(validValue)) {
            let errorMsg = this.props.intl.formatMessage({ id: 'error-item-exists' });
            this.setState({ error: errorMsg });
            return;
        }

        this.props.onChange(validValue);
        this.setState({
            value: '',
            error: ''
        });
    };

    getValidValue = (value) => {
        if (!!this.props.options) {
            for (let opt of this.props.options) {
                if (opt.toUpperCase() === value.toUpperCase()) {
                    return opt;
                }
            }
        }

        return null;
    };

    render() {
        let { controlId, label, labelId, tooltip, tooltipId, placeholder, className, options } = this.props;

        let dataListId = !!controlId && controlId.length > 0 ? controlId + 'DataList' : 'dataList' + generateGuid();
        let emptyDataListId = 'emptyDataList';
        let showEmptyDataList = !this.state.value || !!this.state.error;

        let placeholderText = !!placeholder ? placeholder : this.props.intl.formatMessage({ id: 'restricted-input-placeholder' });
        let submitDisabled = !this.getValidValue(this.state.value);
        let submitVariant = submitDisabled ? 'secondary' : 'success';

        return (
            <Form.Group controlId={controlId} className={className}>
                <TooltipLabel label={label} labelId={labelId} tooltip={tooltip} tooltipId={tooltipId} />
                <InputGroup>
                    <InputGroup.Prepend>
                        <Button
                            variant={submitVariant}
                            className='d-inline-block'
                            aria-label={this.props.intl.formatMessage({ id: 'submit' })}
                            onClick={() => {
                                this.submitValue(this.state.value);
                            }}
                            disabled={submitDisabled}
                        >
                            <FontAwesomeIcon icon={faArrowRight} size='sm' />
                        </Button>
                    </InputGroup.Prepend>
                    <Form.Control
                        type='text'
                        aria-label={placeholderText}
                        value={this.state.value}
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleInputKeyPress}
                        isInvalid={!!this.state.error}
                        list={showEmptyDataList ? emptyDataListId : dataListId}
                        placeholder={placeholderText}
                        autoComplete='off'
                        maxLength='255'
                    />
                    <Form.Control.Feedback type='invalid' aria-live='polite'>
                        {this.state.error}
                    </Form.Control.Feedback>
                </InputGroup>

                <datalist id={dataListId}>
                    {options?.map((opt, index) => (
                        <option key={controlId + opt + index} value={opt} />
                    ))}
                </datalist>
                <datalist id={emptyDataListId} />
            </Form.Group>
        );
    }
}

export default injectIntl(RestrictedTextInput);
