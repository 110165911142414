export function stringContains(value, searchFor) {
    const v = (value || '').toLowerCase();
    let v2 = searchFor;
    if (v2) {
        v2 = v2.toLowerCase();
    }
    return v.indexOf(v2) > -1;
}

export function stringNullOrWhiteSpace(string) {
    return string === null || string === undefined || string.trim() === '';
}
