// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MpsConfigPage from '../pages/MpsConfigPage';
import MpsGatewaysPage from '../pages/MpsGatewaysPage';
import MpsNotificationsPage from '../pages/MpsNotificationsPage';
import MpsResourcesPage from '../pages/MpsResourcesPage';
import MpsDataPage from '../pages/MpsDataPage';
import * as MpsConstants from '../MpsConstants';

export class MpsDisplay extends Component {
    intl = this.props.intl;

    getAvailableProviderIds = () => {
        let { root } = this.props;
        let availableProviderIds = [];

        for (let env of root?.environments) {
            for (let provider of env?.providers) {
                let providerId = provider.configFile?.contents?.providerId;
                if (!!providerId) {
                    availableProviderIds.push(providerId);
                }
            }
        }

        return availableProviderIds;
    };

    getAvailableResourceFileNames = () => {
        let selectedProvider = this.getSelectedProvider();
        let resFileNames = selectedProvider?.resourceFiles?.map((resFile) => resFile.fileName);

        if (resFileNames == null || resFileNames == undefined) {
            resFileNames = [];
        }

        return resFileNames;
    };

    getSelectedProvider = () => {
        let { root, environment, provider } = this.props;

        let selectedEnv = root.environments?.find((item) => item.name === environment);
        if (!!selectedEnv) {
            let selectedProvider = selectedEnv.providers?.find((item) => item.name === provider);
            if (!!selectedProvider) {
                return selectedProvider;
            }
        }

        return null;
    };

    handleValueChange = (newValue, ...propNames) => {
        if (!!this.props.onChange) {
            let { environment, provider } = this.props;
            this.props.onChange(newValue, environment, provider, ...propNames);
        }
    };

    getActivePage = () => {
        let { allFiles, page } = this.props;
        let selectedProvider = this.getSelectedProvider();
        let availableResourceFileNames = this.getAvailableResourceFileNames();
        let availableProviderIds = this.getAvailableProviderIds();

        // Display "empty" page.
        if (!this.props.environment || !this.props.provider || !this.props.page) {
            return (
                <div>
                    <h4>{this.intl.formatMessage({ id: 'mps-empty-header' })}</h4>
                    <p className='ml-0'>{this.intl.formatMessage({ id: 'mps-empty-body' })}</p>
                    <a href='https://docs.microsoft.com/en-us/windows-hardware/drivers/mobilebroadband/mobile-plans'>
                        {this.intl.formatMessage({ id: 'mps-empty-link' })}
                    </a>
                </div>
            );
        }

        if (page === MpsConstants.CONFIGURATION_PAGE) {
            return (
                <MpsConfigPage
                    availableProviderIds={availableProviderIds}
                    availableResourceFileNames={availableResourceFileNames}
                    constants={this.props.root?.constants}
                    config={selectedProvider?.configFile?.contents}
                    env={this.props.environment}
                    onChange={this.handleValueChange}
                />
            );
        } else if (page === MpsConstants.GATEWAYS_PAGE) {
            return (
                <MpsGatewaysPage
                    allFiles={allFiles}
                    availableResourceFileNames={availableResourceFileNames}
                    constants={this.props.root?.constants}
                    config={selectedProvider?.configFile?.contents}
                    gatewayFiles={selectedProvider?.gatewayFiles}
                    gatewayPathPrefix={selectedProvider?.gatewayPathPrefix}
                    onChange={this.handleValueChange}
                />
            );
        } else if (page === MpsConstants.NOTIFICATIONS_PAGE) {
            return (
                <MpsNotificationsPage
                    allFiles={allFiles}
                    availableResourceFileNames={availableResourceFileNames}
                    constants={this.props.root?.constants}
                    config={selectedProvider?.configFile?.contents}
                    env={this.props.environment}
                    notificationFiles={selectedProvider?.notificationFiles}
                    notificationPathPrefix={selectedProvider?.notificationPathPrefix}
                    onChange={this.handleValueChange}
                />
            );
        } else if (page === MpsConstants.RESOURCES_PAGE) {
            return (
                <MpsResourcesPage
                    draft={this.props.draft}
                    allFiles={allFiles}
                    isUserDraft={this.props.root?.isUserDraft}
                    pushToast={this.props.pushToast}
                    resourceFiles={selectedProvider?.resourceFiles}
                    resourcePathPrefix={selectedProvider?.resourcePathPrefix}
                    onChange={this.handleValueChange}
                />
            );
        } else if (page === MpsConstants.DATA_PAGE) {
            return (
                <MpsDataPage
                    allFiles={allFiles}
                    dataFiles={selectedProvider?.dataFiles}
                    dataPathPrefix={selectedProvider?.dataPathPrefix}
                    onChange={this.handleValueChange}
                />
            );
        }
    };

    getHeader = () => {
        if (!this.props.environment || !this.props.provider || !this.props.page) {
            return null;
        }

        let env = this.intl.formatMessage({ id: `mps-option-${this.props.environment?.toLowerCase()}` });
        let page = this.intl.formatMessage({ id: `mps-option-${this.props.page?.toLowerCase()}` });

        return <h2 className='mt-3'>{`${env} / ${this.props.provider} / ${page}`}</h2>;
    };

    render() {
        return (
            <div>
                {this.getHeader()}
                <hr />
                {this.getActivePage()}
            </div>
        );
    }
}

export default injectIntl(MpsDisplay);
