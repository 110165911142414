// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import Profile from '../profiles/Profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingTreeNavigation from './SettingTreeNavigation';
import './SettingTreeNavigationContainer.css';
import { injectIntl } from 'react-intl';

export class SettingTreeNavigationContainer extends Component {
    handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onBackButtonPressed();
        }
    };

    render() {
        const { intl } = this.props;
        return (
            <div className='setting-tree-navigation-container'>
                <div
                    className='profile-name-header'
                    onClick={this.props.onBackButtonPressed}
                    onKeyPress={this.handleOnKeyPress}
                    tabIndex={0}
                    role='button'
                >
                    <FontAwesomeIcon icon='angle-left' />
                    <p>{intl.formatMessage({ id: 'select-another-profile' })}</p>
                </div>
                <Profile
                    className='profile-name-header-profile'
                    profile={this.props.profile.profile}
                    onProfileDeleted={this.props.onProfileDeleted}
                    onProfileNameChanged={this.props.onProfileNameChanged}
                />
                <SettingTreeNavigation
                    schema={this.props.schema}
                    profileSettings={this.props.profile.profile.settings}
                    target={this.props.profile.target}
                    onChildItemSelected={this.props.onChildItemSelected}
                    onNavigationItemAdded={this.props.onNavigationItemAdded}
                    onNavigationItemDeleted={this.props.onNavigationItemDeleted}
                    onGroupItemSelected={this.props.onGroupItemSelected}
                    selectedItemPath={this.props.selectedItemPath}
                    targetItemSelected={this.props.targetItemSelected}
                    onTargetItemAdded={this.props.onTargetItemAdded}
                    onTargetItemDeleted={this.props.onTargetItemDeleted}
                />
            </div>
        );
    }
}

export default injectIntl(SettingTreeNavigationContainer);
