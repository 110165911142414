import { Component } from 'react';
import { Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import ModalContainer from '../../ModalContainer';
import { deepClone } from '../../mps/MpsUtils';

export class NewDraftModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            draftDetails: {
                draftTitle: '',
                type: 'COSA',
                description: '',
                background: ''
            },
            validation: {
                draftValid: false
            }
        };
    }

    handleTextChange = (propName) => (evt) => {
        this.updateDraftDetails(propName, evt.target.value);
    };

    handleSubmit = () => {
        const draftDetails = deepClone(this.state.draftDetails);
        this.props.handleSubmit(draftDetails);
    };

    handleButtonClick = (type) => () => {
        this.updateDraftDetails('type', type);
    };

    updateDraftDetails(propName, value) {
        const newDraftDetails = deepClone(this.state.draftDetails);
        newDraftDetails[propName] = value;
        this.setState({ draftDetails: newDraftDetails });
        this.validateDraft(newDraftDetails);
    }

    validateDraft(draftDetails) {
        let isDraftValid = true;
        isDraftValid = isDraftValid && !!draftDetails.draftTitle;
        isDraftValid = isDraftValid && !!draftDetails.description;

        this.setState({ validation: { draftValid: isDraftValid } });
    }

    render() {
        const draftDetails = this.state.draftDetails;
        const validation = this.state.validation;
        return (
            <ModalContainer
                title={this.props.intl.formatMessage({ id: 'new-draft' })}
                show={this.props.show}
                handleClose={this.props.handleClose}
                handleSubmit={this.handleSubmit}
                submitButtonDisabled={!validation.draftValid}
            >
                <Form.Group>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'title' })}</Form.Label>
                    <Form.Control type='text' onChange={this.handleTextChange('draftTitle')} value={draftDetails.draftTitle} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'type' })}</Form.Label>
                    <div>
                        <Form.Check
                            label={this.props.intl.formatMessage({ id: 'cosa' })}
                            checked={draftDetails.type === 'COSA'}
                            onChange={this.handleButtonClick('COSA')}
                        />
                        <Form.Check
                            label={this.props.intl.formatMessage({ id: 'mps' })}
                            checked={draftDetails.type === 'MPS'}
                            onChange={this.handleButtonClick('MPS')}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'description' })}</Form.Label>
                    <Form.Text>{this.props.intl.formatMessage({ id: 'draft-description-info' })}</Form.Text>
                    <Form.Control type='text' onChange={this.handleTextChange('description')} value={draftDetails.description} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.intl.formatMessage({ id: 'background' })}</Form.Label>
                    <Form.Text>{this.props.intl.formatMessage({ id: 'draft-background-info' })}</Form.Text>
                    <Form.Control type='text' onChange={this.handleTextChange('background')} value={draftDetails.background} />
                </Form.Group>
            </ModalContainer>
        );
    }
}

export default injectIntl(NewDraftModal);
