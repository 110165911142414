import { Component } from 'react';
import DraftSubmissionColumn, { ColumnSelection } from './draft-submission-column/DraftSubmissionColumn';
import DraftsContainer from './drafts/drafts-container/DraftsContainer';
import SubmissionsContainer from './submissions/SubmissionsContainer';
import { Col, Row } from 'react-bootstrap';
import './PortalLanding.css';

export default class PortalLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPage: ColumnSelection.DRAFTS
        };
    }

    handlePageClick = (newSelectedPage) => {
        this.setState({ selectedPage: newSelectedPage });
    };

    getSelectedPage() {
        if (this.state.selectedPage === ColumnSelection.DRAFTS) {
            return (
                <DraftsContainer
                    openCosaConfig={this.props.openCosaConfig}
                    openMpsConfig={this.props.openMpsConfig}
                    pushErrorToast={this.props.pushErrorToast}
                    pushSuccessToast={this.props.pushSuccessToast}
                />
            );
        } else if (this.state.selectedPage === ColumnSelection.SUBMISSIONS) {
            return (
                <SubmissionsContainer
                    openCosaConfig={this.props.openCosaConfig}
                    openMpsConfig={this.props.openMpsConfig}
                    pushErrorToast={this.props.pushErrorToast}
                    pushSuccessToast={this.props.pushSuccessToast}
                />
            );
        }
    }

    render() {
        return (
            <div>
                <Row className='padding-bottom'>
                    <Col lg={2} className='justify-content-center draft-submission-col'>
                        <DraftSubmissionColumn handlePageClick={this.handlePageClick} selectedPage={this.state.selectedPage} />
                    </Col>
                    <Col lg={10}>{this.getSelectedPage()}</Col>
                </Row>
            </div>
        );
    }
}
