// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DescriptionToolTip from '../DescriptionToolTip';
import './SettingNameDisplay.css';

export class SettingNameDisplay extends Component {
    render() {
        return (
            <div className='setting-name-display'>
                <div>
                    <Form.Label className='setting-name'>{this.props.displayName}</Form.Label>
                </div>
                {this.props.description != null && <DescriptionToolTip description={this.props.description} />}
            </div>
        );
    }
}
