import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import SortableTable from '../../controls/table/sortable-table/SortableTable';

export const ACCEPTED = 'Accepted';
export const PENDING = 'Pending';
export const ACTION_NEEDED = 'ActionNeeded';
export const CANCELLED = 'Cancelled';

export const COSA = 'Cosa';
export const MPS = 'Mps';

export class SelectSubmissionList extends Component {
    onRowClicked = (item) => {
        const selectedSubmission = this.props.submissionList.find((submission) => submission.pullRequestId === item.key);
        this.props.onSubmissionSelected(selectedSubmission);
    };

    getStatus(submission) {
        const { intl } = this.props;

        switch (submission.status) {
            case ACCEPTED:
                return intl.formatMessage({ id: 'accepted' });
            case PENDING:
                return intl.formatMessage({ id: 'pending' });
            case ACTION_NEEDED:
                return intl.formatMessage({ id: 'action_needed' });
            case CANCELLED:
                return intl.formatMessage({ id: 'cancelled' });
            default:
                return intl.formatMessage({ id: 'unknown' });
        }
    }

    getType(submission) {
        const { intl } = this.props;
        switch (submission.type) {
            case COSA:
                return intl.formatMessage({ id: 'cosa' });
            case MPS:
                return intl.formatMessage({ id: 'mps' });
            default:
                return intl.formatMessage({ id: 'unknown' });
        }
    }

    render() {
        const headerItems = [
            { columnName: <FontAwesomeIcon icon={faExclamationTriangle} />, fieldName: 'needsAttention' },
            { columnName: this.props.intl.formatMessage({ id: 'name' }), fieldName: 'name', sortable: true },
            { columnName: this.props.intl.formatMessage({ id: 'type' }), fieldName: 'type', sortable: true },
            { columnName: this.props.intl.formatMessage({ id: 'status' }), fieldName: 'status', sortable: true },
            { columnName: this.props.intl.formatMessage({ id: 'last-updated' }), fieldName: 'date', sortable: true }
        ];
        const bodyItems = this.props.submissionList.map((submission) => {
            return {
                key: submission.pullRequestId,
                name: submission.title,
                type: this.getType(submission),
                status: this.getStatus(submission),
                date: !!submission.lastUpdated ? new Date(submission.lastUpdated).toLocaleDateString() : '',
                isSelected: submission.pullRequestId === this.props.selectedSubmission?.pullRequestId,
                needsAttention:
                    submission.status === ACTION_NEEDED ? (
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#FFBF00' }} />
                    ) : null
            };
        });

        return <SortableTable headerItems={headerItems} bodyItems={bodyItems} onRowClicked={this.onRowClicked} />;
    }
}

export default injectIntl(SelectSubmissionList);
