// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import API from '../../API';
import { aquireTokenSilentOrPopup } from '../../auth/MsalUtils';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            errorInfo: '',
            hasError: false
        };
    }

    static getDerivedStateFromError = (error) => {
        return {
            hasError: true,
            error: error
        };
    };

    componentDidCatch = (error, errorInfo) => {
        console.log({ error, errorInfo });
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        });

        aquireTokenSilentOrPopup((tokenResponse) => {
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken
                }
            };

            let body = {
                error: error.message,
                errorInfo: errorInfo?.componentStack ?? null
            };

            API.post('/telemetry/client/error', body, headers).catch((error) => {
                console.log(error);
            });
        });
    };

    render() {
        let msg = this.props.intl.formatMessage({ id: 'something-went-wrong' });
        if (!!this.state.hasError) {
            return <h1>{msg}</h1>;
        }

        return this.props.children;
    }
}

export default injectIntl(ErrorBoundary);
