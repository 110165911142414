// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcons from '@fortawesome/free-solid-svg-icons';
import { generateGuid } from '../../mps/MpsUtils';
import './ToastQueue.css';

export class SimpleToast extends Component {
    constructor(props) {
        super(props);

        if (!!this.props.setPushToastHandler) {
            this.props.setPushToastHandler(this.pushToast);
        }

        this.state = {
            queue: []
        };
    }

    getToastHeader = (toast) => {
        let titleText = null;
        let variant = toast.variant ?? 'primary';

        if (!!toast.title) {
            titleText = toast.title;
        } else if (!!toast.titleId) {
            titleText = this.props.intl.formatMessage({ id: toast.titleId });
        } else if (!!variant) {
            titleText = this.props.intl.formatMessage({ id: `toast-title-${variant}` });
        }

        if (!!titleText) {
            let icon = FaIcons.faBell;
            let bgClass = `bg-${variant}`;
            let textClass = 'text-white';
            let closeClass = 'toast-close-white';

            switch (variant?.trim().toLowerCase()) {
                case 'primary':
                    break;
                case 'secondary':
                    break;
                case 'success':
                    icon = FaIcons.faCheckCircle;
                    break;
                case 'warning':
                    icon = FaIcons.faExclamationCircle;
                    break;
                case 'danger':
                    icon = FaIcons.faTimesCircle;
                    break;
                case 'info':
                    icon = FaIcons.faInfoCircle;
                    break;
                case 'light':
                    textClass = 'text-dark';
                    closeClass = 'toast-close-black';
                    break;
                case 'dark':
                    break;
                default:
                    return null;
            }

            return (
                <Toast.Header className={`${bgClass} ${textClass} ${closeClass}`}>
                    <FontAwesomeIcon icon={icon} size='lg' className='mr-2' />
                    <strong className='mr-auto'>{titleText}</strong>
                </Toast.Header>
            );
        }

        return null;
    };

    getToast = (toast) => {
        let bodyText = null;
        if (!!toast.body) {
            bodyText = toast.body;
        } else if (!!toast.bodyId) {
            bodyText = this.props.intl.formatMessage({ id: toast.bodyId });
        }

        if (!!bodyText) {
            let delayProps = {};
            if (toast.variant !== 'danger') {
                delayProps = {
                    delay: 5000,
                    autohide: true
                };
            }

            return (
                <Toast
                    className='mb-4'
                    animation
                    {...delayProps}
                    key={toast.id}
                    onClose={() => {
                        this.popToast(toast.id);
                    }}
                >
                    {this.getToastHeader(toast)}
                    <Toast.Body>{bodyText}</Toast.Body>
                </Toast>
            );
        }

        return null;
    };

    pushToast = (newToast) => {
        newToast.id = generateGuid();
        this.setState({ queue: [...this.state.queue, newToast] });
    };

    popToast = (id) => {
        if (this.state.queue.length > 0) {
            this.setState({ queue: this.state.queue.filter((toast) => toast.id !== id) });
        }
    };

    render() {
        return (
            <div className='toast-queue-container position-absolute d-flex flex-column align-items-end'>
                <div className='toast-queue-stack mt-4'>{this.state.queue.map((toast) => this.getToast(toast))}</div>
            </div>
        );
    }
}

export default injectIntl(SimpleToast);
