// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import LabeledInput from '../../controls/form/LabeledInput';
import CollapsableContainer from '../../controls/container/CollapsableContainer';
import MultiSelect from '../../controls/form/MultiSelect';
import SimpleModal from '../../controls/modal/SimpleModal';
import { deepClone, generateGuid } from '../MpsUtils';
import * as MpsConstants from '../MpsConstants';
import './MpsPromoNotification.css';

export class MpsPromoNotification extends Component {
    constructor(props) {
        super(props);

        this.lastNonEmptyValue = this.props.value ?? {};
    }

    handleIsEnabledChange = (newValue) => {
        if (!!this.props.onChange) {
            if (!!newValue) {
                var newValue = this.lastNonEmptyValue[this.props.instanceId] ?? this.getEmptyPromoNotification();
                this.props.onChange(newValue);
            } else {
                this.lastNonEmptyValue[this.props.instanceId] = this.props.value;
                this.props.onChange(null);
            }
        }
    };

    getEmptyPromoNotification() {
        var emptyPromoNotification = deepClone(this.props.constants?.emptyPromoNotification);
        emptyPromoNotification.campaignId = generateGuid();

        return emptyPromoNotification;
    }

    handleValueChange = (newValue, ...propNames) => {
        if (!!this.props.onChange) {
            this.props.onChange(newValue, ...propNames);
        }
    };

    render() {
        let { value, availableIds, controlId, label, labelId, tooltip, tooltipId } = this.props;
        let isEnabled = !!value;

        return (
            <CollapsableContainer
                label={label}
                labelId={labelId}
                tooltip={tooltip}
                tooltipId={tooltipId}
                labelSuffixId={isEnabled ? 'mps-notification-enabled-suffix' : 'mps-notification-disabled-suffix'}
            >
                <Row>
                    <Col>
                        <LabeledInput
                            type='checkbox'
                            controlId={controlId + 'IsEnabled'}
                            labelId='mps-notification-isenabled-label'
                            tooltipId='mps-notification-isenabled-tooltip'
                            value={isEnabled}
                            onChange={this.handleIsEnabledChange}
                        />
                    </Col>
                    <Col>{/* BLANK */}</Col>
                </Row>
                {isEnabled ? (
                    <div>
                        <Row>
                            <Col md={6}>
                                <LabeledInput
                                    type='select'
                                    controlId={controlId + 'NotificationId'}
                                    labelId='mps-notification-notificationid-label'
                                    tooltipId='mps-notification-notificationid-tooltip'
                                    value={value?.notificationId}
                                    options={[null, ...availableIds]}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'notificationId');
                                    }}
                                />
                            </Col>
                            <Col md={6}>
                                <LabeledInput
                                    type='text'
                                    controlId={controlId + 'CampaignId'}
                                    labelId='mps-notification-campaignid-label'
                                    tooltipId='mps-notification-campaignid-tooltip'
                                    value={value?.campaignId}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'campaignId');
                                    }}
                                    defaultValue={generateGuid()}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <LabeledInput
                                    type='datetime-local'
                                    controlId={controlId + 'StartDateTimeIso'}
                                    labelId='mps-notification-startdatetimeiso-label'
                                    tooltipId='mps-notification-startdatetimeiso-tooltip'
                                    value={value?.startDateTimeIso}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'startDateTimeIso');
                                    }}
                                />
                            </Col>
                            <Col md={6}>
                                <LabeledInput
                                    type='datetime-local'
                                    controlId={controlId + 'EndDateTimeIso'}
                                    labelId='mps-notification-enddatetimeiso-label'
                                    tooltipId='mps-notification-enddatetimeiso-tooltip'
                                    value={value?.endDateTimeIso}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'endDateTimeIso');
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <SimpleModal
                                    labelId='mps-notification-countryisos-label'
                                    tooltipId='mps-notification-countryisos-tooltip'
                                    count={value?.countryIsos?.length}
                                    highContrast
                                >
                                    <MultiSelect
                                        controlId={controlId + 'CountryIsos'}
                                        labelId='mps-notification-countryisos-label'
                                        tooltipId='mps-notification-countryisos-tooltip'
                                        items={value?.countryIsos}
                                        options={this.props.constants?.countryIsos}
                                        onChange={(newValue) => {
                                            this.handleValueChange(newValue, 'countryIsos');
                                        }}
                                        restricted
                                        unique
                                    />
                                </SimpleModal>
                            </Col>
                            <Col md={6}>
                                <LabeledInput
                                    type='number'
                                    controlId={controlId + 'MaxAttempts'}
                                    labelId='mps-notification-maxattempts-label'
                                    tooltipId='mps-notification-maxattempts-tooltip'
                                    min='0'
                                    max='999'
                                    value={value?.maxAttempts}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'maxAttempts');
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <LabeledInput
                                    type='checkbox'
                                    controlId={controlId + 'PermanentDismiss'}
                                    labelId='mps-notification-permanentdismiss-label'
                                    tooltipId='mps-notification-permanentdismiss-tooltip'
                                    value={value?.permanentDismiss}
                                    onChange={(newValue) => {
                                        this.handleValueChange(newValue, 'permanentDismiss');
                                    }}
                                    defaultValue={true}
                                />
                            </Col>
                            <Col md={6}>{/* BLANK */}</Col>
                        </Row>
                    </div>
                ) : null}
                <Row>
                    <Col md={6}>{this.props.bonusContent}</Col>
                    <Col md={6}>{/* BLANK */}</Col>
                </Row>
            </CollapsableContainer>
        );
    }
}

export default injectIntl(MpsPromoNotification);
