// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import './TooltipLabel.css';
import { generateGuid } from '../../mps/MpsUtils';
import DescriptionToolTip from '../../DescriptionToolTip';

export class TooltipLabel extends Component {
    getIcon = () => {
        if (!!this.props.icon) {
            let iconSize = this.props.iconSize ?? 'sm';

            return <FontAwesomeIcon className='form-label-icon mr-2' icon={this.props.icon} size={iconSize} focusable={true} />;
        }

        return null;
    };

    getLabel = () => {
        let labelText = '';
        let suffixText = '';

        if (!!this.props.label) {
            labelText = this.props.label;
        } else if (!!this.props.labelId) {
            labelText = this.props.intl.formatMessage({ id: this.props.labelId });
        }

        if (!!this.props.labelSuffix) {
            suffixText = this.props.labelSuffix;
        } else if (!!this.props.labelSuffixId) {
            suffixText = this.props.intl.formatMessage({ id: this.props.labelSuffixId });
        }

        let fullText = labelText + suffixText;
        let labelClassName = 'form-label mr-2 ' + (this.props.labelClassName ?? '');
        if (!!labelText) {
            return (
                <Form.Label className={labelClassName} title={fullText}>
                    {fullText}
                </Form.Label>
            );
        }

        return null;
    };

    getOverlay = () => {
        let labelText = '';
        let tooltipText = '';
        let tooltipId = `tooltip-${generateGuid()}`;

        if (!!this.props.label) {
            labelText = this.props.label;
        } else if (!!this.props.labelId) {
            labelText = this.props.intl.formatMessage({ id: this.props.labelId });
        }

        if (!!this.props.tooltip) {
            tooltipText = this.props.tooltip;
        } else if (!!this.props.tooltipId) {
            tooltipText = this.props.intl.formatMessage({ id: this.props.tooltipId });
        }

        if (!!tooltipText) {
            return <DescriptionToolTip
                className='form-label-tooltip-icon'
                descriptionPrefix={labelText}
                description={tooltipText}
                tooltipId={tooltipId}
            />;
        }

        return null;
    };

    render() {
        let icon = this.getIcon();
        let label = this.getLabel();
        let overlay = this.getOverlay();

        if (!label) {
            return null;
        }

        return (
            <div className={this.props.rootClassName ?? 'mb-2'}>
                {icon}
                {label}
                {overlay}
            </div>
        );
    }
}

export default injectIntl(TooltipLabel);
