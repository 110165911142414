// Copyright (C) Microsoft Corporation. All rights reserved.
import { isUserSignedIn } from '../auth/MsalUtils';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

const initialState = { hasLoggedInUser: isUserSignedIn() };

export const updateLoginState = () => ({ type: LOGIN });
export const updateLogoutState = () => ({ type: LOGOUT });

export const loginReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                hasLoggedInUser: true
            };
        case LOGOUT:
            return {
                hasLoggedInUser: false
            };
        default:
            return state;
    }
};
