// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { SettingNameDisplay } from '../cosa/SettingNameDisplay';

export class RangeInput extends Component {
    handleOnChange = (isFirst) => (evt) => {
        let first = this.props.first;
        let second = this.props.second;

        if (isFirst) {
            first = evt.target.value;
        } else {
            second = evt.target.value;
        }

        return this.props.handleOnChange({ first: first, second: second });
    };

    render() {
        return (
            <div>
                <SettingNameDisplay displayName={this.props.displayName} description={this.props.description} />
                <Form.Control
                    type='text'
                    value={this.props.first}
                    onChange={this.handleOnChange(true)}
                    disabled={this.props.disabled}
                    isInvalid={!!this.props.errors}
                    aria-label={this.props.firstAriaLabel}
                />
                <Form.Control
                    type='text'
                    value={this.props.second}
                    onChange={this.handleOnChange(false)}
                    disabled={this.props.disabled}
                    isInvalid={!!this.props.errors}
                    aria-label={this.props.secondAriaLabel}
                />
                <Form.Control.Feedback type='invalid' aria-live='polite'>
                    {this.props.errors}
                </Form.Control.Feedback>
            </div>
        );
    }
}
