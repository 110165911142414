// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class OkCancelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    handleShow = () => {
        this.setState({ show: true });
        if (this.props.onShow) {
            this.props.onShow();
        }
    };

    handleSuccess = () => {
        if (!!this.props.onSuccess) {
            let closeNow = this.props.onSuccess();
            if (closeNow) {
                this.setState({ show: false });
            }
        }
    };

    handleClose = () => {
        this.setState({ show: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    getModalHeader = () => {
        let titleText = null;
        if (!!this.props.title) {
            titleText = this.props.title;
        } else if (!!this.props.titleId) {
            titleText = this.props.intl.formatMessage({ id: this.props.titleId });
        }

        if (!!titleText) {
            return (
                <Modal.Header closeButton>
                    <Modal.Title>{titleText}</Modal.Title>
                </Modal.Header>
            );
        }

        return null;
    };

    getButtonChildren = () => {
        let buttonText = null;
        if (!!this.props.buttonText) {
            buttonText = this.props.buttonText;
        } else if (!!this.props.buttonTextId) {
            buttonText = this.props.intl.formatMessage({ id: this.props.buttonTextId });
        }

        let icon = !!this.props.icon ? <FontAwesomeIcon className='mr-1' icon={this.props.icon} size='sm' /> : null;
        if (!!buttonText) {
            return (
                <div>
                    {icon}
                    {buttonText}
                </div>
            );
        }

        return null;
    };

    render() {
        let { buttonVariant, buttonDisabled, fullWidthButton } = this.props;

        return (
            <Form.Group>
                <Button
                    className={!!fullWidthButton ? 'w-100' : null}
                    variant={buttonVariant}
                    onClick={this.handleShow}
                    disabled={buttonDisabled}
                >
                    {this.getButtonChildren()}
                </Button>

                <Modal size='lg' centered scrollable show={this.state.show} onHide={this.handleClose}>
                    {this.getModalHeader()}
                    <Modal.Body>{this.state.show ? this.props.children : null}</Modal.Body>
                    <Modal.Footer>
                        <Button variant={buttonVariant} onClick={this.handleSuccess}>
                            {!!this.props.reuseButton ? this.getButtonChildren() : this.props.intl.formatMessage({ id: 'ok' })}
                        </Button>
                        <Button variant='secondary' onClick={this.handleClose}>
                            {this.props.intl.formatMessage({ id: 'cancel' })}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form.Group>
        );
    }
}

export default injectIntl(OkCancelModal);
