// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SettingTreeName.css';
import { injectIntl } from 'react-intl';

export class SettingTreeName extends Component {
    handleOnClick = () => {
        if (this.props.onItemSelected != null) {
            this.props.onItemSelected();
        }
    };

    getIconName() {
        return this.props.isExpanded ? 'angle-up' : 'angle-down';
    }

    onIconClick = (e) => {
        e.stopPropagation();
        this.props.onExpand();
    };

    onDeleteClicked = (e) => {
        e.stopPropagation();
        this.props.onDeleteClicked();
    };

    onAddClick = (e) => {
        e.stopPropagation();
        this.props.onAddClicked();
    };

    getIconDiv() {
        return (
            <div
                className='setting-tree-name-buttons'
                role='button'
                tabIndex={0}
                aria-label={this.props.settingName}
                aria-expanded={this.props.isExpanded}
                onClick={this.onIconClick}
                onKeyPress={(e) => this.onEnterClickedListener(e, this.props.onExpand)}
            >
                <FontAwesomeIcon icon={this.getIconName()} size='lg' />
            </div>
        );
    }

    getDeleteIconDiv() {
        return (
            <div
                className='setting-tree-name-buttons'
                role='button'
                onClick={this.onDeleteClicked}
                onKeyPress={(e) => this.onEnterClickedListener(e, this.props.onDeleteClicked)}
                tabIndex={0}
                aria-label={this.props.intl.formatMessage({ id: 'delete-item-button-aria-label' }, { text: this.props.settingName })}
            >
                <FontAwesomeIcon icon='minus-circle' size='lg' />
            </div>
        );
    }

    onEnterClickedListener = (e, callBack) => {
        if (e.key === 'Enter') {
            callBack();
        }
    };

    getAddIconDiv() {
        return (
            <div
                className='setting-tree-name-buttons'
                role='button'
                onClick={this.onAddClick}
                onKeyPress={(e) => this.onEnterClickedListener(e, this.props.onAddClicked)}
                tabIndex={0}
                aria-label={this.props.intl.formatMessage({ id: 'add-item-button-aria-label' }, { text: this.props.addChildAriaLabel })}
            >
                <FontAwesomeIcon icon='plus-square' size='lg' />
            </div>
        );
    }

    getSettingName() {
        if (this.props.settingId) {
            return (
                <p>
                    {this.props.settingName} - <b>{this.props.settingId}</b>
                </p>
            );
        }

        return <p>{this.props.settingName}</p>;
    }

    render() {
        let className = 'setting-tree-name-container';
        if (this.props.isSelected) {
            className += ' selected';
        }

        return (
            <div className={className}>
                <div
                    className='setting-tree-name-text'
                    onClick={this.handleOnClick}
                    onKeyPress={(e) => this.onEnterClickedListener(e, this.handleOnClick)}
                    tabIndex={0}
                    role='button'
                    aria-label={this.props.intl.formatMessage({ id: 'select-item-button-aria-label' }, { text: this.props.settingName })}
                    ref={this.props.inputRef}
                >
                    {this.getSettingName()}
                </div>
                <div style={{ display: 'flex' }}>
                    {this.props.canAddSetting && this.getAddIconDiv()}
                    {this.props.canBeDeleted && this.getDeleteIconDiv()}
                    {this.props.isTreeItem && this.getIconDiv()}
                </div>
            </div>
        );
    }
}

export default injectIntl(SettingTreeName);
