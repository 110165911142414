// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Col, Row } from 'react-bootstrap';
import LabeledInput from '../../controls/form/LabeledInput';
import SimpleModal from '../../controls/modal/SimpleModal';
import SingleTextList from '../../controls/list/SingleTextList';
import DoubleTextList from '../../controls/list/DoubleTextList';
import MultiSelect from '../../controls/form/MultiSelect';
import CollapsableContainer from '../../controls/container/CollapsableContainer';
import * as MpsConstants from '../MpsConstants';
import { deepClone } from '../MpsUtils';

export class MpsConfigPage extends Component {
    intl = this.props.intl;

    handleValueChange = (newValue, ...propNames) => {
        if (!!this.props.onChange) {
            this.props.onChange(newValue, 'configFile', 'contents', ...propNames);
        }
    };

    handleProviderPortalChange = (newValue, objectName, fieldName) => {
        if (!!this.props.onChange) {
            var providerPortalObject;

            if (this.props.config?.providerPortalV2 === null) {
                providerPortalObject = deepClone(this.props.constants?.emptyProviderPortal);
            } else {
                providerPortalObject = this.props.config?.providerPortalV2;
            }

            switch (fieldName) {
                case 'portalUri':
                    providerPortalObject.portalUri = newValue;
                    break;
                case 'portalOperation':
                    providerPortalObject.portalOperation = newValue;
                    break;
                case 'portalPayloadFormat':
                    providerPortalObject.portalPayloadFormat = newValue;
                    break;
                case 'portalPayloadSeparator':
                    providerPortalObject.portalPayloadSeparator = newValue;
                    break;
                default:
                    break;
            }

            if (providerPortalObject.portalUri.trim().length === 0) {
                providerPortalObject = null;
            }

            this.handleValueChange(providerPortalObject, objectName);
        }
    };

    render() {
        let { config } = this.props;

        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId='providerId'
                            labelId='mps-providerid-label'
                            tooltipId='mps-providerid-tooltip'
                            value={config?.providerId}
                            disabled={true}
                        />
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-allowedadminusers-label'
                            tooltipId='mps-allowedadminusers-tooltip'
                            count={config?.allowedAdminUsers?.length}
                        >
                            <SingleTextList
                                itemLabelId='mps-allowedadminusers-item-label'
                                deleteButtonAriaId='mps-allowedadminuser-delete-button'
                                items={config?.allowedAdminUsers}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'allowedAdminUsers');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId='brandName'
                            labelId='mps-brandname-label'
                            tooltipId='mps-brandname-tooltip'
                            value={config?.brandName}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'brandName');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId='logoFilename'
                            labelId='mps-logofilename-label'
                            tooltipId='mps-logofilename-tooltip'
                            value={config?.logoFilename}
                            options={['', ...this.props.availableResourceFileNames]}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'logoFilename');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId='defaultLang'
                            labelId='mps-defaultlang-label'
                            tooltipId='mps-defaultlang-tooltip'
                            value={config?.defaultLang}
                            options={this.props.constants?.languages}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'defaultLang');
                            }}
                        />
                    </Col>
                    <Col md={6}>{/* BLANK */}</Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className={`bg-light p-2`}>
                            <CollapsableContainer labelId='mps-providerPortalEdge-label' tooltipId='mps-providerPortalEdge-tooltip'>
                                <Row>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='text'
                                            controlId='portalUri'
                                            labelId='mps-portaluri-label'
                                            tooltipId='mps-portaluri-tooltip'
                                            value={config?.portalUri}
                                            onChange={(newValue) => {
                                                this.handleValueChange(newValue, 'portalUri');
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='select'
                                            controlId='portalOperation'
                                            labelId='mps-portaloperation-label'
                                            tooltipId='mps-portaloperation-tooltip'
                                            value={config?.portalOperation}
                                            options={[MpsConstants.HTTP_GET, MpsConstants.HTTP_POST]}
                                            onChange={(newValue) => {
                                                this.handleValueChange(newValue, 'portalOperation');
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='textarea'
                                            controlId='portalPayloadFormat'
                                            labelId='mps-portalpayloadformat-label'
                                            tooltipId='mps-portalpayloadformat-tooltip'
                                            value={config?.portalPayloadFormat}
                                            onChange={(newValue) => {
                                                this.handleValueChange(newValue, 'portalPayloadFormat');
                                            }}
                                            instanceId={`${this.props.env}-${config?.providerId}`}
                                            disabled={config?.portalOperation !== MpsConstants.HTTP_POST}
                                            disabledValue={''}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='text'
                                            controlId='portalPayloadSeparator'
                                            labelId='mps-portalpayloadseparator-label'
                                            tooltipId='mps-portalpayloadseparator-tooltip'
                                            value={config?.portalPayloadSeparator}
                                            onChange={(newValue) => {
                                                this.handleValueChange(newValue, 'portalPayloadSeparator');
                                            }}
                                            instanceId={`${this.props.env}-${config?.providerId}`}
                                            disabled={config?.portalOperation !== MpsConstants.HTTP_POST}
                                            disabledValue={''}
                                        />
                                    </Col>
                                </Row>
                            </CollapsableContainer>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className={`bg-white p-2`}>
                            <CollapsableContainer labelId='mps-providerPortalChromium-label' tooltipId='mps-providerPortalChromium-tooltip'>
                                <Row>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='text'
                                            controlId='portalUriV2'
                                            labelId='mps-portaluri-label'
                                            tooltipId='mps-portaluri-tooltip'
                                            value={config?.providerPortalV2?.portalUri}
                                            onChange={(newValue) => {
                                                this.handleProviderPortalChange(newValue, 'providerPortalV2', 'portalUri');
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='select'
                                            controlId='portalOperationV2'
                                            labelId='mps-portaloperation-label'
                                            tooltipId='mps-portaloperation-tooltip'
                                            value={config?.providerPortalV2?.portalOperation}
                                            options={[MpsConstants.HTTP_GET, MpsConstants.HTTP_POST]}
                                            onChange={(newValue) => {
                                                this.handleProviderPortalChange(newValue, 'providerPortalV2', 'portalOperation');
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='textarea'
                                            controlId='portalPayloadFormatV2'
                                            labelId='mps-portalpayloadformat-label'
                                            tooltipId='mps-portalpayloadformat-tooltip'
                                            value={config?.providerPortalV2?.portalPayloadFormat}
                                            onChange={(newValue) => {
                                                this.handleProviderPortalChange(newValue, 'providerPortalV2', 'portalPayloadFormat');
                                            }}
                                            instanceId={`${this.props.env}-${config?.providerId}`}
                                            disabled={config?.providerPortalV2?.portalOperation !== MpsConstants.HTTP_POST}
                                            disabledValue={''}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <LabeledInput
                                            type='text'
                                            controlId='portalPayloadSeparatorV2'
                                            labelId='mps-portalpayloadseparator-label'
                                            tooltipId='mps-portalpayloadseparator-tooltip'
                                            value={config?.providerPortalV2?.portalPayloadSeparator}
                                            onChange={(newValue) => {
                                                this.handleProviderPortalChange(newValue, 'providerPortalV2', 'portalPayloadSeparator');
                                            }}
                                            instanceId={`${this.props.env}-${config?.providerId}`}
                                            disabled={config?.providerPortalV2?.portalOperation !== MpsConstants.HTTP_POST}
                                            disabledValue={''}
                                        />
                                    </Col>
                                </Row>
                            </CollapsableContainer>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId='balanceUri'
                            labelId='mps-balanceuri-label'
                            tooltipId='mps-balanceuri-tooltip'
                            value={config?.balanceUri}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'balanceUri');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-allowednotifyuris-label'
                            tooltipId='mps-allowednotifyuris-tooltip'
                            count={config?.allowedNotifyUris?.length}
                        >
                            <SingleTextList
                                controlId='allowedNotifyUris'
                                labelId='mps-allowednotifyuris-label'
                                tooltipId='mps-allowednotifyuris-tooltip'
                                itemLabelId='mps-allowednotifyuris-item-label'
                                deleteButtonAriaId='mps-allowednotifyuris-delete-button'
                                items={config?.allowedNotifyUris}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'allowedNotifyUris');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='text'
                            controlId='purchaseUri'
                            labelId='mps-purchaseuri-label'
                            tooltipId='mps-purchaseuri-tooltip'
                            value={config?.purchaseUri}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'purchaseUri');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId='purchaseProviderId'
                            labelId='mps-purchaseproviderid-label'
                            tooltipId='mps-purchaseproviderid-tooltip'
                            value={config?.purchaseProviderId}
                            options={['', ...this.props.availableProviderIds]}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'purchaseProviderId');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledInput
                            type='checkbox'
                            controlId='isESimSupported'
                            labelId='mps-isesimsupported-label'
                            tooltipId='mps-isesimsupported-tooltip'
                            value={config?.isESimSupported}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'isESimSupported');
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LabeledInput
                            type='select'
                            controlId='asyncType'
                            labelId='mps-asynctype-label'
                            tooltipId='mps-asynctype-tooltip'
                            value={config?.asyncType}
                            options={['None', 'Connectivity', 'ProfileDownload']}
                            onChange={(newValue) => {
                                this.handleValueChange(newValue, 'asyncType');
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-iccidranges-label'
                            tooltipId='mps-iccidranges-tooltip'
                            count={config?.iccidRanges?.length}
                        >
                            <DoubleTextList
                                controlId='iccidRanges'
                                labelId='mps-iccidranges-label'
                                tooltipId='mps-iccidranges-tooltip'
                                firstLabelId='mps-iccid-start-label'
                                secondLabelId='mps-iccid-end-label'
                                firstPropName='start'
                                secondPropName='end'
                                deleteButtonAriaId='mps-iccidranges-delete-button'
                                items={config?.iccidRanges}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'iccidRanges');
                                }}
                                numeric
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-hexiccidranges-label'
                            tooltipId='mps-hexiccidranges-tooltip'
                            count={config?.hexIccidRanges?.length}
                        >
                            <DoubleTextList
                                controlId='hexIccidRanges'
                                labelId='mps-hexiccidranges-label'
                                tooltipId='mps-hexiccidranges-tooltip'
                                firstLabelId='mps-hexiccid-start-label'
                                secondLabelId='mps-hexiccid-end-label'
                                firstPropName='start'
                                secondPropName='end'
                                deleteButtonAriaId='mps-hexiccidranges-delete-button'
                                items={config?.hexIccidRanges}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'hexIccidRanges');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal labelId='mps-imsiranges-label' tooltipId='mps-imsiranges-tooltip' count={config?.imsiRanges?.length}>
                            <DoubleTextList
                                controlId='imsiRanges'
                                labelId='mps-imsiranges-label'
                                tooltipId='mps-imsiranges-tooltip'
                                firstLabelId='mps-imsi-start-label'
                                secondLabelId='mps-imsi-end-label'
                                deleteButtonAriaId='mps-imsiranges-delete-button'
                                firstPropName='start'
                                secondPropName='end'
                                overrideEmptyItem={{ start: '', end: '', spns: [] }}
                                items={config?.imsiRanges}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'imsiRanges');
                                }}
                                numeric
                                renderItemChild={(item, index) => {
                                    return (
                                        <SimpleModal labelId='mps-spn-label' tooltipId='mps-spn-tooltip' count={item?.spns?.length}>
                                            <DoubleTextList
                                                controlId='spns'
                                                labelId='mps-spn-label'
                                                tooltipId='mps-spn-tooltip'
                                                firstLabelId='mps-spn-value-label'
                                                secondLabelId='mps-spn-isprefix-label'
                                                deleteButtonAriaId='mps-spn-delete-button'
                                                firstPropName='value'
                                                secondPropName='isPrefix'
                                                secondType='checkbox'
                                                overrideEmptyItem={{ value: '', isPrefix: false }}
                                                items={item?.spns}
                                                onChange={(newValue) => {
                                                    this.handleValueChange(newValue, 'imsiRanges', index, 'spns');
                                                }}
                                            />
                                        </SimpleModal>
                                    );
                                }}
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-supportedcountryisos-label'
                            tooltipId='mps-supportedcountryisos-tooltip'
                            count={config?.supportedCountryIsos?.length}
                        >
                            <MultiSelect
                                controlId='supportedCountryIsos'
                                labelId='mps-supportedcountryisos-label'
                                tooltipId='mps-supportedcountryisos-tooltip'
                                items={config?.supportedCountryIsos}
                                options={this.props.constants?.countryIsos}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'supportedCountryIsos');
                                }}
                                restricted
                                unique
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-emptyesimcurrentprovidercountryisos-label'
                            tooltipId='mps-emptyesimcurrentprovidercountryisos-tooltip'
                            count={config?.emptyESimCurrentProviderCountryIsos?.length}
                        >
                            <MultiSelect
                                controlId='emptyESimCurrentProviderCountryIsos'
                                labelId='mps-emptyesimcurrentprovidercountryisos-label'
                                tooltipId='mps-emptyesimcurrentprovidercountryisos-tooltip'
                                items={config?.emptyESimCurrentProviderCountryIsos}
                                options={this.props.constants?.countryIsos}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'emptyESimCurrentProviderCountryIsos');
                                }}
                                restricted
                                unique
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-supportedhnis-label'
                            tooltipId='mps-supportedhnis-tooltip'
                            count={config?.supportedHnis?.length}
                        >
                            <SingleTextList
                                controlId='supportedHnis'
                                labelId='mps-supportedhnis-label'
                                tooltipId='mps-supportedhnis-tooltip'
                                itemLabelId='mps-supportedhnis-item-label'
                                deleteButtonAriaId='mps-supportedhnis-delete-button'
                                items={config?.supportedHnis}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'supportedHnis');
                                }}
                                numeric
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-allowedtacs-label'
                            tooltipId='mps-allowedtacs-tooltip'
                            count={config?.allowedTacs?.length}
                            buttonDisabled={config?.deniedTacs?.length > 0}
                        >
                            <SingleTextList
                                controlId='allowedTacs'
                                labelId='mps-allowedtacs-label'
                                tooltipId='mps-allowedtacs-tooltip'
                                itemLabelId='mps-tac-item-label'
                                deleteButtonAriaId='mps-allowedtacs-delete-button'
                                items={config?.allowedTacs}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'allowedTacs');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-deniedtacs-label'
                            tooltipId='mps-deniedtacs-tooltip'
                            count={config?.deniedTacs?.length}
                            buttonDisabled={config?.allowedTacs?.length > 0}
                        >
                            <SingleTextList
                                controlId='deniedTacs'
                                labelId='mps-deniedtacs-label'
                                tooltipId='mps-deniedtacs-tooltip'
                                itemLabelId='mps-tac-item-label'
                                deleteButtonAriaId='mps-deniedtacs-delete-button'
                                items={config?.deniedTacs}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'deniedTacs');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-alloweddevices-label'
                            tooltipId='mps-alloweddevices-tooltip'
                            count={config?.allowedDevices?.length}
                            buttonDisabled={config?.deniedDevices?.length > 0}
                        >
                            <DoubleTextList
                                controlId='allowedDevices'
                                labelId='mps-alloweddevices-label'
                                tooltipId='mps-alloweddevices-tooltip'
                                firstLabelId='mps-device-make-label'
                                secondLabelId='mps-device-model-label'
                                deleteButtonAriaId='mps-alloweddevices-delete-button'
                                firstPropName='make'
                                secondPropName='model'
                                items={config?.allowedDevices}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'allowedDevices');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-denieddevices-label'
                            tooltipId='mps-denieddevices-tooltip'
                            count={config?.deniedDevices?.length}
                            buttonDisabled={config?.allowedDevices?.length > 0}
                        >
                            <DoubleTextList
                                controlId='deniedDevices'
                                labelId='mps-denieddevices-label'
                                tooltipId='mps-denieddevices-tooltip'
                                firstLabelId='mps-device-make-label'
                                secondLabelId='mps-device-model-label'
                                deleteButtonAriaId='mps-denieddevices-delete-button'
                                firstPropName='make'
                                secondPropName='model'
                                items={config?.deniedDevices}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'deniedDevices');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-deniedeigregexes-label'
                            tooltipId='mps-deniedeigregexes-tooltip'
                            count={config?.deniedEidRegexes?.length}
                        >
                            <SingleTextList
                                controlId='deniedEidRegexes'
                                labelId='mps-deniedeigregexes-label'
                                tooltipId='mps-deniedeigregexes-tooltip'
                                itemLabelId='mps-deniedeigregexes-item-label'
                                deleteButtonAriaId='mps-deniedeigregexes-delete-button'
                                items={config?.deniedEidRegexes}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'deniedEidRegexes');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                    <Col md={6}>
                        <SimpleModal
                            labelId='mps-walledgardenprovidernames-label'
                            tooltipId='mps-walledgardenprovidernames-tooltip'
                            count={config?.walledGardenProviderNames?.length}
                        >
                            <SingleTextList
                                controlId='walledGardenProviderNames'
                                labelId='mps-walledgardenprovidernames-label'
                                tooltipId='mps-walledgardenprovidernames-tooltip'
                                itemLabelId='mps-walledgardenprovidernames-item-label'
                                deleteButtonAriaId='mps-walledgardenprovidernames-delete-button'
                                items={config?.walledGardenProviderNames}
                                onChange={(newValue) => {
                                    this.handleValueChange(newValue, 'walledGardenProviderNames');
                                }}
                            />
                        </SimpleModal>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default injectIntl(MpsConfigPage);
