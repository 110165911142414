// Copyright (C) Microsoft Corporation. All rights reserved.
import { combineReducers, createStore } from 'redux';
import { loginReducer } from './LoginStatus';

export default function configureStore(initialState) {
    const reducers = {
        loginReducer
    };

    const rootReducer = combineReducers({
        ...reducers
    });

    return createStore(rootReducer, initialState);
}
