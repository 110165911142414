// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import './ProfilesContainer.css';
import ProfilesList from './ProfilesList';
import NewProfileModal from './NewProfileModal';
import NoProfileSelectedContainer from './NoProfileSelectedContainer';
import NavigationWithDisplayContainer from '../../common/NavigationWithDisplayContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export class ProfilesContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewProfileModal: false,
            filteredProfileText: ''
        };
    }

    showNewProfileModal() {
        this.setState({ showNewProfileModal: true });
    }

    handleNewProfileClosing = () => {
        this.setState({ showNewProfileModal: false });
    };

    handleNewProfileSubmit = (name) => {
        this.setState({ showNewProfileModal: false });
        this.props.onNewProfileCreated(name);
    };

    filterProfileOnChange = (evt) => {
        this.setState({ filteredProfileText: evt.target.value });
    };

    getProfiles() {
        return (
            <div className='profile-list-navigation'>
                <div className='profile-filter-container'>
                    <Form.Group className='profile-filter'>
                        <Form.Control
                            type='text'
                            onChange={this.filterProfileOnChange}
                            value={this.state.filteredProfileText}
                            placeholder={this.props.intl.formatMessage({ id: 'search-profiles' })}
                            aria-label={this.props.intl.formatMessage({ id: 'search-profiles' })}
                        />
                    </Form.Group>
                    <FontAwesomeIcon icon={faSearch} size='lg' className='profile-search-icon' />
                </div>
                <ProfilesList
                    profiles={this.props.profiles}
                    onProfileSelected={this.props.onProfileSelected}
                    onProfileDeleted={this.props.onProfileDeleted}
                    onProfileNameChanged={this.props.onProfileNameChanged}
                    profilesFilter={this.state.filteredProfileText}
                />
                <div className='new-profile-button'>
                    <Button onClick={() => this.showNewProfileModal()}>{this.props.intl.formatMessage({ id: 'new-profile' })}</Button>
                </div>
            </div>
        );
    }

    getDisplayComponent() {
        return <NoProfileSelectedContainer />;
    }

    render() {
        return (
            <div>
                <NavigationWithDisplayContainer navigationComponent={this.getProfiles()} displayComponent={this.getDisplayComponent()} />
                <NewProfileModal
                    show={this.state.showNewProfileModal}
                    handleClose={this.handleNewProfileClosing}
                    handleSubmit={this.handleNewProfileSubmit}
                />
            </div>
        );
    }
}

export default injectIntl(ProfilesContainer);
