// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { deepClone } from '../../mps/MpsUtils';
import TooltipLabel from '../form/TooltipLabel';
import './ExpandableList.css';

export class ExpandableList extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
    }

    addEmptyItem = () => {
        let newItems = deepClone(this.props.items) ?? [];
        newItems.push(deepClone(this.props.emptyItem));

        this.props.onChange(newItems);
    };

    removeItem = (index) => {
        if (Array.isArray(this.props.items)) {
            let newItems = deepClone(this.props.items);
            newItems.splice(index, 1);

            this.props.onChange(newItems);
        }
    };

    getLabelText() {
        let labelText;
        if (!!this.props.label) {
            labelText = this.props.label;
        } else if (!!this.props.labelId) {
            labelText = this.props.intl.formatMessage({ id: this.props.labelId });
        }

        return labelText;
    }

    getAddButtonAriaLabel() {
        const labelText = this.props.intl.formatMessage({ id: 'add-item-button-aria-label' }, { text: this.getLabelText() });

        return labelText;
    }

    componentDidUpdate(prevProps) {
        if (this.props.items.length !== prevProps.items.length && this.props.items.length > 0 && this.mainRef.current != null) {
            this.mainRef.current.focus();
        }
    }

    render() {
        let { items, renderItem, deleteButtonAriaId, label, labelId, tooltip, tooltipId } = this.props;

        const deleteButtonAria =
            deleteButtonAriaId == null
                ? this.props.intl.formatMessage({ id: 'delete' })
                : this.props.intl.formatMessage({ id: deleteButtonAriaId });

        return (
            <div>
                <TooltipLabel label={label} labelId={labelId} tooltip={tooltip} tooltipId={tooltipId} />
                {items?.map((item, index) => {
                    const containerRef = index === items.length - 1 ? this.mainRef : null;
                    return (
                        <Container key={`expandableListItem${index}`}>
                            <Row key={index} className='exp-list-row'>
                                <Col>{renderItem(item, index, containerRef)}</Col>
                                <Col md='auto' className='exp-list-remove-button-col'>
                                    <Button
                                        className='exp-list-remove-button'
                                        variant='light'
                                        onClick={() => {
                                            this.removeItem(index);
                                        }}
                                        aria-label={deleteButtonAria}
                                    >
                                        <FontAwesomeIcon icon={faMinusCircle} size='sm' />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    );
                })}
                <Button onClick={this.addEmptyItem} className='exp-list-add-button' aria-label={this.getAddButtonAriaLabel()}>
                    <FontAwesomeIcon className='mr-1' icon={faPlusCircle} size='lg' />
                    {this.props.intl.formatMessage({ id: 'add' })}
                </Button>
            </div>
        );
    }
}

export default injectIntl(ExpandableList);
