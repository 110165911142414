// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import TargetCondition from './TargetCondition';

export class TargetsContainer extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
    }

    handleOnChange = (evt) => {
        const targetState = { ...this.props.targetState };
        const newTargetCondition = evt.target.value;

        let index = -1;
        for (let i = 0; i < this.props.targetState.targetConditions.length; i++) {
            if (this.props.targetState.targetConditions[i].name === newTargetCondition.name) {
                index = i;
                break;
            }
        }

        if (newTargetCondition.value != null) {
            // Value was added
            if (index === -1) {
                targetState.targetConditions.push(newTargetCondition);
            } else {
                targetState.targetConditions[index] = newTargetCondition;
            }
        } else if (newTargetCondition.value == null && index >= 0) {
            // Value was removed
            targetState.targetConditions.splice(index, 1);
        }

        this.props.onTargetStateChange(targetState);
    };

    mapTargetConditionWithSchema(targetCondition) {
        let targetConditionValue = null;
        for (let i = 0; i < this.props.targetState.targetConditions.length; i++) {
            if (targetCondition.Name.toLowerCase() === this.props.targetState.targetConditions[i].name.toLowerCase()) {
                targetConditionValue = this.props.targetState.targetConditions[i];
                break;
            }
        }

        const key = this.props.targetState.id + '/' + targetCondition.Name;
        return (
            <div key={key} className={key}>
                <TargetCondition value={targetConditionValue} schema={targetCondition} onChange={this.handleOnChange} />
            </div>
        );
    }

    componentDidMount() {
        if (this.mainRef.current != null) {
            this.mainRef.current.focus();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedItemPath != this.props.selectedItemPath) {
            this.mainRef.current.focus();
        }
    }

    render() {
        const targetConditions = this.props.targetConditionSchema.map((targetCondition) =>
            this.mapTargetConditionWithSchema(targetCondition)
        );

        return (
            <div ref={this.mainRef} tabIndex={0} aria-label={this.props.intl.formatMessage({ id: 'target-container-aria' })}>
                {targetConditions}
            </div>
        );
    }
}

export default injectIntl(TargetsContainer);
