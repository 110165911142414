// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './BooleanSetting.css';
import { generateNewSettingValue } from './SettingFactory';
import { SettingNameDisplay } from '../SettingNameDisplay';

export default class BooleanSetting extends Component {
    handleChange = (evt) => {
        const settingValue = generateNewSettingValue(this.props.setting, this.props.settingValue);
        settingValue.value = evt.target.value;
        this.props.onChange(settingValue);
    };

    setupFormCheck(allowedValue) {
        let checked = false;
        let allowedValueString = allowedValue.Value.toLowerCase();
        if (this.props.settingValue == null) {
            // Set the default value as the selected option
            checked = allowedValueString === this.props.setting.DefaultValue;
        } else if (this.props.settingValue != null && this.props.settingValue.value === allowedValueString) {
            checked = true;
        }
        const ariaLabel = this.props.displayName + ' ' + allowedValue.Name;
        return (
            <Form.Check
                type='radio'
                id={ariaLabel}
                label={allowedValue.Name}
                aria-label={ariaLabel}
                checked={checked}
                value={allowedValueString}
                onChange={this.handleChange}
            />
        );
    }

    render() {
        const first = this.setupFormCheck(this.props.setting.Validation.AllowedValues[0]);
        const second = this.setupFormCheck(this.props.setting.Validation.AllowedValues[1]);
        return (
            <div class='boolean-setting'>
                <SettingNameDisplay displayName={this.props.displayName} description={this.props.setting.Description} />
                <Form.Group>
                    {first}
                    {second}
                </Form.Group>
            </div>
        );
    }
}
