// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { isNumZeroOrEmpty, isNumNonZeroOrEmpty, generateGuid } from '../../mps/MpsUtils';
import TooltipLabel from './TooltipLabel';
import { debounce } from 'lodash';
import './LabeledInput.css';

export class LabeledInput extends Component {
    constructor(props) {
        super(props);

        this.trySetValue(false);
        this.trySetSelectedOption();

        // This variable acts as a cache to remember the last value of the input in the event the user
        // does something to disable the input. I.e. portalPayloadSeparator becoming disabled if the HTTP Operation
        // goes from POST to GET.
        this.lastValue = {};
        if (this.props.value != null && this.props.disabled != null && !this.props.disabled) {
            this.lastValue[this.props.instanceId] = this.props.value;
        }

        this.debouncedOnChange = debounce((newValue) => {
            this.props.onChange(newValue);
        }, 100);

        this.state = {
            value: this.props.value
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.instanceId !== prevProps.instanceId) {
            // Set lastValue if the instance ids have change. We need to make sure it is set as the user might change something that changes
            // the control before they make a change to it which would update lastValue
            var latestValue = this.trySetValue(true);
            if (this.props.disabled != null && !this.props.disabled) {
                this.lastValue[this.props.instanceId] = latestValue;
            }

            if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
                this.trySetSelectedOption();
            }
        } else {
            // This handles the common case of users making a change to the input or making a change to another control that disables this one
            if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
                // Set the value for the input, whether that be the default value for the input or props has a value
                var latestValue = this.trySetValue(true);

                if (this.props.disabled != null && !this.props.disabled && !!this.props.disabled !== !!prevProps.disabled) {
                    this.lastValue[this.props.instanceId] = latestValue;
                }
            }

            if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
                this.trySetSelectedOption();
            }

            if (this.props.disabledValue != null && !!this.props.disabled !== !!prevProps.disabled) {
                if (this.props.disabled != null && !!this.props.disabled) {
                    // Save the disabled value if the control has become disabled.
                    this.props.onChange(this.props.disabledValue);
                } else if (this.lastValue[this.props.instanceId] != null && this.props.value !== this.lastValue[this.props.instanceId]) {
                    // Save the last remembered value for the control when the control becomes enabled.
                    this.props.onChange(this.lastValue[this.props.instanceId]);
                }
            }
        }
    }

    trySetValue = (canSetState) => {
        let { defaultValue, value } = this.props;

        // We don't currently set the value if there is a disabled value and the current value is null.
        if (defaultValue != null && value == null) {
            if (this.props.onChange != null) {
                this.props.onChange(defaultValue);
            }
            return defaultValue;
        } else if (canSetState) {
            this.setState({ value: value });
            return value;
        }
    };

    trySetSelectedOption = () => {
        let { defaultIndex, options } = this.props;
        if (!Number.isInteger(defaultIndex) || !(defaultIndex >= 0 && defaultIndex < options?.length)) {
            return;
        }

        if (options?.length > 0) {
            let newIndex = Math.max(options.indexOf(this.props.value), defaultIndex);
            let newValue = options[newIndex];
            this.props.onChange(newValue);
        }
    };

    isValidValue = (value) => {
        let isValid = true;

        if (isValid && !!this.props.numeric) {
            isValid = isValid && isNumNonZeroOrEmpty(value);
        }

        if (isValid && !!this.props.numericZero) {
            isValid = isValid && isNumZeroOrEmpty(value);
        }

        return isValid;
    };

    handleChange = (e) => {
        if (!!this.props.onChange) {
            let type = e.target.type;
            let newValue = e.target.value;

            if (type === 'checkbox') {
                newValue = e.target.checked;
            } else if (type === 'select-one') {
                newValue = this.props.options[e.target.selectedIndex];
            } else if (type === 'color') {
                newValue = this.toEightDigitHexColor(e.target.value);
            } else if (type === 'datetime-local') {
                newValue = this.toZuluDateTime(e.target.value);
            } else if (type === 'file') {
                newValue = e.target.files[0];
            }

            // Do not bubble up invalid values.
            if (this.isValidValue(newValue)) {
                this.setState({ value: newValue });
                this.debouncedOnChange(newValue);
                this.lastValue[this.props.instanceId] = newValue;
            }
        }
    };

    getFeedback = () => {
        let errorText = null;

        if (!!this.props.error) {
            errorText = this.props.error;
        } else if (!!this.props.errorId) {
            errorText = this.props.intl.formatMessage({ id: this.props.errorId });
        }

        if (!!errorText) {
            return (
                <Form.Control.Feedback type='invalid' aria-live='polite'>
                    {errorText}
                </Form.Control.Feedback>
            );
        }

        return null;
    };

    getOptionDisplayName = (key) => {
        if (key != null) {
            const prefix = 'mps-option-';
            const prefixedId = prefix + key.toString().toLowerCase();
            const keyStr = key.toString();

            let displayName = keyStr;
            if (prefixedId !== prefix) {
                displayName = this.props.intl.formatMessage({
                    id: prefixedId,
                    defaultMessage: keyStr
                });
            }

            if (displayName != null) {
                return displayName;
            }
        }

        return '';
    };

    getChildren = (type) => {
        let { options } = this.props;

        if (type === 'select' && Array.isArray(options)) {
            return options.map((opt) => {
                const displayName = this.getOptionDisplayName(opt);
                const ariaLabel = displayName === '' ? this.props.intl.formatMessage({ id: 'unselected-aria-label' }) : displayName;
                return (
                    <option key={opt + '-' + generateGuid()} value={opt} aria-label={ariaLabel}>
                        {displayName}
                    </option>
                );
            });
        }

        return null;
    };

    toEightDigitHexColor(sixDigitHexColor) {
        if (sixDigitHexColor?.length !== 7) {
            return '';
        }

        let hex = sixDigitHexColor.substring(1, 7).toUpperCase();
        return `0x${hex}FF`;
    }

    toSixDigitHexColor(eightDigitHexColor) {
        if (eightDigitHexColor?.length !== 10) {
            return '#000000';
        }

        let hex = eightDigitHexColor.substring(2, 8).toLowerCase();
        return `#${hex}`;
    }

    toNonZuluDateTime(zuluDateTime) {
        if (!zuluDateTime) {
            return '';
        }

        return zuluDateTime.substring(0, zuluDateTime.length - 1);
    }

    toZuluDateTime(dateTime) {
        return `${dateTime}Z`;
    }

    render() {
        let {
            controlId,
            inputClassName,
            label,
            labelId,
            tooltip,
            tooltipId,
            error,
            errorId,
            type,
            value,
            options,
            onChange,
            isInvalid,
            maxLength,
            defaultIndex,
            defaultValue,
            disabled,
            disabledValue,
            instanceId,
            ...otherProps
        } = this.props;

        if (!controlId) {
            controlId = generateGuid();
        }

        let stateValue = this.state.value;
        let valueProps = { value: stateValue ?? '' };
        let asProps = { as: 'input' };
        let maxLengthProps = { maxLength: 1023 };
        let isDisabled = false;
        let colorPickerText = null;

        let inputClassNameArray = [];
        if (!!inputClassName) {
            inputClassNameArray.push(inputClassName);
        }

        if (type === 'checkbox') {
            valueProps = { checked: stateValue ?? false };
            inputClassNameArray.push('form-control-checkbox');
        } else if (type === 'select') {
            asProps = { as: type };
            isDisabled = type === 'select' && (!options || options?.length === 0);
        } else if (type === 'textarea') {
            asProps = { as: type };
            maxLengthProps = null;
        } else if (type === 'color') {
            valueProps = { value: this.toSixDigitHexColor(stateValue) };
            inputClassNameArray.push('form-control-color');
            colorPickerText = ' - ' + (!!stateValue ? stateValue : this.props.intl.formatMessage({ id: 'error-no-value-selected' }));
        } else if (type === 'datetime-local') {
            valueProps = { value: this.toNonZuluDateTime(stateValue) };
        } else if (type === 'file') {
            // We are not allowed to programatically set the value of file inputs.
            valueProps = { value: this.props.value };
            maxLengthProps = null;
        }

        return (
            <Form.Group controlId={controlId}>
                <TooltipLabel label={label} labelId={labelId} tooltip={tooltip} tooltipId={tooltipId} />

                <Form.Control
                    {...otherProps}
                    {...valueProps}
                    {...asProps}
                    {...maxLengthProps}
                    type={this.props.type}
                    className={inputClassNameArray.join(' ')}
                    onChange={this.handleChange}
                    isInvalid={!!error || !!errorId}
                    disabled={!!disabled || isDisabled}
                    ref={this.props.inputRef}
                >
                    {this.getChildren(type)}
                </Form.Control>

                {this.getFeedback()}
                {colorPickerText}
            </Form.Group>
        );
    }
}

export default injectIntl(LabeledInput);
