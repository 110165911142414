// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import SettingGroupTreeItem from './SettingGroupTreeItem';
import { getSettingValuesForPath } from '../settingtypes/SettingFactory';
import './SettingTreeNavigation.css';
import TargetTree from '../targets/TargetTree';

export default class SettingTreeNavigation extends Component {
    mapSettingToGroup(settingGroup) {
        return (
            <div key={settingGroup.Path}>
                <SettingGroupTreeItem
                    settingGroup={settingGroup}
                    settingValue={getSettingValuesForPath(settingGroup.Path, this.props.profileSettings, true)}
                    onChildItemSelected={this.props.onChildItemSelected}
                    onNavigationItemAdded={this.props.onNavigationItemAdded}
                    onNavigationItemDeleted={this.props.onNavigationItemDeleted}
                    onGroupItemSelected={this.props.onGroupItemSelected}
                    selectedItemPath={this.props.selectedItemPath}
                />
            </div>
        );
    }

    render() {
        const settingGroups = this.props.schema.SettingGroups.map((settingGroup) => this.mapSettingToGroup(settingGroup));
        return (
            <div className='setting-tree-navigation'>
                <div>
                    <TargetTree
                        target={this.props.target}
                        targetItemSelected={this.props.targetItemSelected}
                        selectedItemPath={this.props.selectedItemPath}
                        onTargetItemAdded={this.props.onTargetItemAdded}
                        onTargetItemDeleted={this.props.onTargetItemDeleted}
                    />
                </div>
                <div>{settingGroups}</div>
            </div>
        );
    }
}
