// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Profile.css';
import EditProfileModal from './EditProfileModal';
import { injectIntl } from 'react-intl';

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditProfileModal: false
        };
    }

    handleProfileSelected = () => {
        if (this.props.onProfileSelected != null) {
            this.props.onProfileSelected(this.props.profile);
        }
    };

    handleEditAction(e) {
        e.stopPropagation();
        this.setState({ showEditProfileModal: true });
    }

    handleOnCancel = () => {
        this.closeEditProfileModal();
    };

    handleOnDelete = () => {
        this.closeEditProfileModal();
        this.props.onProfileDeleted(this.props.profile.targetRefs[0].id);
    };

    handleDone = (newProfileName) => {
        this.closeEditProfileModal();
        const profile = { ...this.props.profile };
        profile.profileName = newProfileName;
        this.props.onProfileNameChanged(profile);
    };

    closeEditProfileModal() {
        this.setState({ showEditProfileModal: false });
    }

    handleOnProfileKeyPress = (e) => {
        if (e.key === 'Enter' && this.props.onProfileSelected != null) {
            this.props.onProfileSelected(this.props.profile);
        }
    };

    handleOnEditKeyPress = (e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            this.setState({ showEditProfileModal: true });
        }
    };

    render() {
        const profileSelector = this.props.onProfileSelected == null ? '' : ' pointer-selectable';
        const profileRole = this.props.onProfileSelected == null ? '' : 'button';

        return (
            <div>
                <div className='profile'>
                    <div
                        className={profileSelector}
                        onClick={this.handleProfileSelected}
                        tabIndex={0}
                        onKeyPress={this.handleOnProfileKeyPress}
                        role={profileRole}
                        aria-label={this.props.profile.profileName}
                    >
                        <p>{this.props.profile.profileName}</p>
                    </div>
                    <div
                        className='profile-edit pointer-selectable'
                        onClick={(e) => this.handleEditAction(e)}
                        tabIndex={0}
                        onKeyPress={this.handleOnEditKeyPress}
                        role='button'
                        aria-label={this.props.intl.formatMessage(
                            { id: 'edit-profile-button' },
                            { profileName: this.props.profile.profileName }
                        )}
                    >
                        <FontAwesomeIcon icon='ellipsis-v' />
                    </div>
                </div>
                <EditProfileModal
                    name={this.props.profile.profileName}
                    show={this.state.showEditProfileModal}
                    handleClose={this.handleOnCancel}
                    handleDone={this.handleDone}
                    handleDelete={this.handleOnDelete}
                />
            </div>
        );
    }
}

export default injectIntl(Profile);
