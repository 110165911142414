// Copyright (C) Microsoft Corporation. All rights reserved.
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './AssetSetting.css';
import { generateNewSettingValue } from './SettingFactory';
import { SettingNameDisplay } from '../SettingNameDisplay';
import { injectIntl } from 'react-intl';

let fileReader = null;

export class AssetSetting extends Component {
    readFile = (fileName) => (e) => {
        const content = fileReader.result;
        this.props.onAssetsChange({ name: fileName, b64Content: btoa(content), settingPath: this.props.setting.Path });

        const settingValue = generateNewSettingValue(this.props.setting, this.props.settingValue);
        settingValue.value = fileName;
        this.props.onChange(settingValue);
    };

    onChange = (evt) => {
        if (evt.target.files[0] == null) {
            return;
        }

        if (evt.target.files[0].name.includes(' ')) {
            alert(this.props.intl.formatMessage({ id: 'cosa-asset-setting-file-name-space-error' }));
            return;
        }

        const fileNameSplit = evt.target.files[0].name.split('.');
        const fileExtension = fileNameSplit[fileNameSplit.length - 1];
        if (!this.props.setting.Validation.AllowedFileExtensions.includes(fileExtension)) {
            const validFileExtensions = this.props.setting.Validation.AllowedFileExtensions.join(', ');
            alert(
                this.props.intl.formatMessage(
                    { id: 'cosa-asset-setting-file-extension-error' },
                    { validFileExtensions: validFileExtensions }
                )
            );
            return;
        }

        fileReader = new FileReader();
        fileReader.onloadend = this.readFile(evt.target.files[0].name);
        fileReader.readAsBinaryString(evt.target.files[0]);
    };

    getCurrentlySelectedFile() {
        const settingValue = this.props.settingValue;
        if (settingValue == null) {
            return <Form.Label>{this.props.intl.formatMessage({ id: 'no-file-selected' })}</Form.Label>;
        }

        const fileNameSplit = settingValue.value.split('\\');
        return <Form.Label>{fileNameSplit[fileNameSplit.length - 1]}</Form.Label>;
    }

    render() {
        return (
            <div class='asset-setting'>
                <SettingNameDisplay displayName={this.props.displayName} description={this.props.setting.Description} />
                {this.getCurrentlySelectedFile()}
                <Form.Group>
                    <Form.File aria-label={this.props.displayName} onChange={this.onChange} />
                </Form.Group>
            </div>
        );
    }
}

export default injectIntl(AssetSetting);
