import { stringContains } from '../../../utils/StringUtils';
import { deepClone } from '../../mps/MpsUtils';
import { FilterButtonState } from './CosaMpsFilterButtons';

export function getFilteredDraftsOrSubmissionList(draftList, filteredText, filteredType) {
    let filteredDraftList = deepClone(draftList);

    // Filter based on button state
    if (filteredType !== FilterButtonState.NO_BUTTON_SELECTED) {
        let newFilteredList = [];
        filteredDraftList.forEach((draft) => {
            if (draft.type.toLowerCase() === 'cosa' && filteredType === FilterButtonState.COSA_BUTTON_SELECTED) {
                newFilteredList.push(draft);
            } else if (draft.type.toLowerCase() === 'mps' && filteredType === FilterButtonState.MPS_BUTTON_SELECTED) {
                newFilteredList.push(draft);
            }
        });

        filteredDraftList = newFilteredList;
    }

    // Filter based on text state
    if (filteredText !== null && filteredText !== '') {
        let newFilteredList = [];
        draftList.forEach((draft) => {
            if (stringContains(draft.title, filteredText)) {
                newFilteredList.push(draft);
            }
        });

        filteredDraftList = newFilteredList;
    }

    return filteredDraftList;
}
